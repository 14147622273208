import { xor, assign } from 'lodash';
import { TOGGLE_ITEM } from '../actions/customer_support';

const initialState = {
  openedItems: []
};

export function customerSupportReducer(state = initialState, action) {
  switch (action.type) {
  case TOGGLE_ITEM:
    return assign({}, state, {
      openedItems: xor(state.openedItems, [action.id])
    });
  default:
    return state;
  }
}
