export const BLIND_POST_CONTENT = '싫어요 수가 많아서 내용 감춤';
export const SORT_TYPES = {
  RECENT: 'RECENT', // 최신순
  RANK_DESC: 'RANK_DESC' // 인기순
};

export const PROFIT_FILTER_TYPES = {
  STOCKHOLD: 'STOCKHOLD', // 주주인증
  PROFIT: 'PROFIT' // 수익인증
};

export const REACTION_TYPES = {
  LIKE: 'LIKE',
  DISLIKE: 'DISLIKE'
};

export const COMMENT_SORT_TYPES = {
  ASC: 'ASC',
  DESC: 'DESC',
  RANK_ASC: 'RANK_ASC',
  RANK_DESC: 'RANK_DESC'
};

export const FORMAT_TYPES = {
  HTML: 'HTML',
  CUSTOM: 'CUSTOM'
};