import { merge } from 'lodash';
import fetchActionTypes from '../../constants/fetch_actions';

export function usersReducer(state = {}, action) {
  switch (action.type) {
  // 좋아요를 누른 유저를 받을 경우 user 엔티티에 추가한다.
  case fetchActionTypes.LIKED_USERS.RECEIVE:
    return merge({}, state, action.payload.entities.users);
    // 내 정보를 가져왔을 경우 user 엔티티에 추가한다.
  case fetchActionTypes.ME.RECEIVE:
    return merge({}, state, action.payload.entities.users);
  default:
    return state;
  }
}
