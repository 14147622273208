import { SELECT_FAVORITE_STOCK_LIST,
  EDIT_FAVORITE_STOCK_LIST,
  SUCCESS_DELETE_FAVORITE_STOCK_LISTS,
  SUCCESS_UPDATE_FAVORITE_STOCK_LIST,
  CLEAR_SELECTED_FAVORITE_STOCK_LISTS
} from '../../actions/favorite_stock_lists';
import { xor, assign } from 'lodash';

const ABSENCE = null;
const initialState = {
  selectedLists: [],
  editedList: ABSENCE
};

export function favoriteStockListsReducer(state = initialState, action) {
  switch (action.type) {
  case SELECT_FAVORITE_STOCK_LIST:
    return assign({}, state, {
      selectedLists: xor(state.selectedLists, [action.id])
    });
  case EDIT_FAVORITE_STOCK_LIST:
    return assign({}, state, {
      editedList: action.id
    });
  case SUCCESS_UPDATE_FAVORITE_STOCK_LIST:
    return assign({}, state, {
      editedList: ABSENCE
    });
  case SUCCESS_DELETE_FAVORITE_STOCK_LISTS:
    return assign({}, state, {
      selectedLists: []
    });
  case CLEAR_SELECTED_FAVORITE_STOCK_LISTS:
    return initialState;
  default:
    return state;
  }
}
