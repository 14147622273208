import { CALL_API } from '../middlewares/api';
import { Routes } from '../routes';
import * as paginateSelectors from '../selectors/paginate_selectors';
import fetchActionTypes from '../constants/fetch_actions';
import { insightArticle, insightArticleArray } from '../schemas/insight_article';

export const REQUEST_INSIGHT_ARTICLES = 'REQUEST_INSIGHT_ARTICLES';
export const RECEIVE_INSIGHT_ARTICLES = 'RECEIVE_INSIGHT_ARTICLES';
export const FAILURE_INSIGHT_ARTICLES = 'FAILURE_INSIGHT_ARTICLES';

export function fetchInsightArticles(scope = 'all', cursor = null, limit = 10) {
  return {
    [CALL_API]: {
      types: [REQUEST_INSIGHT_ARTICLES, RECEIVE_INSIGHT_ARTICLES, FAILURE_INSIGHT_ARTICLES],
      config: {
        method: 'get',
        url: Routes.api_insight_articles_path({ scope, cursor, limit, format: 'json' })
      },
      schema: {
        insightArticles: insightArticleArray
      }
    },
    scope,
    cursor,
    limit
  };
}

export function loadInsightArticles(scope = 'all', cursor = null, limit = 10) {
  const selector = paginateSelectors.insightArticlesSelectorFactory(scope);

  return (dispatch, getState) => {
    const paginate = selector(getState());

    if (cursor === null && paginate.pageCount > 0) {
      return null;
    }

    return dispatch(fetchInsightArticles(scope, cursor, limit));
  };
}

export const REQUEST_INSIGHT_ARTICLE = 'REQUEST_INSIGHT_ARTICLE';
export const RECEIVE_INSIGHT_ARTICLE = 'RECEIVE_INSIGHT_ARTICLE';
export const FAILURE_INSIGHT_ARTICLE = 'FAILURE_INSIGHT_ARTICLE';

export function fetchInsightArticle(id) {
  return {
    [CALL_API]: {
      types: fetchActionTypes.INSIGHT_ARTICLE,
      config: {
        method: 'get',
        url: Routes.api_insight_article_path(id, { format: 'json' })
      },
      schema: {
        insightArticle
      }
    },
    id
  };
}

/**
 * 아티클 상세조회
 * @param {number} id - 조회하고자 하는 아티클 아이디
 * @param {boolean} force - 무조건 다시 부르는가?
 * @returns {Function}
 */
export function loadInsightArticle(id, force = false) {
  return (dispatch, getState) => {
    const {
      entities: {
        insightArticles: {
          [id]: article
        }
      }
    } = getState();

    // 이미 state에 필요한 아티클 데이터가 있다면, 해당 아티클을 return한다.
    if (article && article.content && !force) {
      return Promise.resolve(article);
    }

    // 아직 state에 충분한 아티클 데이터가 없다면, 해당 아티클을 fetch한다.
    return dispatch(fetchInsightArticle(id));
  };
}

/**
 * 특정 아티클의 이전, 다음 글 조회
 * @param {number} id - 이전, 다음 아티클 조회시의 기준이 되는 아티클 아이디
 * @returns {Function}
 */
export function loadSideInsightArticle(id) {
  return (dispatch, getState) => {
    const {
      investingStrategies: {
        insightArticles: {
          [id]: sideArticle
        }
      }
    } = getState();

    if (sideArticle) {
      // 다음 아티클 정보가 있다면 이를 load한다.
      if (sideArticle.nextArticle) {
        dispatch(loadInsightArticle(sideArticle.nextArticle));
      }
      // 이전 아티클 정보가 있다면 이를 load한다.
      if (sideArticle.prevArticle) {
        dispatch(loadInsightArticle(sideArticle.prevArticle));
      }
    }

    return null;
  };
}

export const REQUEST_INSIGHT_ARTICLE_FEATURED = 'REQUEST_INSIGHT_ARTICLE_FEATURED';
export const RECEIVE_INSIGHT_ARTICLE_FEATURED = 'RECEIVE_INSIGHT_ARTICLE_FEATURED';
export const FAILURE_INSIGHT_ARTICLE_FEATURED = 'FAILURE_INSIGHT_ARTICLE_FEATURED';

export function fetchInsightArticleFeatured(limit) {
  return {
    [CALL_API]: {
      types: [REQUEST_INSIGHT_ARTICLE_FEATURED, RECEIVE_INSIGHT_ARTICLE_FEATURED, FAILURE_INSIGHT_ARTICLE_FEATURED],
      config: {
        method: 'get',
        url: Routes.featured_api_insight_articles_path({ limit, format: 'json' })
      },
      schema: {
        insightArticles: insightArticleArray
      }
    },
    limit
  };
}

export const REQUEST_SECURITY_INSIGHT_ARTICLES = 'REQUEST_SECURITY_INSIGHT_ARTICLES';
export const RECEIVE_SECURITY_INSIGHT_ARTICLES = 'RECEIVE_SECURITY_INSIGHT_ARTICLES';
export const FAILURE_SECURITY_INSIGHT_ARTICLES = 'FAILURE_SECURITY_INSIGHT_ARTICLES';

export function fetchSecurityInsightArticles(securityId, cursor, limit) {
  return {
    [CALL_API]: {
      types: [REQUEST_SECURITY_INSIGHT_ARTICLES, RECEIVE_SECURITY_INSIGHT_ARTICLES, FAILURE_SECURITY_INSIGHT_ARTICLES],
      config: {
        method: 'get',
        url: Routes.api_security_insight_articles_path(securityId, { cursor, limit, format: 'json' })
      },
      schema: {
        insightArticles: insightArticleArray
      }
    },
    securityId,
    cursor,
    limit
  };
}

export function loadSecurityInsightArticles(securityId, cursor, limit) {
  return (dispatch, getState) => {
    const {
      paginate: {
        insightArticlesBySecurityId
      }
    } = getState();

    const pagination = insightArticlesBySecurityId[securityId];
    if (cursor === null && pagination && pagination.pageCount > 0) {
      return null;
    }

    return dispatch(fetchSecurityInsightArticles(securityId, cursor, limit));
  };
}
