/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { EditTextModal } from '../../components/common/edit_text_modal';
import { closeModal } from '../../actions/modals';
import { createFavoriteTopicStocks } from '../../actions/topics';

class AddTopicStocksModal extends React.Component {
  props: {
    closeModal: typeof closeModal,
    createFavoriteTopicStocks: typeof createFavoriteTopicStocks,
    topic: Object
  };

  topicSecurityIds(): Array<string> {
    return this.props.topic.stocks.map(s => s.securityId);
  }

  render() {
    return (
      <EditTextModal
        title="토픽 관심그룹 만들기"
        description="관심그룹 이름을 입력해 주세요"
        onClickCancel={this.props.closeModal}
        onClickConfirm={
          name => this.props.createFavoriteTopicStocks(name, this.topicSecurityIds())
            .then(() => this.props.closeModal())
        }
        defaultValue={this.props.topic.name}
      />
    );
  }
}

export default connect(null, {
  closeModal,
  createFavoriteTopicStocks
})(AddTopicStocksModal);
