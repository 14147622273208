import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { loadComponent } from '../../helper';

export default (
  <Route path="investing_strategies">
    <IndexRoute
      getComponent={
        (location, cb) =>
          import('../../../containers/investing_strategies/investing_strategies_index_container')
            .then(loadComponent(cb))
      }
    />
    <Route>
      <Route
        path="contributors/:id" getComponent={
          (location, cb) =>
            import('../../../containers/investing_strategies/contributors_show_container')
              .then(loadComponent(cb))
        }
      />
      <Route
        path="articles/:id" getComponent={
          (location, cb) =>
            import('../../../containers/investing_strategies/insight_articles_show_container')
              .then(loadComponent(cb))
        }
      />
      <Route
        path="topics/:id" getComponent={
          (location, cb) =>
            import('../../../containers/topics/topics_show_container')
              .then(loadComponent(cb))
        }
      />
    </Route>
  </Route>
);
