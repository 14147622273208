import { Schema, arrayOf } from 'normalizr';

export const company = new Schema('companies', {
  idAttribute: 'securityId'
});


export const quarter = new Schema('quarterlyStatements', { idAttribute: 'id' });
export const quarterly = arrayOf(quarter);
export const year = new Schema('yearlyStatements', { idAttribute: 'id' });
export const yearly = arrayOf(year);
