import { assign } from 'lodash';
import fetchActionTypes from '../../constants/fetch_actions';
import { reactionsReducer } from './reactions';

export function socialPostCommentsInCommentReducer(state = {}, action) {
  switch (action.type) {
  case fetchActionTypes.SOCIAL_POST_SECOND_COMMENTS.RECEIVE:
    return assign({}, state, {
      [action.id]: [
        ...state[action.id] || [],
        ...action.payload
      ]
    });
  case fetchActionTypes.ADD_SOCIAL_POST_SECOND_COMMENT_REACTION.RECEIVE:
  case fetchActionTypes.REMOVE_SOCIAL_POST_SECOND_COMMENT_REACTION.RECEIVE:
    return assign({}, state, {
      [action.parentId]: state[action.parentId].map(comment => {
        if (comment.id !== action.id) {
          return comment;
        } else {
          return reactionsReducer(comment, action);
        }
      })
    });
  default:
    return state;
  }
}
