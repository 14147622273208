import fetchActionTypeCreator from './helper/fetch_action_type_creator';

const fetchActionTypes = {
  ASSETS: fetchActionTypeCreator('ASSETS'),
  RECENT_SECURITY: fetchActionTypeCreator('RECENT_SECURITY'),
  RECENT_SECURITIES: fetchActionTypeCreator('RECENT_SECURITIES'),
  DAY_FOREXES: fetchActionTypeCreator('DAY_FOREXES'),
  TICK_FOREXES: fetchActionTypeCreator('TICK_FOREXES'),
  FOREX_CALCS: fetchActionTypeCreator('FOREX_CALCS'),
  CONTEST: fetchActionTypeCreator('CONTEST'),
  RANKERS: fetchActionTypeCreator('RANKERS'),
  CONTEST_RANKERS: fetchActionTypeCreator('CONTEST_RANKERS'),
  RANKERS_DETAIL: fetchActionTypeCreator('RANKERS_DETAIL'),
  EXCHANGE_TRADED_ANALYSES: fetchActionTypeCreator('EXCHANGE_TRADED_ANALYSES'),
  EXCHANGE_TRADED_ASSETS: fetchActionTypeCreator('EXCHANGE_TRADED_ASSETS'),
  EXCHANGE_TRADED_VALUES: fetchActionTypeCreator('EXCHANGE_TRADED_VALUES'),
  FEATURED_TOPICS: fetchActionTypeCreator('FEATURED_TOPICS'),
  TOPICS: fetchActionTypeCreator('TOPICS'),
  SECURITY_TOPICS: fetchActionTypeCreator('SECURITY_TOPICS'),
  CONTRIBUTORS: fetchActionTypeCreator('CONTRIBUTORS'),
  CONTRIBUTOR_ARTICLES: fetchActionTypeCreator('CONTRIBUTOR_ARTICLES'),
  INSIGHT_ARTICLE: fetchActionTypeCreator('INSIGHT_ARTICLE'),
  LIKED_USERS: fetchActionTypeCreator('LIKED_USERS'),
  REPORTS: fetchActionTypeCreator('REPORTS'),
  ME: fetchActionTypeCreator('ME'),
  FOREIGN_INDEXES: fetchActionTypeCreator('FOREIGN_INDEXES'),
  FOREX: fetchActionTypeCreator('FOREX'),
  MAJOR_FOREX_IDS: fetchActionTypeCreator('MAJOR_FOREX_IDS'),
  CHART_IMAGE_URLS: fetchActionTypeCreator('CHART_IMAGE_URLS'),
  FOREIGN_CHART_IMAGE_URLS: fetchActionTypeCreator('FOREIGN_CHART_IMAGE_URLS'),
  SOCIAL_POST: fetchActionTypeCreator('SOCIAL_POST'),
  ADD_SOCIAL_POST_REACTION: fetchActionTypeCreator('ADD_SOCIAL_POST_REACTION'),
  REMOVE_SOCIAL_POST_REACTION: fetchActionTypeCreator('REMOVE_SOCIAL_POST_REACTION'),
  SOCIAL_POSTS: fetchActionTypeCreator('SOCIAL_POSTS'),
  TOP_RANKED_SOCIAL_POSTS: fetchActionTypeCreator('TOP_RANKED_SOCIAL_POSTS'),
  SOCIAL_POST_COMMENTS: fetchActionTypeCreator('SOCIAL_POST_COMMENTS'),
  SOCIAL_POST_SECOND_COMMENTS: fetchActionTypeCreator('SOCIAL_POST_SECOND_COMMENTS'),
  ADD_SOCIAL_POST_COMMENT_REACTION: fetchActionTypeCreator('ADD_SOCIAL_POST_COMMENT_REACTION'),
  REMOVE_SOCIAL_POST_COMMENT_REACTION: fetchActionTypeCreator('REMOVE_SOCIAL_POST_COMMENT_REACTION'),
  ADD_SOCIAL_POST_SECOND_COMMENT_REACTION: fetchActionTypeCreator('ADD_SOCIAL_POST_SECOND_COMMENT_REACTION'),
  REMOVE_SOCIAL_POST_SECOND_COMMENT_REACTION: fetchActionTypeCreator('REMOVE_SOCIAL_POST_SECOND_COMMENT_REACTION'),
  SPECIAL_STOCKS: fetchActionTypeCreator('SPECIAL_STOCKS'),
  SOCIAL_SECURITY: fetchActionTypeCreator('SOCIAL_SECURITY'),
};

export default fetchActionTypes;
