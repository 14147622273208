import { combineReducers } from 'redux';
import { keys, toArray, isObject, union, difference, assign, without, mapValues } from 'lodash';
import * as quotationsActions from '../actions/quotations';
import * as trendActions from '../actions/trend';
import * as commentsActions from '../actions/comments';
import * as postsActions from '../actions/posts';
import * as newsItemsActions from '../actions/news_items';
import * as insightArticlesActions from '../actions/insight_articles';
import * as favoriteStockListsActions from '../actions/favorite_stock_lists';
import * as favoriteStocksActions from '../actions/favorite_stocks';
import * as popularStocksActions from '../actions/popuplar_stocks';
import * as topicsActions from '../actions/topics';
import * as wicsSectorsActions from '../actions/wics_sectors';
import * as noticesActions from '../actions/notices';
import * as searchActions from '../actions/search';
import * as companiesActions from '../actions/companies.jsx';
import * as emotionActions from '../actions/emotions';
import fetchActionTypes from '../constants/fetch_actions';
import reducers from './paginates/index';
import { last } from 'lodash';
import { SINGLE_POST_SIZE } from '../constants/social_posts';
import { EMPTY_SIZE, SINGLE_SIZE } from '../constants/number';

function updatePaginate([requestType, receiveType, failureType], entityLocator, attachFront = false) {
  return function updatePagination(state = {
    isFetching: false,
    pageCount: 0,
    reachedEnd: false,
    entities: [],
    nextCursor: null
  }, action) {
    switch (action.type) {
    case requestType:
      return assign({}, state, {
        isFetching: true
      });
    case receiveType:
      /* eslint-disable no-case-declarations */
      const entitiesCount = entityLocator(action).length;
      const nextCursor = action.payload.nextCursor || (action.payload.result && action.payload.result.nextCursor);
      const limit = action.limit;
      /* eslint-enable no-case-declarations */
      return assign({}, state, {
        isFetching: false,
        pageCount: state.pageCount + SINGLE_SIZE,
        entities: attachFront ? union(entityLocator(action), state.entities) : union(state.entities, entityLocator(action)),
        reachedEnd: entitiesCount === EMPTY_SIZE || (limit && limit > entitiesCount),
        nextCursor
      });
    case failureType:
      return assign({}, state, {
        isFetching: false
      });
    default:
      return state;
    }
  };
}

function updateSocialPaginate({
  fetchActionTypes: [requestType, receiveType, failureType],
  entityLocator,
  attachFront = false,
  isComment = false,
  isSecondComment = false
}) {
  const getLastKey = (action) => {
    if (isComment) {
      return action.payload.lastKey || last(action.payload.result);
    } else if (isSecondComment) {
      return action.lastKey || last(action.payload)?.id;
    } else {
      return action.payload.lastKey || (action.payload.result && action.payload.result.lastKey);
    }
  };

  return function updatePagination(state = {
    isFetching: false,
    pageCount: 0,
    reachedEnd: false,
    entities: [],
    lastKey: null,
    isError: false
  }, action) {
    switch (action.type) {
    case requestType:
      return assign({}, state, {
        isFetching: true,
        isError: false
      });
    case receiveType:
      /* eslint-disable no-case-declarations */
      const entityLocatorResult = entityLocator(action) || [];
      const entities = (isComment && !isSecondComment) ? entityLocatorResult.reverse() : entityLocatorResult;
      const entitiesCount = entities.length;
      const lastKey = getLastKey(action);
      const size = action.size;
      /* eslint-enable no-case-declarations */
      return assign({}, state, {
        isFetching: false,
        isError: false,
        pageCount: state.pageCount + SINGLE_SIZE,
        entities: attachFront ? union(entities, state.entities) : union(state.entities, entities),
        reachedEnd: entitiesCount === EMPTY_SIZE || size === SINGLE_POST_SIZE || (size && size > entitiesCount),
        lastKey
      });
    case failureType:
      return assign({}, state, {
        isFetching: false,
        isError: true
      });
    default:
      return state;
    }
  };
}

export function paginate(types, entityLocator, actionToKey) {
  let actionTypes = types;
  const FETCH_ACTIONS_SIZE = 3;
  if (isObject(actionTypes)) {
    if (keys(actionTypes).length === FETCH_ACTIONS_SIZE) {
      actionTypes = toArray(actionTypes);
    } else {
      throw new Error('Expected an object of three keys.');
    }
  }

  const [requestType, receiveType, failureType] = actionTypes;
  const updatePagination = updatePaginate([requestType, receiveType, failureType], entityLocator);

  if (typeof actionToKey === 'undefined') {
    return updatePagination;
  }

  return function updatePaginationByKey(state = {}, action) {
    switch (action.type) {
    case requestType:
    case receiveType:
    case failureType:
      /* eslint-disable no-case-declarations */
      const key = actionToKey(action);
      /* eslint-enable no-case-declarations */
      return assign({}, state, {
        [key]: updatePagination(state[key], action)
      });
    default:
      return state;
    }
  };
}

function postsBySecurityIdAndScopePaginate() {
  const entityLocator = action => (action.payload.result.posts);
  const actionToKey = action => ([action.securityId, action.scope]);

  const updatePagination = updatePaginate([
    postsActions.REQUEST_SECURITY_POSTS,
    postsActions.RECEIVE_SECURITY_POSTS,
    postsActions.FAILURE_SECURITY_POSTS
  ], entityLocator);

  function update(state, action) {
    const computedState = updatePagination(state, action);

    switch (action.type) {
    case postsActions.SUCCESS_CREATE_POST:
      return assign({}, state, {
        entities: [action.payload.result.post, ...computedState.entities]
      });
    case postsActions.SUCCESS_DELETE_POST:
      return assign({}, state, {
        entities: without(state.entities, action.payload.id)
      });
    default:
      return computedState;
    }
  }

  return function postsBySecurityIdAndScope(state = {}, action) {
    let key = actionToKey(action);

    switch (action.type) {
    case postsActions.REQUEST_SECURITY_POSTS:
    case postsActions.RECEIVE_SECURITY_POSTS:
    case postsActions.FAILURE_SECURITY_POSTS:
      return assign({}, state, {
        [key]: update(state[key], action)
      });
    case postsActions.SUCCESS_CREATE_POST:
      key = [action.securityId, 'all'];
      return assign({}, state, {
        [key]: update(state[key], action)
      });
    case postsActions.SUCCESS_DELETE_POST:
      // 전체 업데이트
      return assign({}, state, mapValues(state, o => update(o, action)));
    default:
      return state;
    }
  };
}

function socialPostsByParamsPaginate() {
  const entityLocator = action => (action.payload.result.posts);
  const actionToKey = action => ([action.securityId, action.sort, action.profitFilterType]);

  const updatePagination = updateSocialPaginate({
    fetchActionTypes: [
      fetchActionTypes.SOCIAL_POSTS.REQUEST,
      fetchActionTypes.SOCIAL_POSTS.RECEIVE,
      fetchActionTypes.SOCIAL_POSTS.FAILURE
    ],
    entityLocator
  });

  function update(state, action) {
    return updatePagination(state, action);
  }

  return function socialPostsByParams(state = {}, action) {
    let key = actionToKey(action);

    switch (action.type) {
    case fetchActionTypes.SOCIAL_POSTS.REQUEST:
    case fetchActionTypes.SOCIAL_POSTS.RECEIVE:
    case fetchActionTypes.SOCIAL_POSTS.FAILURE:
      if (action.isTopRankedSocialPosts) {
        return state;
      }

      return assign({}, state, {
        [key]: update(state[key], action)
      });
    default:
      return state;
    }
  };
}

function commentsByPostIdPaginate() {
  const entityLocator = action => ([...action.payload.comments]);
  const actionToKey = action => (action.commentableId);

  const updatePagination = updatePaginate([
    commentsActions.REQUEST_COMMENTS,
    commentsActions.RECEIVE_COMMENTS,
    commentsActions.FAILURE_COMMENTS
  ], entityLocator);

  function update(state, action) {
    const computedState = updatePagination(state, action);

    switch (action.type) {
    case commentsActions.SUCCESS_CREATE_COMMENT:
      return assign({}, computedState, {
        entities: [...action.payload.comments, ...computedState.entities]
      });
    case commentsActions.SUCCESS_DELETE_COMMENT:
      return assign({}, state, {
        entities: without(state.entities, action.commentId)
      });
    default:
      return computedState;
    }
  }

  return function commentsByPostId(state = {}, action) {
    const key = actionToKey(action);

    switch (action.type) {
    case commentsActions.REQUEST_COMMENTS:
    case commentsActions.RECEIVE_COMMENTS:
    case commentsActions.FAILURE_COMMENTS:
    case commentsActions.SUCCESS_CREATE_COMMENT:
      if (action.commentableType !== 'post') {
        return state;
      }

      return assign({}, state, {
        [key]: update(state[key], action)
      });
    case commentsActions.SUCCESS_DELETE_COMMENT:
      return assign({}, state, mapValues(state, o => update(o, action)));
    default:
      return state;
    }
  };
}

function commentsByCommentIdPaginate() {
  const entityLocator = action => ([...action.payload.comments]);
  const actionToKey = action => (action.id);

  const updatePagination = updatePaginate([
    commentsActions.REQUEST_SECOND_COMMENTS,
    commentsActions.RECEIVE_SECOND_COMMENTS,
    commentsActions.FAILURE_SECOND_COMMENTS
  ], entityLocator, true);

  function update(state, action) {
    const computedState = updatePagination(state, action);
    return computedState;
  }

  return function commentsByCommentId(state = {}, action) {
    const key = actionToKey(action);

    switch (action.type) {
    case commentsActions.REQUEST_SECOND_COMMENTS:
    case commentsActions.RECEIVE_SECOND_COMMENTS:
    case commentsActions.FAILURE_SECOND_COMMENTS:
      return assign({}, state, {
        [key]: update(state[key], action)
      });
    case commentsActions.RECEIVE_COMMENTS:
      if (action.commentableType !== 'post') {
        return state;
      }

      // eslint-disable-next-line no-case-declarations
      let newState = {};
      action.payload.comments.forEach(({ count, replies, reply }) => {
        const id = reply.id;
        const length = replies ? replies.length : EMPTY_SIZE;
        newState[id] = {
          isFetching: false,
          pageCount: 1,
          entities: replies,
          reachedEnd: length === EMPTY_SIZE || (count && count <= length),
          nextCursor: length > EMPTY_SIZE ? replies[0].id : null
        };
      });
      return assign({}, state, newState);
    default:
      return state;
    }
  };
}

function commentsByInsightArticleIdPaginate() {
  const entityLocator = action => ([...action.payload.comments].reverse());
  const actionToKey = action => (action.commentableId);

  const updatePagination = updatePaginate([
    commentsActions.REQUEST_COMMENTS,
    commentsActions.RECEIVE_COMMENTS,
    commentsActions.FAILURE_COMMENTS
  ], entityLocator);

  function update(state, action) {
    const computedState = updatePagination(state, action);

    switch (action.type) {
    case commentsActions.SUCCESS_CREATE_COMMENT:
      return assign({}, computedState, {
        entities: [action.payload.comment, ...computedState.entities]
      });
    default:
      return computedState;
    }
  }

  return function commentsByPostId(state = {}, action) {
    const key = actionToKey(action);

    switch (action.type) {
    case commentsActions.REQUEST_COMMENTS:
    case commentsActions.RECEIVE_COMMENTS:
    case commentsActions.FAILURE_COMMENTS:
    case commentsActions.SUCCESS_CREATE_COMMENT:
      if (action.commentableType !== 'insight_article') {
        return state;
      }

      return assign({}, state, {
        [key]: update(state[key], action)
      });
    default:
      return state;
    }
  };
}

function commentsBySocialPostIdPaginate() {
  const entityLocator = action => {
    return [...action.payload.result];
  };
  const actionToKey = action => (action.id);

  const updatePagination = updateSocialPaginate({
    fetchActionTypes: [
      fetchActionTypes.SOCIAL_POST_COMMENTS.REQUEST,
      fetchActionTypes.SOCIAL_POST_COMMENTS.RECEIVE,
      fetchActionTypes.SOCIAL_POST_COMMENTS.FAILURE
    ],
    entityLocator,
    isComment: true,
    attachFront: true
  });

  function update(state, action) {
    return updatePagination(state, action);
  }

  return function commentsBySocialPostId(state = {}, action) {
    const key = actionToKey(action);
    switch (action.type) {
    case fetchActionTypes.SOCIAL_POST_COMMENTS.REQUEST:
    case fetchActionTypes.SOCIAL_POST_COMMENTS.RECEIVE:
    case fetchActionTypes.SOCIAL_POST_COMMENTS.FAILURE:
      return assign({}, state, {
        [key]: update(state[key], action)
      });
    default:
      return state;
    }
  };
}

function secondCommentsBySocialPostCommentIdPaginate() {
  const entityLocator = action => (action?.payload || []).map(reply => reply.id);
  const actionToKey = action => (action.id);

  const updatePagination = updateSocialPaginate({
    fetchActionTypes: [
      fetchActionTypes.SOCIAL_POST_SECOND_COMMENTS.REQUEST,
      fetchActionTypes.SOCIAL_POST_SECOND_COMMENTS.RECEIVE,
      fetchActionTypes.SOCIAL_POST_SECOND_COMMENTS.FAILURE
    ],
    entityLocator,
    isComment: true,
    isSecondComment: true,
    attachFront: true
  });

  function update(state, action) {
    return updatePagination(state, action);
  }

  return function secondCommentsBySocialPostCommentId(state = {}, action) {
    const key = actionToKey(action);

    switch (action.type) {
    case fetchActionTypes.SOCIAL_POST_SECOND_COMMENTS.REQUEST:
    case fetchActionTypes.SOCIAL_POST_SECOND_COMMENTS.RECEIVE:
    case fetchActionTypes.SOCIAL_POST_SECOND_COMMENTS.FAILURE:
      return assign({}, state, {
        [key]: update(state[key], action)
      });
    default:
      return state;
    }
  };
}

function favoriteStockLists() {
  const paginator = paginate([
    favoriteStockListsActions.REQUEST_FAVORITE_STOCK_LISTS,
    favoriteStockListsActions.RECEIVE_FAVORITE_STOCK_LISTS,
    favoriteStockListsActions.FAILURE_FAVORITE_STOCK_LISTS
  ], action => (action.payload.result.favoriteStockLists));
  return function updateFavoriteStockLists(state = {}, action) {
    const computedState = paginator(state, action);
    switch (action.type) {
    case favoriteStockListsActions.SUCCESS_ADD_FAVORITE_STOCK_LIST:
      return assign({}, computedState, {
        entities: [...computedState.entities, action.payload.result.favoriteStockList]
      });
    case favoriteStockListsActions.SUCCESS_DELETE_FAVORITE_STOCK_LISTS:
      return assign({}, computedState, {
        entities: difference(computedState.entities, action.payload.result.favoriteStockLists)
      });
    case favoriteStockListsActions.SUCCESS_REORDER_FAVORITE_STOCK_LISTS:
      return assign({}, computedState, {
        entities: [...action.ids]
      });
    case favoriteStockListsActions.INIT_FAVORITE_STOCK_LISTS:
      return {};
    default:
      return computedState;
    }
  };
}

function likedUsersByPostId() {
  const likedUsersPaginator = paginate(
    fetchActionTypes.LIKED_USERS,
    action => (action.payload.result.users),
    action => (action.postId)
  );

  return function updateLikedUsersByPostId(state = {}, action) {
    const computedState = likedUsersPaginator(state, action);
    // paginator에 필요한 state가  아직 없는 경우에 대한 처리. ex) 토론 목록에서 좋아요를 누를 경우.
    const currentEntities = computedState[action.emotionableId] ? computedState[action.emotionableId].entities : [];

    if (action.emotionableType !== 'post') {
      return computedState;
    }

    switch (action.type) {
    case emotionActions.SUCCESS_LIKE:
      return assign({}, computedState, {
        [action.emotionableId]: {
          entities: [action.myId, ...currentEntities]
        }
      });
    case emotionActions.SUCCESS_DISLIKE:
      return assign({}, computedState, {
        [action.emotionableId]: {
          entities: difference(currentEntities, [action.myId])
        }
      });
    case emotionActions.SUCCESS_UNLIKE:
      return assign({}, computedState, {
        [action.emotionableId]: {
          entities: difference(currentEntities, [action.myId])
        }
      });
    default:
      return computedState;
    }
  };
}

function searchPaginate(types, entityLocator, actionToKey) {
  const reducer = paginate(types, entityLocator, actionToKey);
  return (state, action) => {
    const nextState = reducer(state, action);

    const [, successType] = types;
    if (action.type === successType) {
      return assign({}, nextState, {
        reachedEnd: entityLocator(action).length < action.limit
      });
    }
    return nextState;
  };
}

export const paginateReducer = combineReducers({
  dayCandlesBySecurityId: paginate([
    quotationsActions.REQUEST_DAY_CANDLES,
    quotationsActions.RECEIVE_DAY_CANDLES,
    quotationsActions.FAILURE_DAY_CANDLES
  ], action => (action.payload.result.dayCandles), action => (action.securityId)),
  investorsBySecurityId: paginate([
    quotationsActions.REQUEST_INVESTORS,
    quotationsActions.RECEIVE_INVESTORS,
    quotationsActions.FAILURE_INVESTORS
  ], action => (action.payload.result.investors), action => (action.securityId)),
  dayTradersBySecurityId: paginate([
    quotationsActions.REQUEST_DAY_TRADERS,
    quotationsActions.RECEIVE_DAY_TRADERS,
    quotationsActions.FAILURE_DAY_TRADERS
  ], action => (action.payload.result.dayTraders), action => (action.securityId)),
  articlesBySecurityId: paginate([
    newsItemsActions.REQUEST_SECURITY_ARTICLES,
    newsItemsActions.RECEIVE_SECURITY_ARTICLES,
    newsItemsActions.FAILURE_SECURITY_ARTICLES
  ], action => (action.payload.result.newsItems), action => (action.securityId)),
  disclosuresBySecurityId: paginate([
    newsItemsActions.REQUEST_SECURITY_DISCLOSURES,
    newsItemsActions.RECEIVE_SECURITY_DISCLOSURES,
    newsItemsActions.FAILURE_SECURITY_DISCLOSURES
  ], action => (action.payload.result.newsItems), action => (action.securityId)),
  newsBySecurityId: paginate([
    newsItemsActions.REQUEST_SECURITY_NEWS,
    newsItemsActions.RECEIVE_SECURITY_NEWS,
    newsItemsActions.FAILURE_SECURITY_NEWS
  ], action => (action.payload.result.newsItems), action => (action.securityId)),
  articlesBySecurityIds: paginate([
    newsItemsActions.REQUEST_SECURITIES_ARTICLES,
    newsItemsActions.RECEIVE_SECURITIES_ARTICLES,
    newsItemsActions.FAILURE_SECURITIES_ARTICLES
  ], action => (action.payload.result.newsItems), action => (action.securityIds.join(','))),
  disclosuresBySecurityIds: paginate([
    newsItemsActions.REQUEST_SECURITIES_DISCLOSURES,
    newsItemsActions.RECEIVE_SECURITIES_DISCLOSURES,
    newsItemsActions.FAILURE_SECURITIES_DISCLOSURES
  ], action => (action.payload.result.newsItems), action => (action.securityIds.join(','))),
  newsBySecurityIds: paginate([
    newsItemsActions.REQUEST_SECURITIES_NEWS,
    newsItemsActions.RECEIVE_SECURITIES_NEWS,
    newsItemsActions.FAILURE_SECURITIES_NEWS
  ], action => (action.payload.result.newsItems), action => (action.securityIds.join(','))),
  insightArticlesBySecurityId: paginate([
    insightArticlesActions.REQUEST_SECURITY_INSIGHT_ARTICLES,
    insightArticlesActions.RECEIVE_SECURITY_INSIGHT_ARTICLES,
    insightArticlesActions.FAILURE_SECURITY_INSIGHT_ARTICLES
  ], action => (action.payload.result.insightArticles), action => (action.securityId)),
  newsByScope: paginate([
    newsItemsActions.REQUEST_NEWS,
    newsItemsActions.RECEIVE_NEWS,
    newsItemsActions.FAILURE_NEWS
  ], action => (action.payload.result.newsItems), action => (action.scope)),
  collectionStocksByKeyAndMarket: paginate([
    trendActions.REQUEST_COLLECTION_STOCKS,
    trendActions.RECEIVE_COLLECTION_STOCKS,
    trendActions.FAILURE_COLLECTION_STOCKS
  ], action => (action.payload.stocks), action => [action.key, action.market]),
  orderedStocksByKeyAndMarket: paginate([
    trendActions.REQUEST_ORDERED_STOCKS,
    trendActions.RECEIVE_ORDERED_STOCKS,
    trendActions.FAILURE_ORDERED_STOCKS
  ], action => (action.payload.stocks), action => [action.key, action.market]),
  dailyNetTradesByKeyAndMarket: paginate([
    trendActions.REQUEST_DAILY_NET_TRADES,
    trendActions.RECEIVE_DAILY_NET_TRADES,
    trendActions.FAILURE_DAILY_NET_TRADES
  ], action => (action.payload.trades.dailyData), action => [action.key, action.market]),
  consecutiveNetTradesByKeyAndMarket: paginate([
    trendActions.REQUEST_CONSECUTIVE_NET_TRADES,
    trendActions.RECEIVE_CONSECUTIVE_NET_TRADES,
    trendActions.FAILURE_CONSECUTIVE_NET_TRADES
  ], action => ([action.payload.trades]), action => [action.key, action.market]),
  insightArticlesByScope: paginate([
    insightArticlesActions.REQUEST_INSIGHT_ARTICLES,
    insightArticlesActions.RECEIVE_INSIGHT_ARTICLES,
    insightArticlesActions.FAILURE_INSIGHT_ARTICLES
  ], action => (action.payload.result.insightArticles), action => (action.scope)),
  insightArticlesByContributorId: paginate(
    fetchActionTypes.CONTRIBUTOR_ARTICLES,
    action => (action.payload.result.insightArticles), action => (action.id)
  ),
  insightArticlesByFeatured: paginate([
    insightArticlesActions.REQUEST_INSIGHT_ARTICLE_FEATURED,
    insightArticlesActions.RECEIVE_INSIGHT_ARTICLE_FEATURED,
    insightArticlesActions.FAILURE_INSIGHT_ARTICLE_FEATURED
  ], action => action.payload.result.insightArticles),
  postsBySecurityIdAndScope: postsBySecurityIdAndScopePaginate(),
  socialPostsByParams: socialPostsByParamsPaginate(),
  topicsByToday: paginate([
    topicsActions.REQUEST_FEATURED_TOPICS_TODAY,
    topicsActions.RECEIVE_FEATURED_TOPICS_TODAY,
    topicsActions.FAILURE_FEATURED_TOPICS_TODAY
  ], action => (action.payload.result.topics)),
  topicsBySecurityId: paginate(
    fetchActionTypes.SECURITY_TOPICS,
    action => (action.payload.result.topics), action => (action.securityId)
  ),
  commentsByPostId: commentsByPostIdPaginate(),
  commentsByCommentId: commentsByCommentIdPaginate(),
  commentsByInsightArticleId: commentsByInsightArticleIdPaginate(),
  commentsBySocialPostId: commentsBySocialPostIdPaginate(),
  secondCommentsBySocialPostCommentId: secondCommentsBySocialPostCommentIdPaginate(),
  favoriteStockLists: favoriteStockLists(),
  rankersByContestId: paginate(
    fetchActionTypes.CONTEST_RANKERS,
    action => (action.payload.result.rankers),
    action => (action.contestId)
  ),
  favoriteStocksBySecurityId: paginate([
    favoriteStocksActions.REQUEST_FAVORITE_STOCKS_BY_SECURITY_ID,
    favoriteStocksActions.RECEIVE_FAVORITE_STOCKS_BY_SECURITY_ID,
    favoriteStocksActions.FAILURE_FAVORITE_STOCKS_BY_SECURITY_ID
  ], action => (action.payload.result.favoriteStocks), action => (action.securityId)
  ),
  exchangeTradedAssetsBySecurityId: paginate(
    fetchActionTypes.EXCHANGE_TRADED_ASSETS,
    action => (action.payload.result.exchangeTradedAssets),
    action => (action.securityId)
  ),
  popularStocks: paginate([
    popularStocksActions.REQUEST_POPULAR_STOCKS,
    popularStocksActions.RECEIVE_POPULAR_STOCKS,
    popularStocksActions.FAILURE_POPULAR_STOCKS
  ], action => action.payload.result.stocks, action => action.popularId),
  exchangeTradedValuesBySecurityId: paginate(
    fetchActionTypes.EXCHANGE_TRADED_VALUES,
    action => (action.payload.result.exchangeTradedValues),
    action => (action.securityId)
  ),
  topRisingWicsSectors: paginate([
    wicsSectorsActions.REQUEST_TOP_RISING_WICS_SECTORS,
    wicsSectorsActions.RECEIVE_TOP_RISING_WICS_SECTORS,
    wicsSectorsActions.FAILURE_TOP_RISING_WICS_SECTORS
  ], action => (action.payload.result.wicsSectors)),
  securitiesByWicsSectorId: paginate([
    wicsSectorsActions.REQUEST_WICS_SECTOR_SECURITIES,
    wicsSectorsActions.RECEIVE_WICS_SECTOR_SECURITIES,
    wicsSectorsActions.FAILURE_WICS_SECTOR_SECURITIES
  ], action => (action.payload.result.securities), action => (action.sectorId)),
  notices: paginate([
    noticesActions.REQUEST_NOTICES,
    noticesActions.RECEIVE_NOTICES,
    noticesActions.FAILURE_NOTICES
  ], action => (action.payload.result.notices)),
  assetsSearchResultByKeyword: searchPaginate([
    searchActions.REQUEST_ASSETS_SEARCH_RESULT,
    searchActions.RECEIVE_ASSETS_SEARCH_RESULT,
    searchActions.FAILURE_ASSETS_SEARCH_RESULT
  ], action => (action.payload.result.assets), action => (action.keyword)),
  topicsSearchResultByKeyword: searchPaginate([
    searchActions.REQUEST_TOPICS_SEARCH_RESULT,
    searchActions.RECEIVE_TOPICS_SEARCH_RESULT,
    searchActions.FAILURE_TOPICS_SEARCH_RESULT
  ], action => (action.payload.result.topics), action => (action.keyword)),
  newsRelatedAssetsSearchResultByKeyword: searchPaginate([
    searchActions.REQUEST_NEWS_RELATED_ASSETS_SEARCH_RESULT,
    searchActions.RECEIVE_NEWS_RELATED_ASSETS_SEARCH_RESULT,
    searchActions.FAILURE_NEWS_RELATED_ASSETS_SEARCH_RESULT
  ], action => (action.payload.result.newsRelatedAssets), action => (action.keyword)),
  newsSearchResultByKeyword: searchPaginate([
    searchActions.REQUEST_NEWS_SEARCH_RESULT,
    searchActions.RECEIVE_NEWS_SEARCH_RESULT,
    searchActions.FAILURE_NEWS_SEARCH_RESULT
  ], action => (action.payload.result.news), action => (action.keyword)),
  insightArticlesSearchResultByKeyword: searchPaginate([
    searchActions.REQUEST_INSIGHT_ARTICLES_SEARCH_RESULT,
    searchActions.RECEIVE_INSIGHT_ARTICLES_SEARCH_RESULT,
    searchActions.FAILURE_INSIGHT_ARTICLES_SEARCH_RESULT
  ], action => (action.payload.result.insightArticles), action => (action.keyword)),
  yearlyStatementsBySecurityId: paginate([
    companiesActions.REQUEST_COMPANY_FINANCIAL_STATEMENTS,
    companiesActions.RECEIVE_COMPANY_FINANCIAL_STATEMENTS,
    companiesActions.FAILURE_COMPANY_FINANCIAL_STATEMENTS
  ], action => (action.payload.result.yearly), action => (action.securityId)),
  quarterlyStatementsBySecurityId: paginate([
    companiesActions.REQUEST_COMPANY_FINANCIAL_STATEMENTS,
    companiesActions.RECEIVE_COMPANY_FINANCIAL_STATEMENTS,
    companiesActions.FAILURE_COMPANY_FINANCIAL_STATEMENTS
  ], action => (action.payload.result.quarterly), action => (action.securityId)),
  likedUsersByPostId: likedUsersByPostId(),
  dayForexesByAssetId: reducers.dayForexesByAssetId,
  tickForexesByAssetId: reducers.tickForexesByAssetId
});
