import { values } from 'lodash';
import { CALL_API } from '../middlewares/api';
import { Routes } from '../routes';
import { favoriteStockList, favoriteStockListArray } from '../schemas/favorite_stock_list';
import { fetchAssets } from './assets';

export const REQUEST_FAVORITE_STOCK_LISTS = 'REQUEST_FAVORITE_STOCK_LISTS';
export const RECEIVE_FAVORITE_STOCK_LISTS = 'RECEIVE_FAVORITE_STOCK_LISTS';
export const FAILURE_FAVORITE_STOCK_LISTS = 'FAILURE_FAVORITE_STOCK_LISTS';
export const INIT_FAVORITE_STOCK_LISTS = 'INIT_FAVORITE_STOCK_LISTS';

export function initFavoriteStockLists() {
  return {
    type: INIT_FAVORITE_STOCK_LISTS,
  };
}

export function fetchFavoriteStockLists() {
  return {
    [CALL_API]: {
      types: [REQUEST_FAVORITE_STOCK_LISTS, RECEIVE_FAVORITE_STOCK_LISTS, FAILURE_FAVORITE_STOCK_LISTS],
      config: {
        method: 'get',
        url: Routes.api_favorite_stock_lists_path({ format: 'json' })
      },
      schema: {
        favoriteStockLists: favoriteStockListArray
      }
    }
  };
}

export function loadFavoriteStockLists() {
  return (dispatch, getState) => {
    const {
      paginate: {
        favoriteStockLists
      }
    } = getState();

    if (favoriteStockLists.pageCount > 0) {
      return Promise.resolve();
    }

    return dispatch(fetchFavoriteStockLists());
  };
}

export const REQUEST_FAVORITE_STOCK_LIST = 'REQUEST_FAVORITE_STOCK_LIST';
export const RECEIVE_FAVORITE_STOCK_LIST = 'RECEIVE_FAVORITE_STOCK_LIST';
export const FAILURE_FAVORITE_STOCK_LIST = 'FAILURE_FAVORITE_STOCK_LIST';

export function fetchFavoriteStockList(id) {
  return {
    [CALL_API]: {
      types: [REQUEST_FAVORITE_STOCK_LIST, RECEIVE_FAVORITE_STOCK_LIST, FAILURE_FAVORITE_STOCK_LIST],
      config: {
        method: 'get',
        url: Routes.api_favorite_stock_list_path(id, { format: 'json' })
      },
      schema: {
        favoriteStockList
      }
    },
    id
  };
}

export function fetchFavoriteStockListWithAssets(id) {
  return (dispatch) => {
    return dispatch(fetchFavoriteStockList(id)).then((action) => {
      const favoriteStocks = values(action.payload.entities.favoriteStocks);
      const assetIds = favoriteStocks.map(s => s.assetId);
      return dispatch(fetchAssets(assetIds));
    });
  };
}

export const SELECT_FAVORITE_STOCK_LIST = 'SELECT_FAVORITE_STOCK_LIST';
export function selectFavoriteStockList(id) {
  return {
    type: SELECT_FAVORITE_STOCK_LIST,
    id
  };
}

export const CLEAR_SELECTED_FAVORITE_STOCK_LISTS = 'CLEAR_SELECTED_FAVORITE_STOCK_LISTS';
export function clearSelectedFavoriteStockLists() {
  return {
    type: CLEAR_SELECTED_FAVORITE_STOCK_LISTS
  };
}

export const EDIT_FAVORITE_STOCK_LIST = 'EDIT_FAVORITE_STOCK_LIST';
export function editFavoriteStockList(id) {
  return {
    type: EDIT_FAVORITE_STOCK_LIST,
    id
  };
}

export const REQUEST_UPDATE_FAVORITE_STOCK_LIST = 'REQUEST_UPDATE_FAVORITE_STOCK_LIST';
export const SUCCESS_UPDATE_FAVORITE_STOCK_LIST = 'SUCCESS_UPDATE_FAVORITE_STOCK_LIST';
export const FAILURE_UPDATE_FAVORITE_STOCK_LIST = 'FAILURE_UPDATE_FAVORITE_STOCK_LIST';

export function updateFavoriteStockList(id, name) {
  return {
    [CALL_API]: {
      types: [REQUEST_UPDATE_FAVORITE_STOCK_LIST, SUCCESS_UPDATE_FAVORITE_STOCK_LIST, FAILURE_UPDATE_FAVORITE_STOCK_LIST],
      config: {
        method: 'put',
        url: Routes.api_favorite_stock_list_path(id, { format: 'json' }),
        data: {
          name
        }
      },
      schema: {
        favoriteStockList
      }
    },
    id,
    name
  };
}

export const REQUEST_ADD_FAVORITE_STOCK_LIST = 'REQUEST_ADD_FAVORITE_STOCK_LIST';
export const SUCCESS_ADD_FAVORITE_STOCK_LIST = 'SUCCESS_ADD_FAVORITE_STOCK_LIST';
export const FAILURE_ADD_FAVORITE_STOCK_LIST = 'FAILURE_ADD_FAVORITE_STOCK_LIST';

export function addFavoriteStockList(name) {
  return {
    [CALL_API]: {
      types: [REQUEST_ADD_FAVORITE_STOCK_LIST, SUCCESS_ADD_FAVORITE_STOCK_LIST, FAILURE_ADD_FAVORITE_STOCK_LIST],
      config: {
        method: 'post',
        url: Routes.api_favorite_stock_lists_path({ format: 'json' }),
        data: {
          name
        }
      },
      schema: {
        favoriteStockList
      }
    },
    name
  };
}

export const REQUEST_DELETE_FAVORITE_STOCK_LISTS = 'REQUEST_DELETE_FAVORITE_STOCK_LISTS';
export const SUCCESS_DELETE_FAVORITE_STOCK_LISTS = 'SUCCESS_DELETE_FAVORITE_STOCK_LISTS';
export const FAILURE_DELETE_FAVORITE_STOCK_LISTS = 'FAILURE_DELETE_FAVORITE_STOCK_LISTS';

export function deleteFavoriteStockLists(ids) {
  return {
    [CALL_API]: {
      types: [REQUEST_DELETE_FAVORITE_STOCK_LISTS, SUCCESS_DELETE_FAVORITE_STOCK_LISTS, FAILURE_DELETE_FAVORITE_STOCK_LISTS],
      config: {
        method: 'delete',
        url: Routes.destroy_api_favorite_stock_lists_path({ format: 'json' }),
        data: {
          ids
        }
      },
      schema: {
        favoriteStockLists: favoriteStockListArray
      }
    },
    ids
  };
}

export const REQUEST_REORDER_FAVORITE_STOCK_LISTS = 'REQUEST_REORDER_FAVORITE_STOCK_LISTS';
export const SUCCESS_REORDER_FAVORITE_STOCK_LISTS = 'SUCCESS_REORDER_FAVORITE_STOCK_LISTS';
export const FAILURE_REORDER_FAVORITE_STOCK_LISTS = 'FAILURE_REORDER_FAVORITE_STOCK_LISTS';

export function reorderFavoriteStockLists(ids) {
  return {
    [CALL_API]: {
      types: [REQUEST_REORDER_FAVORITE_STOCK_LISTS, SUCCESS_REORDER_FAVORITE_STOCK_LISTS, FAILURE_REORDER_FAVORITE_STOCK_LISTS],
      config: {
        method: 'post',
        url: Routes.reorder_api_favorite_stock_lists_path({ format: 'json' }),
        data: {
          ids
        }
      }
    },
    ids
  };
}

export const REQUEST_CONSTRUCT_FAVORITE_STOCK_LIST = 'REQUEST_CONSTRUCT_FAVORITE_STOCK_LIST';
export const SUCCESS_CONSTRUCT_FAVORITE_STOCK_LIST = 'SUCCESS_CONSTRUCT_FAVORITE_STOCK_LIST';
export const FAILURE_CONSTRUCT_FAVORITE_STOCK_LIST = 'FAILURE_CONSTRUCT_FAVORITE_STOCK_LIST';

export function constructFavoriteStockList(name, securityIds) {
  return {
    [CALL_API]: {
      types: [
        REQUEST_CONSTRUCT_FAVORITE_STOCK_LIST,
        SUCCESS_CONSTRUCT_FAVORITE_STOCK_LIST,
        FAILURE_CONSTRUCT_FAVORITE_STOCK_LIST
      ],
      config: {
        method: 'post',
        url: Routes.construct_api_favorite_stock_lists_path({ format: 'json' }),
        data: {
          name,
          security_ids: securityIds.join(',')
        }
      }
    },
    name,
    securityIds
  };
}
