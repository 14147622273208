import { combineReducers } from 'redux';
import { merge } from 'lodash';
import fetchActionTypes from '../constants/fetch_actions';

export function insightArticlesReducer(state = {}, action) {
  switch (action.type) {
  case fetchActionTypes.INSIGHT_ARTICLE.RECEIVE:
    return merge({}, state, {
      [action.payload.result.insightArticle]: {
        prevArticle: action.payload.result.prevCursor,
        nextArticle: action.payload.result.nextCursor
      }
    });
  default:
    return state;
  }
}

export const investingStrategiesReducer = combineReducers({
  insightArticles: insightArticlesReducer
});
