import { assign } from 'lodash';
import { CLICK_TAB } from '../../actions/tabs';
import { actionTypes as contestActionTypes } from '../../constants/contests';

function tabs(state = {
  selectedTabIndex: 0
}, action) {
  switch (action.type) {
  case CLICK_TAB:
    return assign({}, state, {
      selectedTabIndex: action.selectedTabIndex
    });
  default:
    return state;
  }
}

export function tabsReducer(state = {}, action) {
  switch (action.type) {
  case CLICK_TAB:
    return assign({}, state, {
      [action.name]: tabs(state[action.name], action)
    });
  case contestActionTypes.CHANGE:
    return assign({}, state, {
      contests: action.contestId
    });
  default:
    return state;
  }
}
