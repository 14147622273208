import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { loadComponent } from '../helper';

import investingStrategiesRoute from './investing_strategies';
import trendRoute from './trend';
import rankersRoute from './rankers';
import newsRoute from './news';

export default (
  <Route
    path="m" getComponent={(location, cb) =>
      import('../../containers/home/home_main').then(loadComponent(cb))
    }
  >
    <IndexRoute
      getComponent={(location, cb) =>
        import('../../containers/home/home_page').then(loadComponent(cb))
      }
    />

    {investingStrategiesRoute}
    {trendRoute}
    {rankersRoute}
    {newsRoute}

  </Route>
);
