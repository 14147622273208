import { merge, assign } from 'lodash';
import * as DebatesActions from '../../actions/debates';
import * as PostsActions from '../../actions/posts';
import { SORT_TYPES } from '../../constants/debates';

const initialState = {
  category: 'all',
  sort: SORT_TYPES.RECENT,
  profitFilterType: null
};

export function debatesReducer(state = initialState, action) {
  switch (action.type) {
  case DebatesActions.SELECT_DEBATES_CATEGORY:
    return assign({}, state, {
      category: action.category
    });
  case DebatesActions.CLICK_DEBATES_SORT:
    return assign({}, state, {
      sort: action.sort
    });
  case DebatesActions.CLICK_DEBATES_PROFIT_FILTER_TYPE:
    return assign({}, state, {
      profitFilterType: action.profitFilterType
    });
  case DebatesActions.RESET_DEBATES_SORT:
    return assign({}, state, {
      sort: null
    });
  case DebatesActions.RESET_DEBATES_PROFIT_FILTER_TYPE:
    return assign({}, state, {
      profitFilterType: null
    });
  case PostsActions.RECEIVE_SECURITY_POST:
    return merge({}, state, {
      [action.payload.result.post]: {
        prevPost: action.payload.result.prevCursor,
        nextPost: action.payload.result.nextCursor
      }
    });
  default:
    return state;
  }
}
