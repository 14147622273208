export const NETWORK_TURN_ONLINE = 'NETWORK_TURN_ONLINE';
export function networkTurnOnline() {
  return {
    type: NETWORK_TURN_ONLINE
  };
}

export const NETWORK_TURN_OFFLINE = 'NETWORK_TURN_OFFLINE';
export function networkTurnOffline() {
  return {
    type: NETWORK_TURN_OFFLINE
  };
}
