import { Routes } from '../routes';
import { CALL_API } from '../middlewares/api';
import fetchActionTypes from '../constants/fetch_actions';

export function report(id, reportableType, reportType, content) {
  return {
    [CALL_API]: {
      types: fetchActionTypes.REPORTS,
      config: {
        method: 'post',
        url: Routes.api_reports_path({ format: 'json' }),
        data: {
          type: reportableType,
          id,
          reportType,
          content
        }
      }
    },
    id,
    reportableType
  };
}
