import { assign } from 'lodash';
import * as actions from '../../actions/my';
import type { Action } from '../../types/action';

const initialState = {
  showChartImage: false,
  showImportBanner: true
};

type State = typeof initialState;

export function myReducer(state: State = initialState, action: Action): State {
  switch (action.type) {
  case actions.TOGGLE_SHOW_CHART_IMAGE:
    return assign({}, state, {
      showChartImage: !state.showChartImage
    });
  case actions.RESET_MY_UI:
    return initialState;
  default:
    return state;
  }
}
