import store from '../stores';
import { Routes } from '../routes';
import { routeActions } from 'react-router-redux';
import { tryLogin, SUCCESS_LOGIN, SUCCESS_ACTIVATE } from '../actions/login';
import { enableDnmStylesheet, disableDnmStylesheet } from '../actions/stylesheet';

export function loadComponent(cb) {
  return module => cb(null, module.default);
}

export function requireAuth(nextState, replace) {
  // 로그인이 되어 있지 않을 경우
  if (!store.getState().login.isAuthenticated) {
    // 홈화면으로 이동한다.
    replace(Routes.m_path());
    // 단 로그인 창이 뜨고
    store.dispatch(tryLogin())
      .then((res) => {
      // 로그인에 성공했을 경우엔 기존에 들어가고자 하는 페이지로 이동한다.
        if (res.type === SUCCESS_LOGIN || res.type === SUCCESS_ACTIVATE) {
          store.dispatch(routeActions.replace(nextState.location.pathname));
        }
      });
  }
}

export function enableStylesheet() {
  store.dispatch(enableDnmStylesheet());
}

export function disableStylesheet() {
  store.dispatch(disableDnmStylesheet());
}
