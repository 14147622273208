import { merge } from 'lodash';
import { createMemoryHistory } from 'history';
import { routeActions } from 'react-router-redux';
import { requireLogin } from './login';
import { Routes } from '../routes';

const history = createMemoryHistory();

export function goTo(locationDescriptor, { replace = false, saveLocation = false } = {}) {
  return (dispatch, getState) => {
    const { routing: { location: currentLocation } } = getState();

    // replace 시에는 현재 경로 저장을 하지 않는다
    if (replace) {
      return dispatch(routeActions.replace(locationDescriptor));
    }

    const location = history.createLocation(locationDescriptor);
    if (currentLocation.state && currentLocation.state.savedLocation) {
      location.state = merge({}, location.state, {
        savedLocation: currentLocation.state.savedLocation,
        locationDistance: currentLocation.state.locationDistance + 1
      });
    }

    if (saveLocation) {
      location.state = merge({}, location.state, {
        savedLocation: currentLocation,
        locationDistance: 1
      });
    }

    return dispatch(routeActions.push(location));
  };
}

export const loginAndGoTo = requireLogin(goTo);

export function goBack({ restoreLocation = false, fallbackLocation = null } = {}) {
  return (dispatch, getState) => {
    const { routing: { location: currentLocation } } = getState();
    const savedLocation = currentLocation.state && currentLocation.state.savedLocation;

    if (restoreLocation && savedLocation) {
      if (window.history.length - 1 > currentLocation.state.locationDistance) {
        return dispatch(routeActions.go(currentLocation.state.locationDistance * -1));
      }
    }

    if (!restoreLocation) {
      return dispatch(routeActions.goBack());
    }

    return dispatch(goTo(fallbackLocation, { replace: true }));
  };
}

export const goToMyMain = () =>
  goTo(Routes.mobile_recent_view_stocks_path());

export const goToEditFavoriteStockLists = (...params) =>
  loginAndGoTo(Routes.edit_mobile_favorite_stock_lists_path(...params));

export const goToFavoriteStockList = (id, ...params) =>
  loginAndGoTo(Routes.mobile_favorite_stock_list_path(id), ...params);

export const goToEditFavoriteStocks = (id, ...params) =>
  loginAndGoTo(Routes.edit_mobile_favorite_stock_list_favorite_stocks_path(id), ...params);

export const goToAddFavoriteStocks = (id, keyword = undefined, ...params) =>
  loginAndGoTo(Routes.add_mobile_favorite_stock_list_favorite_stocks_path(id, { keyword }), ...params);

export const goToSearchQuery = (keyword, ...params) =>
  goTo(Routes.query_mobile_search_index_path({ keyword }), ...params);

export const goToSearchMain = (...params) =>
  goTo(Routes.mobile_search_index_path(), ...params);

export const goToStocksMain = (id, ...params) =>
  goTo(Routes.mobile_stock_path(id), ...params);

export const goToRankerHome = (id, ...params) =>
  goTo(Routes.mobile_ranker_path(id), ...params);

export const goToAssetsMain = (id, ...params) =>
  goTo(Routes.mobile_asset_path(id), ...params);
