import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { loadComponent } from '../helper';

export default (
  <Route path="m">
    <Route path="search">
      <IndexRoute
        getComponent={(location, cb) =>
          import('../../containers/search/search_container').then(loadComponent(cb))
        }
      />
      <Route
        path="query" getComponent={(location, cb) =>
          import('../../containers/search/search_query_container').then(loadComponent(cb))
        }
      />
    </Route>
  </Route>
);
