import React from 'react';
import { Route, IndexRoute, IndexRedirect } from 'react-router';
import { loadComponent, enableStylesheet, disableStylesheet } from '../../helper';

export default (
  <Route path="trend">
    <IndexRedirect to="domestic" />

    <Route
      path="domestic" getComponent={
        (location, cb) =>
          import('../../../containers/trend/trend_index_container')
            .then(loadComponent(cb))
      }
    />
    <Route
      path="foreign" getComponent={
        (location, cb) =>
          import('../../../containers/trend/trend_foreign_container')
            .then(loadComponent(cb))
      }
    />
    <Route
      path="forex" onEnter={enableStylesheet} onLeave={disableStylesheet} getComponent={
        (location, cb) =>
          import('../../../containers/trend/trend_forex_container')
            .then(loadComponent(cb))
      }
    />
    <Route>
      <Route
        path="kospi" getComponent={
          (location, cb) =>
            import('../../../containers/trend/trend_kospi_container')
              .then(loadComponent(cb))
        }
      />
      <Route
        path="kosdaq" getComponent={
          (location, cb) =>
            import('../../../containers/trend/trend_kosdaq_container')
              .then(loadComponent(cb))
        }
      />
      <Route
        path="collection_a" getComponent={
          (location, cb) =>
            import('../../../containers/trend/trend_collection_a_container')
              .then(loadComponent(cb))
        }
      />
      <Route
        path="collection_b" getComponent={
          (location, cb) =>
            import('../../../containers/trend/trend_collection_b_container')
              .then(loadComponent(cb))
        }
      />

      <Route path="wics_sectors">
        <IndexRoute
          getComponent={
            (location, cb) =>
              import('../../../containers/wics_sectors/wics_sectors_index_container')
                .then(loadComponent(cb))
          }
        />
        <Route
          path=":id" getComponent={
            (location, cb) =>
              import('../../../containers/wics_sectors/wics_sectors_show_container')
                .then(loadComponent(cb))
          }
        />
      </Route>
    </Route>
  </Route>
);
