import { combineReducers } from 'redux';
import { modalsReducer } from './modals';
import { tabsReducer } from './tabs';
import { myReducer } from './my';
import { debatesReducer } from './debates';
import { favoriteStocksReducer } from './favorite_stocks';
import { recentViewStocksReducer } from './recent_view_stocks';
import { favoriteStockListsReducer } from './favorite_stock_lists';
import { radioButtonReducer } from './radio_buttons';
import { floatingsReducer } from './floatings';
import { majorForexIdsReducer } from './major_forex_ids';
import { historySnapshotReducer } from './history_snapshot';
import { stylesheetReducer } from './stylesheet';
import { rankersReducer } from './rankers';

export const uiReducer = combineReducers({
  majorForexIds: majorForexIdsReducer,
  modals: modalsReducer,
  tabs: tabsReducer,
  my: myReducer,
  debates: debatesReducer,
  rankers: rankersReducer,
  favoriteStocks: favoriteStocksReducer,
  recentViewStocks: recentViewStocksReducer,
  favoriteStockLists: favoriteStockListsReducer,
  radioButtons: radioButtonReducer,
  floatings: floatingsReducer,
  historySnapshot: historySnapshotReducer,
  stylesheet: stylesheetReducer
});
