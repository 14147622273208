/* @flow */
import { assign } from 'lodash';
import { RECEIVE_FAVORITE_STOCKS_BY_SECURITY_ID } from '../../actions/favorite_stocks';

import type { FavoriteStockExistence } from '../../types/favorite_stock_existence';
import type { Action } from '../../types/action';

type FavoriteStockExistenceMap = {
  [securityId: string]: FavoriteStockExistence
};

function favoriteStockExistence(state: FavoriteStockExistence, action: Action): FavoriteStockExistence {
  switch (action.type) {
  case RECEIVE_FAVORITE_STOCKS_BY_SECURITY_ID:
    if (action.payload) {
      return assign({}, state, {
        isExist: action.payload.result.favoriteStocks.length > 0
      });
    }
    return state;
  default:
    return state;
  }
}

export function favoriteStockExistencesReducer(state: FavoriteStockExistenceMap = {}, action: Action): FavoriteStockExistenceMap {
  switch (action.type) {
  case RECEIVE_FAVORITE_STOCKS_BY_SECURITY_ID:
    return assign({}, state, {
      [action.securityId]: favoriteStockExistence(state[action.securityId], action)
    });
  default:
    return state;
  }
}
