/* @flow */
import { assign } from 'lodash';
import { CLICK_RADIO_BUTTON, RESET_RADIO_BUTTON } from '../../constants/radio_buttons';

import type { RadioButtonAction, RadioButtonState } from '../../types/radio_buttons';

export function radioButtonReducer(state: RadioButtonState = {}, action: RadioButtonAction): RadioButtonState {
  switch (action.type) {
  case CLICK_RADIO_BUTTON:
    return assign({}, state, {
      [action.name]: action.selectedButtonIndex
    });
  case RESET_RADIO_BUTTON:
    return assign({}, state, {
      [action.name]: action.selectedButtonIndex
    });
  default:
    return state;
  }
}
