import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { loadComponent } from '../../helper';

export default (
  <Route path="rankers">
    <IndexRoute
      getComponent={
        (location, cb) =>
          import('../../../containers/rankers/rankers_index_container')
            .then(loadComponent(cb))
      }
    />
    <Route>
      <Route
        path=":id" getComponent={
          (location, cb) =>
            import('../../../containers/rankers/ranker_home_container')
              .then(loadComponent(cb))
        }
      />
    </Route>
  </Route>
);
