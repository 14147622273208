import { reactionsReducer } from './reactions';
import { assign } from 'lodash';
import fetchActionTypes from '../../constants/fetch_actions';

export function socialPostsReducer(state = {}, action) {
  switch (action.type) {
  case fetchActionTypes.ADD_SOCIAL_POST_REACTION.RECEIVE:
  case fetchActionTypes.REMOVE_SOCIAL_POST_REACTION.RECEIVE:
    return assign({}, state, {
      [action.id]: reactionsReducer(state[action.id], action)
    });
  default:
    return state;
  }
}
