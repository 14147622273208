import React from 'react';
import { Route, IndexRoute } from 'react-router';
import { loadComponent } from '../helper';

export default (
  <Route path="m">
    <Route path="my">
      <Route path="history">
        <IndexRoute
          getComponent={(location, cb) =>
            import('../../containers/recent_view_stocks/recent_view_stocks_index_page').then(loadComponent(cb))
          }
        />
        <Route>
          <Route
            path="edit" getComponent={(location, cb) =>
              import('../../containers/recent_view_stocks/recent_view_stocks_edit_collection_container').then(loadComponent(cb))
            }
          />
        </Route>
      </Route>
      <Route path="favorite_stock_lists">
        <Route>
          <Route
            path="edit" getComponent={(location, cb) =>
              import('../../containers/favorite_stock_lists/edit_favorite_stock_lists_container').then(loadComponent(cb))
            }
          />
        </Route>
        <Route
          path=":id" getComponent={(location, cb) =>
            import('../../containers/favorite_stock_lists/favorite_stock_list_page').then(loadComponent(cb))
          }
        />
        <Route path=":id">
          <Route
            path="favorite_stocks/edit" getComponent={(location, cb) =>
              import('../../containers/favorite_stocks/edit_favorite_stocks_container').then(loadComponent(cb))
            }
          />
          <Route
            path="favorite_stocks/add" getComponent={(location, cb) =>
              import('../../containers/favorite_stocks/add_favorite_stocks_container').then(loadComponent(cb))
            }
          />
        </Route>
      </Route>
    </Route>
  </Route>
);
