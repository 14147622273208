import Scheme from '../components/common/scheme';
import ga from 'react-ga';

export const WARP_SUCCESS = 'WARP_SUCCESS';
export function warpSuccess(success, destination) {
  return {
    type: WARP_SUCCESS,
    destination,
    success
  };
}

export const WARP_FAIL = 'WARP_FAIL';
export function warpFail(error, destination) {
  return {
    type: WARP_FAIL,
    destination,
    error
  };
}

export function createWarpGate() {
  return (_dispatch) => {
    window.scheme = new Scheme();
    return Promise.resolve();
  };
}

export function gaWrapper(category, action, labelValue) {
  if (process.env.NODE_ENV === 'production') {
    ga.event({ category, action, label: labelValue });
  } else {
    console.groupCollapsed('Google Analytics 명령이 감지되었습니다.');
    console.info(`ga.event({ category: '${category}', action: '${action}', label: '${labelValue}' });`);
    console.info('Google Analytics는 production 환경만 트래킹합니다.');
    console.groupEnd();
  }
}

export function requestWarp(host, params, scheme, pkgName, itunesUrl, universalLink) {
  return (dispatch) => {
    if (typeof window.scheme === 'undefined') {
      return dispatch(createWarpGate()).then(requestWarp(host, params, scheme, pkgName, itunesUrl, universalLink));
    } else if (window.scheme.platform === 'pc') {
      return dispatch(warpFail('워프 실패', window.scheme.getDestination(host, params)));
    }
    window.scheme.warpTo(host, params, scheme, pkgName, itunesUrl, universalLink);
    return dispatch(warpSuccess('워프 성공', window.scheme.getDestination(host, params)));
  };
}

export function requestOneLinkWarp(oneLinkSubdomain, params) {
  return (dispatch) => {
    if (typeof window.scheme === 'undefined') {
      return dispatch(createWarpGate()).then(requestOneLinkWarp(oneLinkSubdomain, params));
    }

    window.scheme.oneLinkWarpTo(oneLinkSubdomain, params);
    return dispatch(warpSuccess('워프 성공', window.scheme.getDestination('', params)));
  };
}
