import ga from 'react-ga';
import { UPDATE_LOCATION } from 'react-router-redux';

export const analyticsMiddleware = _store => next => (action) => {
  if (action.type === UPDATE_LOCATION) {
    const fullPath = `${action.payload.pathname}${action.payload.search}`;
    if (process.env.NODE_ENV === 'production') {
      ga.pageview(fullPath);
    } else {
      console.groupCollapsed('Google Analytics 명령이 감지되었습니다.');
      console.info(`COMMAND: ga('send', 'pageview', '${fullPath}')`);
      console.info('Google Analytics는 production 환경만 트래킹합니다.');
      console.groupEnd();
    }
  }

  return next(action);
};
