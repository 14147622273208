import * as emotionsActions from '../../actions/emotions';
import * as commentsActions from '../../actions/comments';
import { emotionsReducer } from './emotions';
import { commentsReducer } from './comments';
import { assign } from 'lodash';

export function postsReducer(state = {}, action) {
  switch (action.type) {
  case emotionsActions.SUCCESS_LIKE:
  case emotionsActions.SUCCESS_DISLIKE:
  case emotionsActions.SUCCESS_UNLIKE:
    if (action.emotionableType !== 'post') {
      return state;
    }
    return assign({}, state, {
      [action.emotionableId]: emotionsReducer(state[action.emotionableId], action)
    });
  case commentsActions.SUCCESS_CREATE_COMMENT:
    if (action.commentableType !== 'post') {
      return state;
    }
    return assign({}, state, {
      [action.commentableId]: commentsReducer(state[action.commentableId], action)
    });
  case commentsActions.SUCCESS_DELETE_COMMENT:
    if (action.commentableType !== 'post') {
      return state;
    }
    return assign({}, state, {
      [action.commentableId]: commentsReducer(state[action.commentableId], action)
    });
  default:
    return state;
  }
}
