import { createSelector } from 'reselect';
import fetchActionTypes from '../../constants/fetch_actions';
import { paginate } from '../paginate';
import { normalizedResultSelectorFactory } from '../../selectors/paginate_selectors';

const normalizedResultSelector = createSelector(
  normalizedResultSelectorFactory('tickForexes'),
  normalizedResult => normalizedResult
);

const tickForexesByAssetId = paginate(
  fetchActionTypes.TICK_FOREXES,
  normalizedResultSelector,
  action => (action.id)
);

export default tickForexesByAssetId;
