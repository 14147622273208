import { assign } from 'lodash';
import fetchActionTypes from '../../constants/fetch_actions';

const initialState = {};

export function reactionsReducer(state = initialState, action) {
  switch (action.type) {
  case fetchActionTypes.ADD_SOCIAL_POST_REACTION.RECEIVE:
  case fetchActionTypes.ADD_SOCIAL_POST_COMMENT_REACTION.RECEIVE:
  case fetchActionTypes.ADD_SOCIAL_POST_SECOND_COMMENT_REACTION.RECEIVE:
    return assign({}, state, {
      reaction: { ...state.reaction, type: action.reactionType },
      likes: action.payload.result.likes,
      dislikes: action.payload.result.dislikes
    });
  case fetchActionTypes.REMOVE_SOCIAL_POST_REACTION.RECEIVE:
  case fetchActionTypes.REMOVE_SOCIAL_POST_COMMENT_REACTION.RECEIVE:
  case fetchActionTypes.REMOVE_SOCIAL_POST_SECOND_COMMENT_REACTION.RECEIVE:
    return assign({}, state, {
      reaction: { ...state.reaction, type: null },
      likes: action.payload.result.likes,
      dislikes: action.payload.result.dislikes
    });
  default:
    return state;
  }
}
