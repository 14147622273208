import React from 'react';

export default class TopButtonFloatingContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDisplay: false
    };

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const scrollTop = document.body.scrollTop;
    const screenHeight = window.screen.height;

    this.setState({
      isDisplay: (parseInt(screenHeight * (1 / 3)) < scrollTop)
    });
  }

  handleClick(e) {
    e.preventDefault();
    document.body.scrollTop = 0;
  }

  render() {
    if (!this.state.isDisplay) {
      return null;
    }

    return (
      <a href="#" className="btnTop" title="위로" onClick={this.handleClick.bind(this)}>위로</a>
    );
  }
}
