import { CALL_API } from '../middlewares/api';

export const REQUEST_SECURITY_FIRMS = 'REQUEST_SECURITY_FIRMS';
export const SUCCESS_SECURITY_FIRMS = 'SUCCESS_SECURITY_FIRMS';
export const FAILURE_SECURITY_FIRMS = 'FAILURE_SECURITY_FIRMS';

// todo (linuss, 220921) : 파라미터중 market code와 version code는 optional로 전환 예정. 전환 이후 제거 필요
export function fetchSecurityFirms() {
  return {
    [CALL_API]: {
      types: [REQUEST_SECURITY_FIRMS, SUCCESS_SECURITY_FIRMS, FAILURE_SECURITY_FIRMS],
      config: {
        method: 'get',
        isMainAPI: true,
        url: '/v13/security_firms',
        params: {
          os: 'web',
          market_code: 'nomarket',
          version_code: '-1'
        }
      }
    }
  };
}
