export const OPEN_FLOATING = 'OPEN_FLOATING';
export function openFloating(floatingType, floatingProps) {
  return {
    type: OPEN_FLOATING,
    floatingType,
    floatingProps
  };
}

export const CLOSE_FLOATING = 'CLOSE_FLOATING';
export function cloaseFloating() {
  return {
    type: CLOSE_FLOATING
  };
}

export const RESET_FLOATING = 'RESET_FLOATING';
export function resetFloating() {
  return { type: RESET_FLOATING };
}
