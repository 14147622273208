import { CALL_API } from '../middlewares/api';
import { Routes } from '../routes';
import { wicsSectorArray } from '../schemas/wics_sector';
import { recentSecurityArray } from '../schemas/recent_security';

export const REQUEST_TOP_RISING_WICS_SECTORS = 'REQUEST_TOP_RISING_WICS_SECTORS';
export const RECEIVE_TOP_RISING_WICS_SECTORS = 'RECEIVE_TOP_RISING_WICS_SECTORS';
export const FAILURE_TOP_RISING_WICS_SECTORS = 'FAILURE_TOP_RISING_WICS_SECTORS';

export function fetchTopRisingWicsSectors(limit: number = 0): any {
  return {
    [CALL_API]: {
      types: [
        REQUEST_TOP_RISING_WICS_SECTORS,
        RECEIVE_TOP_RISING_WICS_SECTORS,
        FAILURE_TOP_RISING_WICS_SECTORS
      ],
      config: {
        method: 'get',
        url: Routes.top_wics_sectors_api_trends_path({ limit, format: 'json' })
      },
      schema: {
        wicsSectors: wicsSectorArray
      }
    },
    limit
  };
}

export function loadTopRisingWicsSectors(limit: number = 0): any {
  return (dispatch, getState) => {
    const {
      paginate: {
        topRisingWicsSectors
      }
    } = getState();

    if (topRisingWicsSectors.pageCount > 0 && limit > 0 && topRisingWicsSectors.entities.length >= limit) {
      return Promise.resolve();
    }

    return dispatch(fetchTopRisingWicsSectors(limit));
  };
}

export const REQUEST_WICS_SECTOR_SECURITIES = 'REQUEST_WICS_SECTOR_SECURITIES';
export const RECEIVE_WICS_SECTOR_SECURITIES = 'RECEIVE_WICS_SECTOR_SECURITIES';
export const FAILURE_WICS_SECTOR_SECURITIES = 'FAILURE_WICS_SECTOR_SECURITIES';

export function fetchWicsSectorSecurities(sectorId: string): any {
  return {
    [CALL_API]: {
      types: [
        REQUEST_WICS_SECTOR_SECURITIES,
        RECEIVE_WICS_SECTOR_SECURITIES,
        FAILURE_WICS_SECTOR_SECURITIES
      ],
      config: {
        method: 'get',
        url: Routes.api_wics_sector_securities_path(sectorId, { format: 'json' })
      },
      schema: {
        securities: recentSecurityArray
      }
    },
    sectorId
  };
}

export function loadWicsSectorSecurities(sectorId: string): any {
  return (dispatch, getState) => {
    const {
      paginate: {
        securitiesByWicsSectorId: {
          [sectorId]: pagination
        }
      }
    } = getState();

    if (pagination && pagination.pageCount > 0) {
      return Promise.resolve();
    }

    return dispatch(fetchWicsSectorSecurities(sectorId));
  };
}
