import { CALL_API } from '../middlewares/api';
import { Routes } from '../routes';
import { dayCandleArray } from '../schemas/day_candle';
import { investorArray } from '../schemas/investor';
import { dayTraderArray } from '../schemas/day_trader';
import { recentTrader } from '../schemas/recent_trader';

export const REQUEST_DAY_CANDLES = 'REQUEST_DAY_CANDLES';
export const RECEIVE_DAY_CANDLES = 'RECEIVE_DAY_CANDLES';
export const FAILURE_DAY_CANDLES = 'FAILURE_DAY_CANDLES';

/**
 * 일별 시세 가져오기
 * @param  {String} securityId
 * @param  {Integer} limit
 * @param  {Date} toDate    toDate 까지 N개를 가져옴
 * @return {Object}
 */
export function fetchDayCandles(securityId, limit, toDate = null) {
  return {
    [CALL_API]: {
      types: [REQUEST_DAY_CANDLES, RECEIVE_DAY_CANDLES, FAILURE_DAY_CANDLES],
      config: {
        method: 'get',
        url: Routes.api_security_day_candles_path(securityId, { limit, to: toDate, format: 'json' })
      },
      schema: {
        dayCandles: dayCandleArray
      }
    },
    securityId,
    limit,
    toDate
  };
}

export function loadDayCandles(securityId, limit, toDate = null, ...params) {
  return (dispatch, getState) => {
    const {
      paginate: {
        dayCandlesBySecurityId
      }
    } = getState();

    const pagination = dayCandlesBySecurityId[securityId];
    if (!toDate && pagination && pagination.pageCount > 0) {
      return null;
    }

    return dispatch(fetchDayCandles(securityId, limit, toDate, ...params));
  };
}

export const REQUEST_INVESTORS = 'REQUEST_INVESTORS';
export const RECEIVE_INVESTORS = 'RECEIVE_INVESTORS';
export const FAILURE_INVESTORS = 'FAILURE_INVESTORS';

/**
 * 일자별 외인/기관 데이터 가져오기
 * @param  {String}  securityId
 * @param  {Integer} limit
 * @param  {Date}    toDate
 * @return {Object}
 */
export function fetchInvestors(securityId, limit, toDate = null) {
  return {
    [CALL_API]: {
      types: [REQUEST_INVESTORS, RECEIVE_INVESTORS, FAILURE_INVESTORS],
      config: {
        method: 'get',
        url: Routes.api_security_investors_path(securityId, { limit, to: toDate, format: 'json' })
      },
      schema: {
        investors: investorArray
      }
    },
    securityId,
    limit,
    toDate
  };
}

export function loadInvestors(securityId, limit = 10, toDate = null, ...params) {
  return (dispatch, getState) => {
    const {
      paginate: {
        investorsBySecurityId
      }
    } = getState();

    const pagination = investorsBySecurityId[securityId];
    if (!toDate && pagination && pagination.pageCount > 0 && pagination.entities.length >= limit) {
      return null;
    }

    return dispatch(fetchInvestors(securityId, limit, toDate, ...params));
  };
}

export const REQUEST_DAY_TRADERS = 'REQUEST_DAY_TRADERS';
export const RECEIVE_DAY_TRADERS = 'RECEIVE_DAY_TRADERS';
export const FAILURE_DAY_TRADERS = 'FAILURE_DAY_TRADERS';

/**
 * 일자별 거래원정보 가져오기
 * @param  {String} securityId
 * @param  {Integer} limit
 * @param  {Date} toDate    toDate 까지 N개를 가져옴
 * @return {Object}
 */
export function fetchDayTraders(securityId, limit, toDate = null) {
  return {
    [CALL_API]: {
      types: [REQUEST_DAY_TRADERS, RECEIVE_DAY_TRADERS, FAILURE_DAY_TRADERS],
      config: {
        method: 'get',
        url: Routes.api_security_day_traders_path(securityId, { limit, to: toDate, format: 'json' })
      },
      schema: {
        dayTraders: dayTraderArray
      }
    },
    securityId,
    limit,
    toDate
  };
}

export function loadDayTraders(securityId, limit, toDate = null, ...params) {
  return (dispatch, getState) => {
    const {
      paginate: {
        dayTradersBySecurityId
      }
    } = getState();

    const pagination = dayTradersBySecurityId[securityId];
    if (!toDate && pagination && pagination.pageCount > 0) {
      return null;
    }

    return dispatch(fetchDayTraders(securityId, limit, toDate, ...params));
  };
}

export const REQUEST_RECENT_TRADER = 'REQUEST_RECENT_TRADER';
export const RECEIVE_RECENT_TRADER = 'RECEIVE_RECENT_TRADER';
export const FAILURE_RECENT_TRADER = 'FAILURE_RECENT_TRADER';

/**
 * 최근 매도/매수상위 거래원정보 가져오기
 * @param  {String} securityId
 * @return {Object}
 */
export function fetchRecentTrader(securityId) {
  return {
    [CALL_API]: {
      types: [REQUEST_RECENT_TRADER, RECEIVE_RECENT_TRADER, FAILURE_RECENT_TRADER],
      config: {
        method: 'get',
        url: Routes.api_security_recent_trader_index_path(securityId, { format: 'json' })
      },
      schema: {
        recentTrader
      }
    },
    securityId
  };
}

export function loadRecentTrader(securityId) {
  return (dispatch, getState) => {
    const {
      entities: {
        recentTraders: {
          [securityId]: recentTrader
        }
      }
    } = getState();

    if (recentTrader) {
      return Promise.resolve();
    }

    return dispatch(fetchRecentTrader(securityId));
  };
}
