import { Routes } from '../routes';
import { CALL_API } from '../middlewares/api';
import { topic, topicArray } from '../schemas/topic';
import fetchActionTypes from '../constants/fetch_actions';

export function fetchTopic(id) {
  return {
    [CALL_API]: {
      types: fetchActionTypes.TOPICS,
      config: {
        method: 'get',
        url: Routes.api_topic_path(id, { format: 'json' })
      },
      schema: {
        topic
      }
    },
    id
  };
}

export function fetchFeaturedTopics(limit) {
  return {
    [CALL_API]: {
      types: fetchActionTypes.FEATURED_TOPICS,
      config: {
        method: 'get',
        url: Routes.featured_api_topics_path({ limit, format: 'json' })
      },
      schema: { topics: topicArray }
    },
    limit
  };
}

// 당일등락률기준상위6개토픽노출
export const REQUEST_FEATURED_TOPICS_TODAY = 'REQUEST_FEATURED_TOPICS_TODAY';
export const RECEIVE_FEATURED_TOPICS_TODAY = 'RECEIVE_FEATURED_TOPICS_TODAY';
export const FAILURE_FEATURED_TOPICS_TODAY = 'FAILURE_FEATURED_TOPICS_TODAY';

export function fetchFeaturedTopicsToday(limit) {
  return {
    [CALL_API]: {
      types: [REQUEST_FEATURED_TOPICS_TODAY, RECEIVE_FEATURED_TOPICS_TODAY, FAILURE_FEATURED_TOPICS_TODAY],
      config: {
        method: 'get',
        url: Routes.featured_api_topics_path({ limit, format: 'json' })
      },
      schema: { topics: topicArray }
    },
    limit
  };
}

export function loadFeaturedTopics(limit) {
  return (dispatch, getState) => {
    const {
      featured: {
        topics
      }
    } = getState();

    if (topics && topics.length > 0) {
      return Promise.resolve();
    }
    return dispatch(fetchFeaturedTopics(limit));
  };
}

export const REQUEST_CREATE_FAVORITE_TOPIC_STOCKS = 'REQUEST_CREATE_FAVORITE_TOPIC_STOCKS';
export const SUCCESS_CREATE_FAVORITE_TOPIC_STOCKS = 'SUCCESS_CREATE_FAVORITE_TOPIC_STOCKS';
export const FAILURE_CREATE_FAVORITE_TOPIC_STOCKS = 'FAILURE_CREATE_FAVORITE_TOPIC_STOCKS';

export function createFavoriteTopicStocks(name, securityIds) {
  return {
    [CALL_API]: {
      types: [REQUEST_CREATE_FAVORITE_TOPIC_STOCKS, SUCCESS_CREATE_FAVORITE_TOPIC_STOCKS, FAILURE_CREATE_FAVORITE_TOPIC_STOCKS],
      config: {
        method: 'post',
        url: Routes.favorite_stocks_api_topics_path({ format: 'json' }),
        data: {
          name,
          security_ids: securityIds.join(',')
        }
      }
    },
    name,
    securityIds
  };
}

export function fetchSecurityTopics(securityId) {
  return {
    [CALL_API]: {
      types: fetchActionTypes.SECURITY_TOPICS,
      config: {
        method: 'get',
        url: Routes.api_security_topics_path(securityId, { format: 'json' })
      },
      schema: { topics: topicArray }
    },
    securityId
  };
}

export function loadSecurityTopics(securityId) {
  return (dispatch, getState) => {
    const {
      entities: {
        topics
      },
      paginate: {
        topicsBySecurityId: {
          [securityId]: pagination
        }
      }
    } = getState();

    if (pagination && pagination.pageCount > 0) {
      const securityTopics = pagination.entities.map(topicId => topics[topicId]);
      return Promise.resolve(securityTopics);
    }
    return dispatch(fetchSecurityTopics(securityId));
  };
}
