import React from 'react';
import { isEmpty } from 'lodash';

function addClassName(target, className) {
  if (isEmpty(className)) {
    return target;
  } else if (isEmpty(target)) {
    return className;
  }
  return `${target} ${className}`;
}

function removeClassName(target, className) {
  if (isEmpty(className)) {
    return target;
  }

  const pattern = `(^|\\s)${className}\\b`;
  const classNamePattern = new RegExp(pattern, 'g');
  return target.replace(classNamePattern, '');
}

function addClassNameToBody(className) {
  if (document) {
    document.body.className = addClassName(document.body.className, className);
  }
}

function removeClassNameFromBody(className) {
  if (document) {
    document.body.className = removeClassName(document.body.className, className);
  }
}

export class FullScreenModal extends React.Component {
  componentDidMount() {
    addClassNameToBody('fullScreenModal');
  }

  componentWillUnmount() {
    removeClassNameFromBody('fullScreenModal');
  }

  render() {
    return null;
  }
}

export default FullScreenModal;
