/* @flow */
import { assign } from 'lodash';
import { OPEN_MODAL, CLOSE_MODAL } from '../../actions/modals';
import { UPDATE_LOCATION } from 'react-router-redux';

import type { ModalAction } from '../../types/modals';

type ModalState = {
  modalType: ?string,
  modalProps: ?Object
};

const initialState: ModalState = {
  modalType: null,
  modalProps: null
};

export function modalsReducer(state: ModalState = initialState, action: ModalAction) : ModalState {
  switch (action.type) {
  case OPEN_MODAL:
    return assign({}, state, {
      modalType: action.modalType,
      modalProps: action.modalProps
    });
  case CLOSE_MODAL:
    return initialState;
  case UPDATE_LOCATION:
    return initialState;
  default:
    return state;
  }
}
