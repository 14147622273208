import { combineReducers } from 'redux';
import * as TrendActions from '../actions/trend';
import fetchActionTypes from '../constants/fetch_actions';
import { assign, keyBy } from 'lodash';

function specialStocksByKey(state = {}, action) {
  switch (action.type) {
  case TrendActions.RECEIVE_SPECIAL_STOCKS:
    return assign({}, state, {
      ...keyBy(action.payload.specialStocks, stock => stock.key)
    });
  default:
    return state;
  }
}

function specialStocksByMarket(state = {}, action) {
  switch (action.type) {
  case TrendActions.RECEIVE_SPECIAL_STOCKS:
    return assign({}, state, {
      [action.market]: specialStocksByKey(state[action.market], action)
    });
  default:
    return state;
  }
}

function marketCondition(state = null, action) {
  switch (action.type) {
  case TrendActions.RECEIVE_MARKET_CONDITION:
    return action.payload.marketCondition;
  default:
    return state;
  }
}

function foreignIndexes(state = null, action) {
  switch (action.type) {
  case fetchActionTypes.FOREIGN_INDEXES.RECEIVE:
    return action.payload.foreignIndexes;
  default:
    return state;
  }
}

export const trendReducer = combineReducers({
  specialStocksByMarket,
  marketCondition,
  foreignIndexes
});
