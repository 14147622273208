import { compact } from 'lodash';
import { createSelector } from 'reselect';
import {
  recentSecurityEntitiesSelector,
  insightArticleSelector
} from './entities_selectors';
import * as SecurityId from '../constants/security_id';
import {
  majorIndexesSelector,
  asiaIndexesSelector,
  americaIndexesSelector,
  europeIndexesSelector
} from './trend_selectors';

export const securityIdSelector = (_, props) => props.params.securityId;

export const recentSecuritySelectorFactory = idSelector =>
  createSelector(
    recentSecurityEntitiesSelector,
    idSelector,
    (entities, id) => entities[id] || {}
  );

export const recentSecuritySelector = recentSecuritySelectorFactory(securityIdSelector);
export const recentSecurityByIdSelector = recentSecuritySelectorFactory(
  (_, props) => props.securityId
);

export const kospiSelector = state => state.entities.recentSecurities[SecurityId.KOSPI_SECURITY_ID];
export const kosdaqSelector = state => state.entities.recentSecurities[SecurityId.KOSDAQ_SECURITY_ID];
export const daxSelector = state => state.entities.recentSecurities[SecurityId.DAX_SECURITY_ID];
export const dowSelector = state => state.entities.recentSecurities[SecurityId.DOW_SECURITY_ID];
export const britishSelector = state => state.entities.recentSecurities[SecurityId.BRITISH_SECURITY_ID];
export const hongkongSelector = state => state.entities.recentSecurities[SecurityId.HONGKONG_SECURITY_ID];
export const nasdaqSelector = state => state.entities.recentSecurities[SecurityId.NASDAQ_SECURITY_ID];
export const shanghaiSelector = state => state.entities.recentSecurities[SecurityId.SHANGHAI_SECURITY_ID];
export const japanSelector = state => state.entities.recentSecurities[SecurityId.JAPAN_SECURITY_ID];

/**
 * RecentSecurities by specific insightArticleId
 * @default []
 * @return {Array<RecentSecurity>}
 */
export const RecentSecuritiesbyInsightArticleId = createSelector(
  insightArticleSelector,
  recentSecurityEntitiesSelector,
  (insightArticleEntity, recentSecuritiesEntity) => (
    (insightArticleEntity)
      ? compact(insightArticleEntity.relatedStocks.map(stock => recentSecuritiesEntity[stock.securityId]))
      : []
  )
);

export const foreignMajorIndexRecentSecuritiesSelector = createSelector(
  majorIndexesSelector,
  recentSecurityEntitiesSelector,
  (majorIndexesSecurityId, recentSecuritiesEntity) =>
    compact(majorIndexesSecurityId.map(securityId => recentSecuritiesEntity[securityId]))
);

export const foreignAsiaIndexRecentSecuritiesSelector = createSelector(
  asiaIndexesSelector,
  recentSecurityEntitiesSelector,
  (asiaIndexesSecurityId, recentSecuritiesEntity) =>
    compact(asiaIndexesSecurityId.map(securityId => recentSecuritiesEntity[securityId]))
);

export const foreignAmericaIndexRecentSecuritiesSelector = createSelector(
  americaIndexesSelector,
  recentSecurityEntitiesSelector,
  (americaIndexesSecurityId, recentSecuritiesEntity) =>
    compact(americaIndexesSecurityId.map(securityId => recentSecuritiesEntity[securityId]))
);

export const foreignEuropeIndexRecentSecuritiesSelector = createSelector(
  europeIndexesSelector,
  recentSecurityEntitiesSelector,
  (europeIndexesSecurityId, recentSecuritiesEntity) =>
    compact(europeIndexesSecurityId.map(securityId => recentSecuritiesEntity[securityId]))
);
