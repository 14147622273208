import { assign, keyBy } from 'lodash';
import * as actions from '../../actions/favorite_stocks';


function deleteFavoriteStocks(state, action) {
  return keyBy(
    action.payload.result.favoriteStocks.map(id => assign({}, state[id], {
      favoriteStockListId: null
    })),
    favoriteStock => favoriteStock.id
  );
}

export function favoriteStocksReducer(state = {}, action) {
  switch (action.type) {
  case actions.SUCCESS_DELETE_FAVORITE_STOCKS:
    return assign({}, state, {
      ...deleteFavoriteStocks(state, action)
    });
  default:
    return state;
  }
}
