import { assign } from 'lodash';
import * as RankersActions from '../../actions/rankers';

const initialState = {
  popularStocks: [],
  rankers: [],
  selectedContestIndex: 0
};

export function rankersReducer(state = initialState, action) {
  switch (action.type) {
  case RankersActions.SELECT_CONTEST:
    return assign({}, state, {
      selectedContestIndex: action.selectedContestIndex
    });
  default:
    return state;
  }
}
