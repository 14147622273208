/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { deleteComment } from '../../actions/comments';
import { promisedCloseModal, openReportModal } from '../../actions/modals';
import { commentSelectorFactory } from '../../selectors/comments_selectors';
import { requireLogin } from '../../actions/login';

import type { Comment } from '../../types/comment';
import { REPORTABLE_TYPE_COMMENT } from '../../constants/report';

class CommentSettingModal extends React.Component {

  props: {
    openReportModal: typeof openReportModal,
    promisedCloseModal: typeof promisedCloseModal,
    deleteComment: typeof deleteComment,
    comment: Comment,
    commentableId: number,
    commentId: number,
    commentableType: string
  };

  handleClickClose(e: Object) {
    e.preventDefault();
    return this.props.promisedCloseModal();
  }

  handleClickDelete(e: Object) {
    this.handleClickClose(e)
      .then(() => {
        if (confirm('삭제 하시겠습니까?')) {
          this.props.deleteComment(this.props.commentableType, this.props.commentableId, this.props.commentId)
            .then((res) => {
              // 삭제를 실패하였을 경우
              if (res.error) {
                alert(res.error.message);
              }
            });
        }
      });
  }

  handleClickReport(e: Object) {
    this.handleClickClose(e)
      .then(() => this.props.openReportModal({ id: this.props.commentId, reportableType: REPORTABLE_TYPE_COMMENT }));
  }

  render() {
    return (
      <div className="reportLayer">
        {this.props.comment &&
        <ul>
          {this.props.comment.isMyComment
            ? <li><a href="#" className="btnDelete" onClick={this.handleClickDelete.bind(this)}>삭제하기</a></li>
            : <li><a href="#" className="btnReport" onClick={this.handleClickReport.bind(this)}>신고하기</a></li>
          }
          <li><a href="#" className="btnClose" onClick={this.handleClickClose.bind(this)}>닫기</a></li>
        </ul>
        }
      </div>
    );
  }
}

const commentIdSelector = (_, props) => props.commentId;

const selector = createStructuredSelector({
  comment: commentSelectorFactory(commentIdSelector)
});

export default connect(selector, {
  deleteComment: requireLogin(deleteComment),
  promisedCloseModal,
  openReportModal: requireLogin(openReportModal)
})(CommentSettingModal);
