import { Routes } from '../routes';
import { CALL_API } from '../middlewares/api';
import { comment } from '../schemas/comment';
import { requireLogin } from '../actions/login';

export const REQUEST_COMMENTS = 'REQUEST_COMMENTS';
export const RECEIVE_COMMENTS = 'RECEIVE_COMMENTS';
export const FAILURE_COMMENTS = 'FAILURE_COMMENTS';

export function fetchComments(commentableType, commentableId, cursor = null, limit = 10) {
  return {
    [CALL_API]: {
      types: [REQUEST_COMMENTS, RECEIVE_COMMENTS, FAILURE_COMMENTS],
      config: {
        method: 'get',
        url: Routes.api_comments_path({ type: commentableType, id: commentableId, cursor, limit: limit, format: 'json' })
      }
      // schema: {
      //   comments: commentArray
      // }
    },
    commentableType,
    commentableId,
    cursor,
    limit
  };
}

export function loadPostComments(id, cursor = null) {
  return (dispatch, getState) => {
    const {
      paginate: {
        commentsByPostId
      }
    } = getState();

    const pagination = commentsByPostId[id];
    if (!cursor && pagination && pagination.pageCount > 0) {
      return null;
    }
    return dispatch(fetchComments('post', id, cursor));
  };
}

export const REQUEST_SECOND_COMMENTS = 'REQUEST_SECOND_COMMENTS';
export const RECEIVE_SECOND_COMMENTS = 'RECEIVE_SECOND_COMMENTS';
export const FAILURE_SECOND_COMMENTS = 'FAILURE_SECOND_COMMENTS';

export function fetchSecondComments(id, cursor = null, limit = 10) {
  return {
    [CALL_API]: {
      types: [REQUEST_SECOND_COMMENTS, RECEIVE_SECOND_COMMENTS, FAILURE_SECOND_COMMENTS],
      config: {
        method: 'get',
        url: `/api/comments/${id}`,
        params: {
          cursor,
          limit
        }
      }
    },
    id,
    cursor,
    limit
  };
}

export function loadSecondComments(id, cursor = null) {
  return (dispatch, getState) => {
    const {
      paginate: {
        commentsByCommentId
      }
    } = getState();

    const pagination = commentsByCommentId[id];
    if (!cursor && pagination && pagination.pageCount > 0) {
      return null;
    }
    return dispatch(fetchSecondComments(id, cursor));
  };
}

export function loadInsightArticleComments(id, cursor = null) {
  return (dispatch, getState) => {
    const {
      paginate: {
        commentsByInsightArticleId
      }
    } = getState();

    const pagination = commentsByInsightArticleId[id];
    if (!cursor && pagination && pagination.pageCount > 0) {
      return null;
    }
    return dispatch(fetchComments('insight_article', id, cursor));
  };
}

export const REQUEST_CREATE_COMMENT = 'REQUEST_CREATE_COMMENT';
export const SUCCESS_CREATE_COMMENT = 'SUCCESS_CREATE_COMMENT';
export const FAILURE_CREATE_COMMENT = 'FAILURE_CREATE_COMMENT';

export function createComment(commentableType, commentableId, content) {
  return {
    [CALL_API]: {
      types: [REQUEST_CREATE_COMMENT, SUCCESS_CREATE_COMMENT, FAILURE_CREATE_COMMENT],
      config: {
        method: 'post',
        url: Routes.api_comments_path({ type: commentableType, id: commentableId, format: 'json' }),
        data: {
          content
        }
      },
      schema: {
        comment
      }
    },
    commentableType,
    commentableId,
    content
  };
}

export const createPostComment = requireLogin(createComment.bind(null, 'post'));

export const REQUEST_DELETE_COMMENT = 'REQUEST_DELETE_COMMENT';
export const SUCCESS_DELETE_COMMENT = 'SUCCESS_DELETE_COMMENT';
export const FAILURE_DELETE_COMMENT = 'FAILURE_DELETE_COMMENT';

export function deleteComment(commentableType, commentableId, commentId) {
  return {
    [CALL_API]: {
      types: [REQUEST_DELETE_COMMENT, SUCCESS_DELETE_COMMENT, FAILURE_DELETE_COMMENT],
      config: {
        method: 'delete',
        url: Routes.api_comment_path(commentId, { format: 'json' })
      }
    },
    commentableType,
    commentableId,
    commentId
  };
}
