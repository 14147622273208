export const EVENT_CATEGORY1 = '모웹 to App - 종목상세 > 시세 > 호가';
export const EVENT_CATEGORY2 = '모웹 to App - 메인';
export const EVENT_CATEGORY3 = '모웹 to App - 토픽 리스트';
export const EVENT_CATEGORY4 = '모웹 to App - 랭킹';
export const EVENT_CATEGORY5 = '모웹 to App - 랭커홈';
export const EVENT_CATEGORY6 = '모웹 to App - 종목상세 > 주문하기';
export const EVENT_CATEGORY7 = '카카오드라이버';

export const EVENT_ACTION1 = '종목상세 > 종합 > 실시간 시세 보기';
export const EVENT_ACTION2 = '종목상세 > 시세 > 일일시세 > 실시간 호가';
export const EVENT_ACTION3 = '종목상세 > 시세 > 투자자 > 실시간 호가';
export const EVENT_ACTION4 = '종목상세 > 시세 > 거래원 > 실시간 호가';
export const EVENT_ACTION5 = '푸터 > APP보기';
export const EVENT_ACTION6 = '투자정보 > 토픽 상세> 토픽 배너';
export const EVENT_ACTION7 = '랭킹 > 리그 참여 배너';
export const EVENT_ACTION8 = '랭커 매매내역 더보기';
export const EVENT_ACTION9 = '홈 > 다른 토픽 더 보기';
export const EVENT_ACTION10 = '투자정보 > 다른 토픽 더 보기';
export const EVENT_ACTION11 = '종목상세 > 주문하기';
export const EVENT_ACTION12 = 'Join Event';

export const UNKNOWN_EVENT_ACTION = 'Unknown Event';

// 실시간 호가 EVENT 리스트
export const REALTIME_QUOTATION_EVENT_ACTION1 = '종목상세 > 종합 > 실시간 호가';
export const REALTIME_QUOTATION_EVENT_ACTION2 = '종목상세 > 토론 > 실시간 호가';
export const REALTIME_QUOTATION_EVENT_ACTION3 = '종목상세 > 뉴스/공시 > 전체 > 실시간 호가';
export const REALTIME_QUOTATION_EVENT_ACTION4 = '종목상세 > 뉴스/공시 > 뉴스 > 실시간 호가';
export const REALTIME_QUOTATION_EVENT_ACTION5 = '종목상세 > 뉴스/공시 > 공시 > 실시간 호가';
export const REALTIME_QUOTATION_EVENT_ACTION6 = '종목상세 > 시세 > 일일시세 > 실시간 호가';
export const REALTIME_QUOTATION_EVENT_ACTION7 = '종목상세 > 시세 > 투자자 > 실시간 호가';
export const REALTIME_QUOTATION_EVENT_ACTION8 = '종목상세 > 시세 > 거래원 > 실시간 호가';
export const REALTIME_QUOTATION_EVENT_ACTION9 = '종목상세 > 분석 > 실시간 호가';
