/* @flow */
import React from 'react';
import { ABtn } from './btn';

export class AlertModal extends React.Component {
  static defaultProps = {
    title: '안내'
  };

  props: {
    title: string,
    message: string,
    onClickConfirm: Function
  };

  handleClickConfirm = () => {
    this.props.onClickConfirm();
  };

  render() {
    const {
      title,
      message
    } = this.props;
    return (
      <div className="popup">
        <h4>{title}</h4>
        <div className="contB">
          <p>{message}</p>
        </div>
        <ABtn href="#" className="btn" title="확인" onClick={this.handleClickConfirm}>확인</ABtn>
      </div>
    );
  }
}


export default AlertModal;