/* @flow */
import { Routes } from '../routes';
import { user as userSchema } from '../schemas/user';
import { CALL_API } from '../middlewares/api';
import fetchActionTypes from '../constants/fetch_actions';

export function fetchMe(): Object {
  return {
    [CALL_API]: {
      types: fetchActionTypes.ME,
      config: {
        method: 'get',
        url: Routes.api_me_index_path({ format: 'json' })
      },
      schema: {
        user: userSchema
      }
    }
  };
}

export function loadMe(): any {
  return (dispatch, getState) => {
    const {
      user
    } = getState();

    if (user && user.avatarUrl) {
      return Promise.resolve(user);
    }
    return dispatch(fetchMe());
  };
}
