/* @flow */
import { assign } from 'lodash';
import { ENABLE_DNM_STYLESHEET, DISABLE_DNM_STYLESHEET } from '../../actions/stylesheet';

type Action = {
  type: string,
  isEnabled: boolean
}

type State = {
  isEnabled: boolean
};

const initialState: State = {
  isEnabled: false
};

export function stylesheetReducer(state: State = initialState, action: Action) : State {
  switch (action.type) {
  case ENABLE_DNM_STYLESHEET:
    document.getElementById('dnm_style').disabled = false;
    return assign({}, state, { isEnabled: true });
  case DISABLE_DNM_STYLESHEET:
    document.getElementById('dnm_style').disabled = true;
    return assign({}, state, { isEnabled: false });
  default:
    return state;
  }
}
