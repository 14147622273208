import { xor, assign } from 'lodash';
import { TOGGLE_NOTICE } from '../actions/notices';

const initialState = {
  openedNotices: []
};

export function noticesReducer(state = initialState, action) {
  switch (action.type) {
  case TOGGLE_NOTICE:
    return assign({}, state, {
      openedNotices: xor(state.openedNotices, [action.id])
    });
  default:
    return state;
  }
}
