/* @flow */
import React, { PropTypes } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import classNames from 'classnames';
import { isEqual } from 'lodash';

export function upDownClassName(change: string, includeLimitType: boolean = false, isUsingDraw: boolean = true): string {
  const upDownClassNameMapping = {
    UPPER_LIMIT: includeLimitType ? 'up1' : 'up',
    RISE: 'up',
    EVEN: isUsingDraw ? 'draw' : '',
    FALL: 'down',
    LOWER_LIMIT: includeLimitType ? 'down1' : 'down'
  };

  return upDownClassNameMapping[change] || 'EVEN';
}

export function upDownClassNameOfForex(change: string, isBefore: boolean = true): string {
  const postFix = isBefore ? 'before' : 'after';
  const upDownClassNameMapping = {
    UPPER_LIMIT: `fa-up-${postFix}`,
    RISE: `fa-up-${postFix}`,
    EVEN: 'draw',
    FALL: `fa-down-${postFix}`,
    LOWER_LIMIT: `fa-down-${postFix}`
  };

  return upDownClassNameMapping[change];
}

export function upDownClassNameByValue(value: number, defaultValue: string = 0) {
  return classNames({ up: value > defaultValue, draw: value === defaultValue, down: value < defaultValue });
}

export class FormattedTime extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    const formatted = moment(this.props.date).format(this.props.format);
    return (
      <span>{formatted}</span>
    );
  }
}

FormattedTime.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  format: PropTypes.string
};

FormattedTime.defaultProps = {
  date: new Date(),
  format: 'HH:MM'
};

export class FormattedDate extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    const formatted = moment(this.props.date).format(this.props.format);
    return (
      <span>{formatted}</span>
    );
  }
}

FormattedDate.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  format: PropTypes.string
};

FormattedDate.defaultProps = {
  date: new Date(),
  format: 'MM.DD'
};

export class FormattedNumber extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  get format() {
    return this.props.value === 0 ? this.props.zeroFormat || this.props.format : this.props.format;
  }
  render() {
    const formatted = numeral(this.props.value).format(this.format);

    return (
      <span className={this.props.className}>{formatted}</span>
    );
  }
}

FormattedNumber.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  format: PropTypes.string,
  zeroFormat: PropTypes.string,
  className: PropTypes.string
};

FormattedNumber.defaultProps = {
  value: 0,
  format: '0,0[.]00'
};

export class FormattedChangePrice extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    return (
      <em>
        <FormattedNumber format={this.props.format} value={Math.abs(this.props.value)} />
        {this.props.children}
      </em>
    );
  }
}

FormattedChangePrice.propTypes = {
  change: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  format: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  isUsingDraw: PropTypes.bool
};

FormattedChangePrice.defaultProps = {
  value: 0
};


export class FormattedChangePriceRate extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps, this.props);
  }

  render() {
    return (
      <i className={upDownClassName(this.props.change, true)}>
        <FormattedNumber format="0.00%" value={Math.abs(this.props.value)} />
      </i>
    );
  }
}

FormattedChangePriceRate.propTypes = {
  change: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired
};

FormattedChangePriceRate.defaultProps = {
  value: 0
};

export class FormattedText extends React.Component {
  render() {
    const splitted = this.props.text.split('\n');
    const formatted = splitted.map((item, i) => {
      return <span key={i}>{item}{splitted.length - 1 !== i && <br/>}</span>;
    });

    return (
      <span>{formatted}</span>
    );
  }
}

FormattedText.propTypes = {
  text: PropTypes.string.isRequired
};

FormattedText.defaultProps = {
  text: ''
};
