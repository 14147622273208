import { EDITED_USER_NAME, SUCCESS_UPDATE_SETTINGS, SUCCESS_UPDATE_USER_NAME, SUCCESS_UPDATE_USER, SUCCESS_INDEX_USER, SUCCESS_INDEX_SETTINGS } from '../actions/user.jsx';
import fetchActionTypes from '../constants/fetch_actions';
import { assign } from 'lodash';

export const initialState = {
  avatarUrl: null,
  id: null,
  name: null,
  birthYear: null,
  gender: null,
  email: null,
  isEditedUserName: false
};

export function userReducer(state = initialState, action) {
  switch (action.type) {
  case EDITED_USER_NAME:
    return assign({}, state, {
      isEditedUserName: action.isEditedUserName
    });
  case SUCCESS_UPDATE_USER_NAME:
    state.isEditedUserName = false;
    return assign({}, state, action.payload.user);
  case SUCCESS_UPDATE_USER:
    return assign({}, state, action.payload.user);
  case SUCCESS_INDEX_USER:
    return assign({}, state, action.payload.user);
  case SUCCESS_INDEX_SETTINGS:
    return assign({}, state, action.payload.settings);
  case SUCCESS_UPDATE_SETTINGS:
    return assign({}, state, action.payload.settings);
  case fetchActionTypes.ME.RECEIVE:
    const userId = action.payload.result.user;
    const user = action.payload.entities.users;
    return assign({}, state, user[userId]);
  default:
    return state;
  }
}
