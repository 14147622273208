import React from 'react';
import { Route, IndexRoute, IndexRedirect } from 'react-router';
import { loadComponent } from '../helper';

export default (
  <Route path="m">
    <Route path="debates">
      <IndexRedirect to="/" />
      <Route
        path=":id" getComponent={(location, cb) =>
          import('../../containers/debates/debates_show_container').then(loadComponent(cb))
        }
      />
    </Route>

    <Route
      path="stocks/:securityId" getComponent={(location, cb) =>
        import('../../containers/stocks/stocks_main').then(loadComponent(cb))
      }
    >
      <IndexRoute
        getComponent={(location, cb) =>
          import('../../containers/stocks/stocks_show_container').then(loadComponent(cb))
        }
      />

      <Route path="debates">
        <IndexRoute
          getComponent={(location, cb) =>
            import('../../containers/debates/debates_index_container').then(loadComponent(cb))
          }
        />
        <Route
          path=":id" getComponent={(location, cb) =>
            import('../../containers/debates/debates_show_container').then(loadComponent(cb))
          }
        />
      </Route>

      <Route path="articles">
        <IndexRoute
          getComponent={(location, cb) =>
            import('../../containers/articles/articles_index_container').then(loadComponent(cb))
          }
        />
        <Route
          path="news" getComponent={(location, cb) =>
            import('../../containers/articles/articles_news_container').then(loadComponent(cb))
          }
        />
        <Route
          path="disclosures" getComponent={(location, cb) =>
            import('../../containers/articles/articles_disclosures_container').then(loadComponent(cb))
          }
        />
      </Route>

      <Route path="quotations">
        <IndexRedirect to="daily" />
        <Route
          path="daily" getComponent={(location, cb) =>
            import('../../containers/stock_quotations/daily_container').then(loadComponent(cb))
          }
        />
        <Route
          path="foreign" getComponent={(location, cb) =>
            import('../../containers/stock_quotations/foreign_container').then(loadComponent(cb))
          }
        />
        <Route
          path="trader" getComponent={(location, cb) =>
            import('../../containers/stock_quotations/trader_container').then(loadComponent(cb))
          }
        />
      </Route>

      <Route path="analysis">
        <IndexRoute
          getComponent={(location, cb) =>
            import('../../containers/stocks/analysis').then(loadComponent(cb))
          }
        />
      </Route>
    </Route>
  </Route>
);
