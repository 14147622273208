/* @flow */
import React from 'react';
import appDownloadImage from '../../../../assets/images/images/discuss/popup_download.svg';
import { ABtn } from './btn';
import { BASE_LINK } from '../../constants/onelink';

type Props = {
  onClickClose: Function,
  requestOneLinkWarp: Function,
  code: string,
}

export class AppDownloadModal extends React.Component {
  props: Props;

  handleClickClose = () => {
    this.props.onClickClose();
  };

  handleRequestOneLink = () => {
    const { requestOneLinkWarp, code } = this.props || {};

    if (requestOneLinkWarp && code) {
      requestOneLinkWarp(BASE_LINK, {
        pid: '다음금융',
        c: 'kakao_finance',
        af_adset: 'kakao_finance_mobile',
        af_ad: 'kakao_finance_mobile_order',
        af_dp: `stockplus://viewAsset?code=${code}&tabName=board`,
        af_web_dp: 'https://stockplus-static.dunamu.com/daum/daum_setup/html/inSite.html',
      });
    }
  };

  render() {
    return (
      <div className="popup">
        <div>
          <ABtn onClick={this.handleRequestOneLink} title="증권플러스 앱 다운로드">
            <img src={appDownloadImage} width="100%" alt="증권플러스 앱 다운로드"/>
          </ABtn>
        </div>
        <ABtn href="#" className="btn" title="닫기" onClick={this.handleClickClose}>닫기</ABtn>
      </div>
    );
  }
}


export default AppDownloadModal;