/* @flow */
import { CLICK_RADIO_BUTTON, RESET_RADIO_BUTTON, INITIAL_RADIO_BUTTON_INDEX } from '../constants/radio_buttons';

import type { RadioButtonAction } from '../types/radio_buttons';

export function clickRadioButton(name: string, selectedButtonIndex: number): RadioButtonAction {
  return {
    type: CLICK_RADIO_BUTTON,
    name,
    selectedButtonIndex
  };
}

export function resetRadioButton(name: string): RadioButtonAction {
  return {
    type: RESET_RADIO_BUTTON,
    name,
    selectedButtonIndex: INITIAL_RADIO_BUTTON_INDEX
  };
}
