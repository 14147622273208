import { assign } from 'lodash';
import fetchActionTypes from '../constants/fetch_actions';

export function featuredReducer(state = {}, action) {
  switch (action.type) {
  case fetchActionTypes.FEATURED_TOPICS.RECEIVE:
    return assign({}, state, {
      topics: action.payload.result.topics
    });
  default:
    return state;
  }
}
