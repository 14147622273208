import { createSelector } from 'reselect';
import { debatesUiSelector } from './ui_selectors';

/**
 * debates state의 category 값을 가져온다.
 * @returns {string} - category 값
 */
export const debatesCategorySelector = createSelector(
  debatesUiSelector,
  debates => debates.category
);

export const debatesSortSelector = createSelector(
  debatesUiSelector,
  debates => debates.sort
);

export const debatesProfitFilterTypeSelector = createSelector(
  debatesUiSelector,
  debates => debates.profitFilterType
);
