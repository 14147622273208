/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ModalRoot from './containers/modals/modal_root';
import FullScreenModal from './containers/modals/full_screen_modal';
import FloatingRootContainer from './containers/floatings/floating_root_container';

import { modalsSelector } from './selectors/ui_selectors';
import { networkTurnOnline, networkTurnOffline } from './actions/network';

type Props = {
  modals: { modalProps: ?Object },
  network: { isOnline: boolean },
  networkTurnOnline: typeof networkTurnOnline,
  networkTurnOffline: typeof networkTurnOffline,
  children: any
};

export class Page extends React.Component {
  props: Props;

  handleNetworkTurnOnline = () => {
    this.props.networkTurnOnline();
  };

  handleNetworkTurnOffline = () => {
    this.props.networkTurnOffline();
  };

  setAutoScrollRestoration = () => {
    if (window.history && window.history.scrollRestoration) {
      window.history.scrollRestoration = 'auto';
    }
  };

  componentDidMount() {
    window.addEventListener('offline', this.handleNetworkTurnOffline);
    window.addEventListener('online', this.handleNetworkTurnOnline);

    // todo(cheetos): 커뮤니티 개편(https://jira.dunamu.com/browse/STPL-2) 배포 이후 라이브러리 교체/삭제 등 조치 예정
    // jira ticket: https://jira.dunamu.com/browse/STPL-361
    this.setAutoScrollRestoration();
  }

  componentWillUnmount() {
    window.removeEventListener('offline', this.handleNetworkTurnOffline);
    window.removeEventListener('online', this.handleNetworkTurnOnline);
  }

  render() {
    return (
      <div>
        {this.props.modals.modalProps && this.props.modals.modalProps.fullScreen &&
        <FullScreenModal/>
        }
        {this.props.children}
        <ModalRoot/>
        <FloatingRootContainer/>
      </div>
    );
  }
}

const selector = createStructuredSelector({
  modals: modalsSelector
});

export default connect(selector, {
  networkTurnOnline,
  networkTurnOffline
})(Page);
