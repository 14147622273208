
export const USE_SERVICE_ALL_AGREEMENT = 'USE_SERVICE_ALL_AGREEMENT';
export function useServiceAllAgreement() {
  return {
    type: USE_SERVICE_ALL_AGREEMENT
  };
}

export const USE_SERVICE_AGE_AGREEMENT = 'USE_SERVICE_AGE_AGREEMENT';
export function useServiceAgeAgreement() {
  return {
    type: USE_SERVICE_AGE_AGREEMENT
  };
}

export const USE_SERVICE_AGREEMENT = 'USE_SERVICE_AGREEMENT';
export function useServiceAgreement() {
  return {
    type: USE_SERVICE_AGREEMENT
  };
}

export const USE_PRIVATE_COLLECT_AGREEMENT = 'USE_PRIVATE_COLLECT_AGREEMENT';
export function usePrivateCollectAgreement() {
  return {
    type: USE_PRIVATE_COLLECT_AGREEMENT
  };
}
