import { createSelector } from 'reselect';

/**
 * state에서 ui를 가져온다
 * @param state
 */
export const uiSelector = state => state.ui || {};

export const debatesUiSelector = createSelector([uiSelector], ui => ui.debates);
export const myUiSelector = createSelector([uiSelector], ui => ui.my);
export const majorForexIdsUiSelector = createSelector([uiSelector], ui => ui.majorForexIds);
export const favoriteStocksUiSelector = createSelector([uiSelector], ui => ui.favoriteStocks);
export const recentViewStocksUiSelector = createSelector([uiSelector], ui => ui.recentViewStocks);
export const favoriteStockListsUiSelector = createSelector([uiSelector], ui => ui.favoriteStockLists);

export const selectedFavoriteStockIdsSelector = createSelector([favoriteStocksUiSelector], ui => ui.selectedStocks);
export const radioButtonsSelector = createSelector([uiSelector], ui => ui.radioButtons);
export const radioButtonByNameSelector = radioButtonNameSelector => createSelector(
  radioButtonsSelector,
  radioButtonNameSelector,
  (radioButtons, name) => radioButtons[name]
);

/**
 * state -> ui에서 modals를 가져오기
 * @param  {Object} state
 * @return {Object}
 */
export const modalsSelector = createSelector(
  [uiSelector],
  ui => ui.modals || {}
);

export const tabsUiSelector = createSelector(
  [uiSelector],
  ui => ui.tabs || {}
);

export const myShowChartImageSelector = createSelector(
  [myUiSelector],
  ui => ui.showChartImage
);

export const myShowImportBannerSelector = createSelector(
  [myUiSelector],
  ui => ui.showImportBanner
);

export const myNavigationTitleSelector = createSelector(
  [myUiSelector],
  ui => ui.title
);

export const featuredAssetIdsSelector = createSelector(
  [majorForexIdsUiSelector],
  ui => ui.featuredAssetIds
);

export const krwAssetIdsSelector = createSelector(
  [majorForexIdsUiSelector],
  ui => ui.krwAssetIds
);

export const usdAssetIdsSelector = createSelector(
  [majorForexIdsUiSelector],
  ui => ui.usdAssetIds
);

export const historySnapshotSelector = createSelector(
  uiSelector,
  ui => ui.historySnapshot.assetIds
);

export const isStylesheetEnabledSelector = createSelector(
  uiSelector,
  ui => ui.stylesheet.isEnabled
);
