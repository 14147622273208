import { Routes } from '../routes';
import { rankerArray } from '../schemas/ranker';
import { CALL_API } from '../middlewares/api';
import fetchActionTypes from '../constants/fetch_actions';

export const SELECT_CONTEST = 'SELECT_CONTESTS';
export function selectContest(selectedContestIndex) {
  return {
    type: SELECT_CONTEST,
    selectedContestIndex
  };
}

export function fetchContestRankers(contestId) {
  return {
    [CALL_API]: {
      types: fetchActionTypes.CONTEST_RANKERS,
      config: {
        method: 'get',
        url: Routes.api_contest_rankers_path(contestId, { format: 'json' })
      },
      schema: {
        rankers: rankerArray
      }
    },
    contestId
  };
}

export function loadContestRankers(contestId) {
  return (dispatch, getState) => {
    const {
      paginate: {
        rankersByContestId
      }
    } = getState();

    const pagination = rankersByContestId[contestId];
    if (pagination && pagination.pageCount > 0) {
      return Promise.resolve();
    }

    return dispatch(fetchContestRankers(contestId));
  };
}

