// 국내 지수
export const KOSPI_SECURITY_ID = 'KOREA-KGG01P';
export const KOSDAQ_SECURITY_ID = 'KOREA-QGG01P';
export const KOSPI200_SECURITY_ID = 'KOREA-K2G01P';

// 상해 지수
export const SHANGHAI_SECURITY_ID = 'SHANGHAI-000001';

// 홍콩 지수
export const HONGKONG_SECURITY_ID = 'HONGKONG-HSI';

// 일본 지수
export const JAPAN_SECURITY_ID = 'JAPAN-NI225';

// 미국 지수
export const DOW_SECURITY_ID = 'USA-DJI';
export const NASDAQ_SECURITY_ID = 'USA-COMP';

// 유럽 지수
export const DAX_SECURITY_ID = 'GERMAN-DAX30';
export const BRITISH_SECURITY_ID = 'BRITISH-FTSE100';

// TOPIX
export const TOPIX_SECURITY_ID = 'JAPAN-T0000';
