/* @flow */
import { assign } from 'lodash';
import { RECEIVE_COMPANY } from '../../actions/companies';

export function companiesReducer(state: Object = {}, action: Object): Object {
  switch (action.type) {
  case RECEIVE_COMPANY:
    if (action.securityId !== action.payload.result.company) {
      return assign({}, state, {
        [action.securityId]: state[action.payload.result.company]
      });
    }
    return state;
  default:
    return state;
  }
}
