/* @flow */
import { requireLogin } from './login';

import type { ModalType, ModalAction, CommentSettingModalProps, ReportModalProps } from '../types/modals';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export function openModal<T>(modalType: ModalType, modalProps: T): ModalAction<T> {
  return {
    type: OPEN_MODAL,
    modalType,
    modalProps
  };
}

export function closeModal(): ModalAction {
  return {
    type: CLOSE_MODAL
  };
}

export function promisedCloseModal(): any {
  return dispatch => Promise.resolve(dispatch(closeModal()));
}

export function openOrderModal<T>(modalProps: T): ModalAction<T> {
  return openModal('order', modalProps);
}

/**
 * 페이지 공유
 */
export function openShareModal<T>(modalProps: T): ModalAction<T> {
  return openModal('share', modalProps);
}

/**
  토픽 종목을 관심 종목에 추가하기
 */
export function openAddTopicStocksModal<T>(modalProps: T): ModalAction<T> {
  return openModal('addTopicStocks', modalProps);
}

/**
 * 관심 종목 편집하기
 */
export function openEditFavoriteStocksModal<T>(modalProps: T): ModalAction<T> {
  return requireLogin(openModal)('editFavoriteStocks', modalProps);
}

/**
 * 최근 조회 종목 관심종목으로 만들기
 */
export function openAddRecentStocksModal<T>(modalProps: T): ModalAction<T> {
  return openModal('addRecentStocks', modalProps);
}

/**
 * 댓글 신고, 삭제 팝업
 * @param modalProps
 * @returns {{type, modalType, modalProps}|ModalAction.<CommentSettingModalProps>}
 */
export function openCommentSettingModal(modalProps: CommentSettingModalProps): ModalAction<CommentSettingModalProps> {
  return openModal('commentSetting', modalProps);
}

/**
 * 신고하기 꽉찬 팝업
 * @param modalProps
 * @returns {{type, modalType, modalProps}|ModalAction.<CommentSettingModalProps>}
 */
export function openReportModal(modalProps: ReportModalProps): ModalAction<ReportModalProps> {
  return openModal('report', modalProps);
}


/**
 * 약관동의
 */
export function openAgreeTermsModal<T>(modalProps: T): ModalAction<T> {
  return openModal('agreeTerms', { ...modalProps, fullScreen: true });
}

/**
 * 휴면계정
 */
export function openReleaseDormantModal<T>(modalProps: T): ModalAction<T> {
  return openModal('releaseDormant', { ...modalProps, fullScreen: true });
}

/**
 * 카카오 드라이버 이벤트
 */
export function openDriverEventModal<T>(modalProps: T): ModalAction<T> {
  return openModal('driverEvent', modalProps);
}

/**
 * 프로필 설정
 */
export function openSetProfileModal<T>(modalProps: T): ModalAction<T> {
  return requireLogin(openModal)('setProfile', { ...modalProps, fullScreen: true });
}

/**
 * 앱 다운로드
 */
export function openAppDownloadModal<T>(modalProps: T): ModalAction<T> {
  return openModal('appDownload', modalProps);
}

/**
 * 알럿
 */
export function openAlertModal<T>(modalProps: T): ModalAction<T> {
  return openModal('alert', modalProps);
}