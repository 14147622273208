import { Schema, arrayOf } from 'normalizr';
import { favoriteStockArray } from './favorite_stock';

export const favoriteStockList = new Schema('favoriteStockLists');

favoriteStockList.define({
  favoriteStocks: favoriteStockArray
});

export const favoriteStockListArray = arrayOf(favoriteStockList);
