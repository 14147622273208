import { zipObject, mapValues, remove } from 'lodash';
import { compact } from 'lodash';
import { createSelector } from 'reselect';
import { recentSecurityEntitiesSelector } from './entities_selectors';
import { securityIdSelector } from './recent_security_selectors';

export const topicEntitiesSelector = state => state.entities.topics || {};
const topicsBySecurityIdPaginateSelector = state => state.paginate.topicsBySecurityId;
const topicsByTodayPaginateSelector = state => state.paginate.topicsByToday;

const topicIdsBySecurityIdSelector = createSelector(
  topicsBySecurityIdPaginateSelector,
  securityIdSelector,
  (paginate, id) => (paginate[id] || {}).entities || []
);

export const topicSelector = createSelector(
  topicEntitiesSelector,
  (_, props) => props.params.id,
  (entities, id) => entities[id] || {}
);

export const topicsBySecurityIdSelector = createSelector(
  topicIdsBySecurityIdSelector,
  topicEntitiesSelector,
  (ids, entities) => ids.map(id => entities[id])
);

export const securityIdsByTopicIdsSelector = createSelector(
  topicIdsBySecurityIdSelector,
  topicEntitiesSelector,
  securityIdSelector,
  (topicIds, entities, id) => {
    const securityIdsArr = topicIds.map((topicId) => {
      const securityIds = entities[topicId].stocks.map(stock => stock.securityId);
      return remove(securityIds, securityId => securityId !== id);
    });
    return zipObject(topicIds, securityIdsArr);
  }
);

export const topicStocksByTopic = createSelector(
  recentSecurityEntitiesSelector,
  securityIdsByTopicIdsSelector,
  (entities, securityIdsByTopicId) => mapValues(securityIdsByTopicId, ids => compact(ids.map(id => entities[id])))
);

export const topicsByToday = createSelector(
  topicEntitiesSelector,
  topicsByTodayPaginateSelector,
  (topicEntities, topicsByTodayPaginate) => topicsByTodayPaginate.entities.map(id => topicEntities[id])
);
