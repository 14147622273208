import React, { PropTypes } from 'react';
import { connect } from 'react-redux';
import TopButtonFloatingContainer from '../../components/floatings/top_button_floating_container.jsx';
import RealTimeQuotationButtonFloatingContainer from './real_time_quotation_button_floating_container.jsx';
import {
  TOP_BUTTON_FLOATING,
  REALTIME_QUOTATION_BUTTON_FLOATING,
  WRITE_BUTTON_FLOATING
} from '../../constants/floating_type.jsx';
import WriteButtonFloatingContainer from './write_button_floating_container';

export class FloatingRootContainer extends React.Component {
  renderFloating() {
    switch (this.props.floatingType) {
    case TOP_BUTTON_FLOATING:
      return <TopButtonFloatingContainer/>;

    case REALTIME_QUOTATION_BUTTON_FLOATING:
      return <RealTimeQuotationButtonFloatingContainer/>;

    case WRITE_BUTTON_FLOATING:
      return <WriteButtonFloatingContainer/>;

    default:
      return <TopButtonFloatingContainer/>;
    }
  }

  render() {
    return (
      <div>
        {this.renderFloating()}
      </div>
    );
  }
}

FloatingRootContainer.propTypes = {
  floatingType: PropTypes.string.isRequired
};

export default connect(
  state => state.ui.floatings
)(FloatingRootContainer);
