/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { routeActions } from 'react-router-redux';
import { Link } from 'react-router';
import classNames from 'classnames';
import { FormattedText } from '../../components/common/formatter';
import { closeModal, openSetProfileModal } from '../../actions/modals';
import {
  useServiceAllAgreement,
  useServiceAgeAgreement,
  useServiceAgreement,
  usePrivateCollectAgreement
} from '../../actions/agreement';
import { createUser } from '../../actions/user';
import { fetchLogin, requireLogin } from '../../actions/login';
import { terms, privacyPolicyEssential } from '../../constants/privacy_and_terms';

class AgreeTermsModal extends React.Component {
  props: {
    kakaoAccessToken: string,
    isUseServiceAgreement: boolean,
    isUsePrivateCollectAgreement: boolean,
    isUseServiceAgeAgreement: boolean,
    isUseServiceAllAgreement: boolean,
    onUseServiceAgreement: typeof useServiceAgreement,
    onUsePrivateCollectAgreement: typeof usePrivateCollectAgreement,
    onUseServiceAgeAgreement: typeof useServiceAgeAgreement,
    onUseServiceAllAgreement: typeof useServiceAllAgreement,
    onCreateUser?: (user: Object) => any,
    onError?: (error: Object) => any,
    createUser: typeof createUser,
    fetchLogin: typeof fetchLogin,
    closeModal: typeof closeModal,
    loginAndGo: typeof routeActions.push,
    openSetProfileModal: typeof openSetProfileModal
  };

  static defaultProps = {
    isUseServiceAgreement: false, // 서비스 약관동의 여부
    isUsePrivateCollectAgreement: false, // 개인 정보 수집동의 여부
    isUseServiceAgeAgreement: false,
    isUseServiceAllAgreement: false
  };

  static style = {
    content: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: 0
    }
  };

  handleClickStartKakaoStock(e: Object) {
    e.preventDefault();
    if (!this.props.isUseServiceAgeAgreement ||
      !this.props.isUseServiceAgreement ||
      !this.props.isUsePrivateCollectAgreement) {
      alert('필수사항 전체 동의가 필요합니다.'); // eslint-disable-line no-alert
      return;
    }

    this.props.createUser(this.props.kakaoAccessToken)
      .then(() => this.props.fetchLogin({ access_token: this.props.kakaoAccessToken }))
      .then((user) => {
        // this.props.closeModal();
        this.props.openSetProfileModal();
        if (this.props.onCreateUser) {
          this.props.onCreateUser(user);
        }
      })
      .catch((error) => {
        if (this.props.onError) {
          this.props.onError(error);
        }
      });
  }

  handleUseAgeAgreement(e: Object) {
    e.preventDefault();
    this.props.onUseServiceAgeAgreement();
  }

  handleUseAllAgreement(e: Object) {
    e.preventDefault();
    this.props.onUseServiceAllAgreement();
  }

  handleUseServiceAgreement(e: Object) {
    e.preventDefault();
    this.props.onUseServiceAgreement();
  }

  handleUsePrivateCollectAgreement(e: Object) {
    e.preventDefault();
    this.props.onUsePrivateCollectAgreement();
  }

  handleClickBack(e) {
    this.props.closeModal();
    location.reload();
    e.preventDefault();
  }

  render() {
    return (
      <div>
        <header>
          <div className="topDef line">
            <h2><a href="#">회원가입</a></h2>
            <span className="btnB">
              <a href="#" className="btnPrev" title="이전">이전</a>
            </span>
          </div>
        </header>
        <main>
          <article className="mystockW">
            <div className="contW02">
              <div className="signupB">
                <span className="agreeB top">
                  <em>증권플러스는 카카오와 제휴계약을 통해 두나무에서 제공하는 서비스입니다.</em>
                  <label htmlFor=""><strong>아래 필수 사항에 전체 동의합니다.</strong></label>
                  <Link to="#" onClick={this.handleUseAllAgreement.bind(this)} className={classNames({ radio: true, selected: this.props.isUseServiceAllAgreement })}>check</Link>
                </span>
                <span className="agreeB">
                  <label htmlFor="">연령(만 14세 이상) 확인 (필수)</label>
                  <Link to="#" onClick={this.handleUseAgeAgreement.bind(this)} className={classNames({ radio: true, selected: this.props.isUseServiceAgeAgreement })}>check</Link>
                </span>
                <span className="agreeB">
                  <label htmlFor="">서비스 이용 약관 동의 (필수)</label>
                  <Link to="#" onClick={this.handleUseServiceAgreement.bind(this)} className={classNames({ radio: true, selected: this.props.isUseServiceAgreement })}>check</Link>
                  <p style={{ letterSpacing: '-1px' }}>
                    <FormattedText text={terms} style={{ letterSpacing: '-1px' }} />
                  </p>
                </span>
                <span className="agreeB">
                  <label htmlFor="">개인정보 수집 및 이용 동의 (필수)</label>
                  <Link to="#" onClick={this.handleUsePrivateCollectAgreement.bind(this)} className={classNames({ radio: true, selected: this.props.isUsePrivateCollectAgreement })}>check</Link>
                  <p style={{ letterSpacing: '-1px' }}>
                    <FormattedText text={privacyPolicyEssential} style={{ letterSpacing: '-1px' }} />
                  </p>
                </span>
              </div>
              <div className="btnB">
                <Link to="#" onClick={this.handleClickStartKakaoStock.bind(this)} className="btnNext" title="다음으로 &gt;">다음으로 ></Link>
              </div>
            </div>
          </article>
        </main>

      </div>
    );
  }
}

const agreementSelector = state => (state.agreement);

export default connect(agreementSelector, {
  onUseServiceAllAgreement: useServiceAllAgreement,
  onUseServiceAgeAgreement: useServiceAgeAgreement,
  onUseServiceAgreement: useServiceAgreement,
  onUsePrivateCollectAgreement: usePrivateCollectAgreement,
  loginAndGo: requireLogin(routeActions.push),
  createUser,
  fetchLogin,
  closeModal,
  openSetProfileModal
})(AgreeTermsModal);
