import request from 'superagent';
import superagentPromisePlugin from 'superagent-promise-plugin';
import { createMemoryHistory } from 'history';
import { toArray, assign, keys, isObject, isArray } from 'lodash';
import { normalize } from 'normalizr';
import { UPDATE_LOCATION } from 'react-router-redux';
import { loginInvalidate } from '../actions/login';
import { API_ENDPOINT, SOCIAL_API_ENDPOINT, STOCKPLUS_MAIN_API_ENDPOINT } from '../constants/env';

export const CALL_API = Symbol('Call API');

const history = createMemoryHistory();
const requests = {};
let currentRequestId = 0;

const UNAUTHORIZED = 401;
const REQUIRED_API_ACTION_TYPES = 3;

function createRequest({ method, url, params, data, headers }) {
  const req = request(method, url)
    .set(headers)
    .query(params)
    .send(data);

  const reqId = currentRequestId++;
  requests[reqId] = req;

  return req
    .use(superagentPromisePlugin)
    .then((...args) => {
      delete requests[reqId];
      return Promise.resolve(...args);
    })
    .catch((...args) => {
      delete requests[reqId];
      return Promise.reject(...args);
    });
}

const addAuthorizationHeader = (header = {}, accessToken) => {
  if (accessToken) {
    return assign({}, header, { Authorization: `Bearer ${accessToken}` });
  } else {
    return header;
  }
};

const addXAccessTokenHeader = (header = {}, accessToken) => {
  if (accessToken) {
    return assign({}, header, { 'X-Access-Token': accessToken });
  } else {
    return header;
  }
};

export const apiMiddleware = store => next => (action) => {
  // Abort all pending ajax requests on update location
  if (action.type === UPDATE_LOCATION) {
    const currentPath = history.createPath(store.getState().routing.location);
    const nextPath = history.createPath(action.payload);

    if (currentPath !== nextPath) {
      keys(requests).forEach((id) => {
        requests[id].abort();
        delete requests[id];
      });
    }
  }

  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  let { types } = callAPI;
  const { config, schema } = callAPI;

  if (isObject(types) && keys(types).length === REQUIRED_API_ACTION_TYPES) {
    types = toArray(types);
  }

  if (!isArray(types) || types.length !== REQUIRED_API_ACTION_TYPES) {
    throw new Error('Expected an array of three action types.');
  }

  const [requestType, successType, failureType] = types;

  const actionWith = (data) => {
    const finalAction = assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const getResponseData = (response) => {
    if (config.isSocialApi) {
      return response.body && response.body.data;
    }

    return response.body;
  };

  // accessToken 꺼내서 주입
  const {
    login: { accessToken }
  } = store.getState();

  if (config.isSocialApi) {
    config.url = `${SOCIAL_API_ENDPOINT}${config.url}`;
    config.headers = addAuthorizationHeader(config.headers, accessToken);
  } else if (config.isMainAPI) {
    config.url = `${STOCKPLUS_MAIN_API_ENDPOINT}${config.url}`;
    config.headers = addAuthorizationHeader(config.headers, accessToken);
  } else {
    config.url = `${API_ENDPOINT}${config.url}`;
    config.headers = addXAccessTokenHeader(config.headers, accessToken);
  }

  next(actionWith({ type: requestType }));
  return createRequest(config)
    .catch((error) => {
      if (error.status === UNAUTHORIZED) {
        next(loginInvalidate());
        config.params = assign({}, config.params, {
          auth_token: null
        });
        return createRequest(config);
      }
      return Promise.reject(error);
    })
    .then((response) => {
      if (response.body.error) {
        return next(actionWith({
          type: failureType,
          error: response.body.error
        }));
      }

      const responseData = getResponseData(response);
      return next(actionWith({
        type: successType,
        payload: schema ? normalize(responseData, schema) : responseData
      }));
    })
    .catch(error => next(actionWith({
      type: failureType,
      error
    })));
};
