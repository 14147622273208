import { compact } from 'lodash';
import { CALL_API } from '../middlewares/api';
import { Routes } from '../routes';
import { newsItemArray } from '../schemas/news_item';

export const REQUEST_SECURITY_ARTICLES = 'REQUEST_SECURITY_ARTICLES';
export const RECEIVE_SECURITY_ARTICLES = 'RECEIVE_SECURITY_ARTICLES';
export const FAILURE_SECURITY_ARTICLES = 'FAILURE_SECURITY_ARTICLES';

/**
 * 커서로 전체 기사 가져오기
 * @param  {String} securityId
 * @param  {Integer} limit
 * @param  {String} cursor
 * @return {Object}
 */
export function fetchSecurityArticles(securityId: string, cursor: ?any = null, limit: number = 20): any {
  return {
    [CALL_API]: {
      types: [REQUEST_SECURITY_ARTICLES, RECEIVE_SECURITY_ARTICLES, FAILURE_SECURITY_ARTICLES],
      config: {
        method: 'get',
        url: Routes.api_security_articles_path(securityId, { limit, cursor, format: 'json' })
      },
      schema: {
        newsItems: newsItemArray
      }
    },
    securityId,
    cursor,
    limit
  };
}

export function loadSecurityArticles(securityId: string, cursor: ?any = null, limit: number = 20, ...params: Array<any>): any {
  return (dispatch, getState) => {
    const {
      paginate: {
        articlesBySecurityId
      }
    } = getState();

    const pagination = articlesBySecurityId[securityId];
    if (!cursor && pagination && pagination.pageCount > 0) {
      return null;
    }

    return dispatch(fetchSecurityArticles(securityId, cursor, limit, ...params));
  };
}

export const REQUEST_SECURITY_NEWS = 'REQUEST_SECURITY_NEWS';
export const RECEIVE_SECURITY_NEWS = 'RECEIVE_SECURITY_NEWS';
export const FAILURE_SECURITY_NEWS = 'FAILURE_SECURITY_NEWS';

/**
 * 커서로 뉴스 가져오기
 * @param  {String} securityId
 * @param  {String} cursor
 * @return {Object}
 */
export function fetchSecurityNews(securityId: string, cursor: ?any = null, limit: number = 20): any {
  return {
    [CALL_API]: {
      types: [REQUEST_SECURITY_NEWS, RECEIVE_SECURITY_NEWS, FAILURE_SECURITY_NEWS],
      config: {
        method: 'get',
        url: Routes.api_security_news_index_path(securityId, { limit, cursor, format: 'json' })
      },
      schema: {
        newsItems: newsItemArray
      }
    },
    securityId,
    cursor,
    limit
  };
}

export function loadSecurityNews(securityId: string, cursor: ?any = null, limit: number = 20, ...params: Array<any>): any {
  return (dispatch, getState) => {
    const {
      paginate: {
        newsBySecurityId
      }
    } = getState();

    const pagination = newsBySecurityId[securityId];
    if (!cursor && pagination && pagination.pageCount > 0) {
      return null;
    }

    return dispatch(fetchSecurityNews(securityId, cursor, limit, ...params));
  };
}

export const REQUEST_SECURITY_DISCLOSURES = 'REQUEST_SECURITY_DISCLOSURES';
export const RECEIVE_SECURITY_DISCLOSURES = 'RECEIVE_SECURITY_DISCLOSURES';
export const FAILURE_SECURITY_DISCLOSURES = 'FAILURE_SECURITY_DISCLOSURES';

/**
 * 커서로 공시 가져오기
 * @param  {String} securityId
 * @param  {String} cursor
 * @return {Object}
 */
export function fetchSecurityDisclosures(securityId: string, cursor: ?any = null, limit: number = 20): any {
  return {
    [CALL_API]: {
      types: [REQUEST_SECURITY_DISCLOSURES, RECEIVE_SECURITY_DISCLOSURES, FAILURE_SECURITY_DISCLOSURES],
      config: {
        method: 'get',
        url: Routes.api_security_disclosures_path(securityId, { limit, cursor, format: 'json' })
      },
      schema: {
        newsItems: newsItemArray
      }
    },
    securityId,
    cursor,
    limit
  };
}

export function loadSecurityDisclosures(securityId: string, cursor: ?any = null, limit: number = 20, ...params: Array<any>): any {
  return (dispatch, getState) => {
    const {
      paginate: {
        disclosuresBySecurityId
      }
    } = getState();

    const pagination = disclosuresBySecurityId[securityId];
    if (!cursor && pagination && pagination.pageCount > 0) {
      return null;
    }

    return dispatch(fetchSecurityDisclosures(securityId, cursor, limit, ...params));
  };
}

export const REQUEST_SECURITIES_ARTICLES = 'REQUEST_SECURITIES_ARTICLES';
export const RECEIVE_SECURITIES_ARTICLES = 'RECEIVE_SECURITIES_ARTICLES';
export const FAILURE_SECURITIES_ARTICLES = 'FAILURE_SECURITIES_ARTICLES';

/**
 * 커서로 특정 종목들과 관련있는 전체 기사 가져오기
 * @param  {String} securityIds
 * @param  {String} cursor
 * @return {Object}
 */
export function fetchSecuritiesArticles(securityIds: Array<string>, cursor: ?any = null, limit: number = 20): any {
  const serializedSecurityIds = (securityIds || []).join(',');

  return {
    [CALL_API]: {
      types: [REQUEST_SECURITIES_ARTICLES, RECEIVE_SECURITIES_ARTICLES, FAILURE_SECURITIES_ARTICLES],
      config: {
        method: 'get',
        url: Routes.articles_api_news_items_path({ security_ids: serializedSecurityIds, limit, cursor, format: 'json' })
      },
      schema: {
        newsItems: newsItemArray
      }
    },
    securityIds,
    cursor,
    limit
  };
}

export function loadSecuritiesArticles(securityIds: Array<string>, cursor: ?any = null, limit: number = 20): any {
  const ids = compact(securityIds);
  const serializedSecurityIds = (ids || []).join(',');

  return (dispatch, getState) => {
    if (ids.length === 0) {
      return Promise.resolve();
    }

    const {
      paginate: {
        articlesBySecurityIds
      }
    } = getState();

    const pagination = articlesBySecurityIds[serializedSecurityIds];
    if (!cursor && pagination && pagination.pageCount > 0) {
      return null;
    }
    return dispatch(fetchSecuritiesArticles(ids, cursor, limit));
  };
}

export const REQUEST_SECURITIES_NEWS = 'REQUEST_SECURITIES_NEWS';
export const RECEIVE_SECURITIES_NEWS = 'RECEIVE_SECURITIES_NEWS';
export const FAILURE_SECURITIES_NEWS = 'FAILURE_SECURITIES_NEWS';

/**
 * 커서로 뉴스 가져오기
 * @param  {String} securityId
 * @param  {String} cursor
 * @return {Object}
 */
export function fetchSecuritiesNews(securityIds: Array<string>, cursor: ?any = null, limit: number = 20): any {
  const serializedSecurityIds = (securityIds || []).join(',');

  return {
    [CALL_API]: {
      types: [REQUEST_SECURITIES_NEWS, RECEIVE_SECURITIES_NEWS, FAILURE_SECURITIES_NEWS],
      config: {
        method: 'get',
        url: Routes.news_api_news_items_path({ security_ids: serializedSecurityIds, limit, cursor, format: 'json' })
      },
      schema: {
        newsItems: newsItemArray
      }
    },
    securityIds,
    cursor,
    limit
  };
}

export function loadSecuritiesNews(securityIds: Array<string>, cursor: ?any = null, limit: number = 20): any {
  const ids = compact(securityIds);
  const serializedSecurityIds = (ids || []).join(',');

  return (dispatch, getState) => {
    if (ids.length === 0) {
      return Promise.resolve();
    }

    const {
      paginate: {
        newsBySecurityIds
      }
    } = getState();

    const pagination = newsBySecurityIds[serializedSecurityIds];
    if (!cursor && pagination && pagination.pageCount > 0) {
      return null;
    }
    return dispatch(fetchSecuritiesNews(ids, cursor, limit));
  };
}

export const REQUEST_SECURITIES_DISCLOSURES = 'REQUEST_SECURITIES_DISCLOSURES';
export const RECEIVE_SECURITIES_DISCLOSURES = 'RECEIVE_SECURITIES_DISCLOSURES';
export const FAILURE_SECURITIES_DISCLOSURES = 'FAILURE_SECURITIES_DISCLOSURES';

/**
 * 커서로 공시 가져오기
 * @param  {String} securityId
 * @param  {String} cursor
 * @return {Object}
 */
export function fetchSecuritiesDisclosures(securityIds: Array<string>, cursor: ?any = null, limit: number = 20): any {
  const serializedSecurityIds = (securityIds || []).join(',');

  return {
    [CALL_API]: {
      types: [REQUEST_SECURITIES_DISCLOSURES, RECEIVE_SECURITIES_DISCLOSURES, FAILURE_SECURITIES_DISCLOSURES],
      config: {
        method: 'get',
        url: Routes.disclosures_api_news_items_path({ security_ids: serializedSecurityIds, limit: 20, cursor, format: 'json' })
      },
      schema: {
        newsItems: newsItemArray
      }
    },
    securityIds,
    cursor,
    limit
  };
}

export function loadSecuritiesDisclosures(securityIds: Array<string>, cursor: ?any = null, limit: number = 20): any {
  const ids = compact(securityIds);
  const serializedSecurityIds = (ids || []).join(',');

  return (dispatch, getState) => {
    if (ids.length === 0) {
      return Promise.resolve();
    }

    const {
      paginate: {
        disclosuresBySecurityIds
      }
    } = getState();

    const pagination = disclosuresBySecurityIds[serializedSecurityIds];
    if (!cursor && pagination && pagination.pageCount > 0) {
      return null;
    }
    return dispatch(fetchSecuritiesDisclosures(ids, cursor, limit));
  };
}

export const REQUEST_NEWS = 'REQUEST_NEWS';
export const RECEIVE_NEWS = 'RECEIVE_NEWS';
export const FAILURE_NEWS = 'FAILURE_NEWS';

/**
 * 커서로 종목무관 전체 기사 가져오기
 * @param  {String} scope
 * @param  {String} cursor
 * @param  {String} limit
 * @return {Object}
 */
export function fetchNews(scope: string, cursor: ?any = null, limit: number = 20): any {
  return {
    [CALL_API]: {
      types: [REQUEST_NEWS, RECEIVE_NEWS, FAILURE_NEWS],
      config: {
        method: 'get',
        url: Routes.all_news_api_news_items_path({ scope, limit, cursor, format: 'json' })
      },
      schema: {
        newsItems: newsItemArray
      }
    },
    scope,
    cursor,
    limit
  };
}

export function loadNews(scope: string, cursor: ?any = null, limit: number = 20): any {
  return (dispatch, getState) => {
    const {
      paginate: {
        newsByScope
      }
    } = getState();

    const pagination = newsByScope;
    if (!cursor && pagination && pagination.pageCount > 0) {
      return null;
    }
    return dispatch(fetchNews(scope, cursor, limit));
  };
}

