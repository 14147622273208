import { connect } from 'react-redux';
import { AppDownloadModal } from '../../components/common/app_download_modal';

import { closeModal } from '../../actions/modals';
import { requestOneLinkWarp } from '../../actions/scheme';

export default connect(null, {
  onClickClose: closeModal,
  requestOneLinkWarp
})(AppDownloadModal);
