import React from 'react';
import { Route, IndexRedirect } from 'react-router';
import { loadComponent } from '../../helper';

export default (
  <Route path="news">
    <IndexRedirect to="popular" />

    <Route
      path="popular" getComponent={
        (location, cb) =>
          import('../../../containers/news/news_popular_container')
            .then(loadComponent(cb))
      }
    />
    <Route
      path="latest" getComponent={
        (location, cb) =>
          import('../../../containers/news/news_latest_container')
            .then(loadComponent(cb))
      }
    />
  </Route>
);
