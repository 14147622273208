/* @flow */
import { combineReducers } from 'redux';
import { merge, assign } from 'lodash';
import reducers from './entities/index';

function updateEntities(state = {}, action) {
  if (action.payload && action.payload.entities) {
    return merge({}, state, action.payload.entities);
  }
  return state;
}

function noOpReducer(state = {}) {
  return state;
}

function createNoOpReducers(...keys) {
  const reducersWithKey = keys.map(k => ({
    [k]: noOpReducer,
  }));
  return assign({}, ...reducersWithKey);
}

const combinedReducers = combineReducers({
  ...createNoOpReducers(
    'topics',
    'recentSecurities',
    'newsItems',
    'dayCandles',
    'investors',
    'dayTraders',
    'recentTraders',
    'comments',
    'rankers',
    'rankerDetail',
    'contests',
    'exchangeTradedAnalyses',
    'exchangeTradedAssets',
    'exchangeTradedValues',
    'wicsSectors',
    'contributors',
    'notices',
    'simpleStocks',
    'assetSearches',
    'stocksForChangeTypeStatistics',
    'yearlyStatements',
    'quarterlyStatements',
    'assets',
    'dayForexes',
    'tickForexes',
    'forexCalcs',
    'chartImageUrl',
    'foreignChartImageUrl',
  ),
  posts: reducers.postsReducer,
  socialPosts: reducers.socialPostsReducer,
  insightArticles: reducers.insightArticlesReducer,
  favoriteStocks: reducers.favoriteStocksReducer,
  favoriteStockLists: reducers.favoriteStockListsReducer,
  favoriteStockExistences: reducers.favoriteStockExistencesReducer,
  companies: reducers.companiesReducer,
  issueKeywords: reducers.issueKeywordsReducer,
  users: reducers.usersReducer,
  topRankedSocialPost: reducers.topRankedSocialPostReducer,
  socialPostComments: reducers.socialPostCommentsReducer,
  socialPostCommentsInComment: reducers.socialPostCommentsInCommentReducer,
  socialSecurities: reducers.socialSecuritiesReducer,
});

export function entitiesReducer(state: Object = {}, action: Object): Object {
  const updatedEntities = updateEntities(state, action);
  const computedState = combinedReducers(updatedEntities, action);
  return assign({}, state, computedState);
}
