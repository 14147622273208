import React from 'react';
import { connect } from 'react-redux';
import { ABtn } from '../../components/common/btn';
import { openAppDownloadModal } from '../../actions/modals';

class WriteButtonFloatingContainer extends React.Component {
  static propTypes = {
    openAppDownloadModal: React.PropTypes.func.isRequired,
    floatingProps: React.PropTypes.object,
  };

  handleClickWrite = event => {
    event.preventDefault();
    this.props.openAppDownloadModal({ 
      code: this.props.floatingProps?.shortCode,
    });
  };

  render() {
    return (
      <div className="btnWriting">
        <ABtn href="#" title="글쓰기" onClick={this.handleClickWrite}>글쓰기</ABtn>
      </div>
    );
  }
}

export default connect(state => state.ui.floatings, {
  openAppDownloadModal,
})(WriteButtonFloatingContainer);
