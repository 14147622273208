import { Routes } from '../routes';
import { CALL_API } from '../middlewares/api';

import { goToSearchQuery, goBack } from './routes';

import { assetSearchArray } from '../schemas/asset_search';
import { topicArray } from '../schemas/topic';
import { newsItemArray } from '../schemas/news_item';
import { insightArticleArray } from '../schemas/insight_article';
import { issueKeywordArray } from '../schemas/issue_keyword';

import type { Action } from '../types/action';

export const INIT_SEARCH = 'INIT_SEARCH';
export function initSearch(): Action {
  return {
    type: INIT_SEARCH
  };
}

export const CLEAR_KEYWORD = 'CLEAR_KEYWORD';
export function clearKeyword(): Action {
  return {
    type: CLEAR_KEYWORD
  };
}

export const SET_KEYWORD = 'SET_KEYWORD';
export function setKeyword(keyword): Action {
  return {
    type: SET_KEYWORD,
    keyword
  };
}

export const REQUEST_QUERY = 'REQUEST_QUERY';
export const RECEIVE_QUERY = 'RECEIVE_QUERY';
export const FAILURE_QUERY = 'FAILURE_QUERY';

export function fetchSearchResult(keyword: string): Object {
  return {
    [CALL_API]: {
      types: [REQUEST_QUERY, RECEIVE_QUERY, FAILURE_QUERY],
      config: {
        method: 'get',
        url: Routes.query_api_search_index_path({ keyword, format: 'json' })
      }
    },
    keyword
  };
}

export const REQUEST_ASSETS_SEARCH_RESULT = 'REQUEST_STOCKS_SEARCH_RESULT';
export const RECEIVE_ASSETS_SEARCH_RESULT = 'RECEIVE_STOCKS_SEARCH_RESULT';
export const FAILURE_ASSETS_SEARCH_RESULT = 'FAILURE_STOCKS_SEARCH_RESULT';

export function fetchAssetsSearchResult(keyword: string, cursor: ?any = null, limit: number = 10): any {
  return {
    [CALL_API]: {
      types: [REQUEST_ASSETS_SEARCH_RESULT, RECEIVE_ASSETS_SEARCH_RESULT, FAILURE_ASSETS_SEARCH_RESULT],
      config: {
        method: 'get',
        url: Routes.assets_api_search_index_path({ keyword, cursor, limit, format: 'json' })
      },
      schema: {
        assets: assetSearchArray
      }
    },
    keyword,
    cursor,
    limit
  };
}

// $FlowIssue
export function loadAssetsSearchResult(keyword: string, cursor: ?any, ...args: Array<any>): any {
  return (dispatch, getState) => {
    const {
      paginate: {
        assetsSearchResultByKeyword: {
          [keyword]: paginate
        }
      }
    } = getState();

    if (paginate && paginate.pageCount > 0 && !cursor) {
      return Promise.resolve(paginate);
    }

    return dispatch(fetchAssetsSearchResult(keyword, cursor, ...args));
  };
}

export const REQUEST_TOPICS_SEARCH_RESULT = 'REQUEST_TOPICS_SEARCH_RESULT';
export const RECEIVE_TOPICS_SEARCH_RESULT = 'RECEIVE_TOPICS_SEARCH_RESULT';
export const FAILURE_TOPICS_SEARCH_RESULT = 'FAILURE_TOPICS_SEARCH_RESULT';

export function fetchTopicsSearchResult(keyword: string, cursor: ?any = null, limit: number = 10): any {
  return {
    [CALL_API]: {
      types: [REQUEST_TOPICS_SEARCH_RESULT, RECEIVE_TOPICS_SEARCH_RESULT, FAILURE_TOPICS_SEARCH_RESULT],
      config: {
        method: 'get',
        url: Routes.topics_api_search_index_path({ keyword, cursor, limit, format: 'json' })
      },
      schema: {
        topics: topicArray
      }
    },
    keyword,
    cursor,
    limit
  };
}

export function loadTopicsSearchResult(keyword: string, cursor: ?any, ...args: Array<any>): any {
  return (dispatch, getState) => {
    const {
      paginate: {
        topicsSearchResultByKeyword: {
          [keyword]: paginate
        }
      }
    } = getState();

    if (paginate && paginate.pageCount > 0 && !cursor) {
      return Promise.resolve();
    }

    return dispatch(fetchTopicsSearchResult(keyword, cursor, ...args));
  };
}

export const REQUEST_NEWS_RELATED_ASSETS_SEARCH_RESULT = 'REQUEST_NEWS_RELATED_ASSETS_SEARCH_RESULT';
export const RECEIVE_NEWS_RELATED_ASSETS_SEARCH_RESULT = 'RECEIVE_NEWS_RELATED_ASSETS_SEARCH_RESULT';
export const FAILURE_NEWS_RELATED_ASSETS_SEARCH_RESULT = 'FAILURE_NEWS_RELATED_ASSETS_SEARCH_RESULT';

export function fetchNewsRelatedAssetsSearchResult(keyword: string, cursor: ?any = null, limit: number = 5): any {
  return {
    [CALL_API]: {
      types: [
        REQUEST_NEWS_RELATED_ASSETS_SEARCH_RESULT,
        RECEIVE_NEWS_RELATED_ASSETS_SEARCH_RESULT,
        FAILURE_NEWS_RELATED_ASSETS_SEARCH_RESULT
      ],
      config: {
        method: 'get',
        url: Routes.news_related_assets_api_search_index_path({ keyword, cursor, limit, format: 'json' })
      },
      schema: {
        newsRelatedAssets: assetSearchArray
      }
    },
    keyword,
    cursor,
    limit
  };
}

export function loadNewsRelatedAssetsSearchResult(keyword: string, cursor: ?any = null, limit: number = 5): any {
  return (dispatch, getState) => {
    const {
      paginate: {
        newsRelatedAssetsSearchResultByKeyword: {
          [keyword]: paginate
        }
      }
    } = getState();

    if (paginate && paginate.pageCount > 0 && !cursor) {
      return Promise.resolve(paginate);
    }

    return dispatch(fetchNewsRelatedAssetsSearchResult(keyword, cursor, limit));
  };
}

export const REQUEST_NEWS_SEARCH_RESULT = 'REQUEST_NEWS_SEARCH_RESULT';
export const RECEIVE_NEWS_SEARCH_RESULT = 'RECEIVE_NEWS_SEARCH_RESULT';
export const FAILURE_NEWS_SEARCH_RESULT = 'FAILURE_NEWS_SEARCH_RESULT';

export function fetchNewsSearchResult(keyword: string, cursor: ?any = null, limit: number = 10): any {
  return {
    [CALL_API]: {
      types: [REQUEST_NEWS_SEARCH_RESULT, RECEIVE_NEWS_SEARCH_RESULT, FAILURE_NEWS_SEARCH_RESULT],
      config: {
        method: 'get',
        url: Routes.news_api_search_index_path({ keyword, cursor, limit, format: 'json' })
      },
      schema: {
        news: newsItemArray
      }
    },
    keyword,
    cursor,
    limit
  };
}

export function loadNewsSearchResult(keyword: string, cursor: ?any, ...args: Array<any>): any {
  return (dispatch, getState) => {
    const {
      paginate: {
        newsSearchResultByKeyword: {
          [keyword]: paginate
        }
      }
    } = getState();

    if (paginate && paginate.pageCount > 0 && !cursor) {
      return Promise.resolve();
    }

    return dispatch(fetchNewsSearchResult(keyword, cursor, ...args));
  };
}

export const REQUEST_INSIGHT_ARTICLES_SEARCH_RESULT = 'REQUEST_INSIGHT_ARTICLES_SEARCH_RESULT';
export const RECEIVE_INSIGHT_ARTICLES_SEARCH_RESULT = 'RECEIVE_INSIGHT_ARTICLES_SEARCH_RESULT';
export const FAILURE_INSIGHT_ARTICLES_SEARCH_RESULT = 'FAILURE_INSIGHT_ARTICLES_SEARCH_RESULT';

export function fetchInsightArticlesSearchResult(keyword: string, cursor: ?any = null, limit: number = 10): any {
  return {
    [CALL_API]: {
      types: [REQUEST_INSIGHT_ARTICLES_SEARCH_RESULT, RECEIVE_INSIGHT_ARTICLES_SEARCH_RESULT, FAILURE_INSIGHT_ARTICLES_SEARCH_RESULT],
      config: {
        method: 'get',
        url: Routes.insight_articles_api_search_index_path({ keyword, cursor, limit, format: 'json' })
      },
      schema: {
        insightArticles: insightArticleArray
      }
    },
    keyword,
    cursor,
    limit
  };
}

export function loadInsightArticlesSearchResult(keyword: string, cursor: ?any, ...args: Array<any>): any {
  return (dispatch, getState) => {
    const {
      paginate: {
        insightArticlesSearchResultByKeyword: {
          [keyword]: paginate
        }
      }
    } = getState();

    if (paginate && paginate.pageCount > 0 && !cursor) {
      return Promise.resolve();
    }

    return dispatch(fetchInsightArticlesSearchResult(keyword, cursor, ...args));
  };
}

export const REQUEST_ISSUE_KEYWORDS = 'REQUEST_ISSUE_KEYWORDS';
export const RECEIVE_ISSUE_KEYWORDS = 'RECEIVE_ISSUE_KEYWORDS';
export const FAILURE_ISSUE_KEYWORDS = 'FAILURE_ISSUE_KEYWORDS';

export function fetchIssueKeywords(): any {
  return {
    [CALL_API]: {
      types: [REQUEST_ISSUE_KEYWORDS, RECEIVE_ISSUE_KEYWORDS, FAILURE_ISSUE_KEYWORDS],
      config: {
        method: 'get',
        url: Routes.issue_keywords_api_search_index_path({ format: 'json' })
      },
      schema: {
        issueKeywords: issueKeywordArray
      }
    }
  };
}

export function showSearchResult(keyword: string, ...params: Array<any>): any {
  return (dispatch) => {
    dispatch(initSearch());
    dispatch(setKeyword(keyword));
    return dispatch(goToSearchQuery(keyword, ...params));
  };
}

export const REQUEST_SUGGEST = 'REQUEST_SUGGEST';
export const RECEIVE_SUGGEST = 'RECEIVE_SUGGEST';
export const FAILURE_SUGGEST = 'FAILURE_SUGGEST';

export function fetchSuggest(keyword: string): Object {
  return {
    [CALL_API]: {
      types: [REQUEST_SUGGEST, RECEIVE_SUGGEST, FAILURE_SUGGEST],
      config: {
        method: 'get',
        url: Routes.autocomplete_api_search_index_path({ keyword, format: 'json' })
      }
    },
    keyword
  };
}

export function cancelSearch(): Object {
  return goBack({ restoreLocation: true, fallbackLocation: Routes.m_path() });
}
