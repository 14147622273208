import { actionTypes as contestActionTypes } from '../constants/contests';

export const CLICK_TAB = 'TABS/CLICK_TAB';
export function clickTab(name, selectedTabIndex) {
  return {
    type: CLICK_TAB,
    name,
    selectedTabIndex
  };
}

export function changeContests(contestId) {
  return {
    type: contestActionTypes.CHANGE,
    contestId
  };
}
