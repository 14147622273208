import * as emotionsActions from '../../actions/emotions';
import { assign } from 'lodash';

export function emotionsReducer(state, action) {
  switch (action.type) {
  case emotionsActions.SUCCESS_LIKE:
    if (state.myEmotion.disliked) {
      return assign({}, state, {
        myEmotion: { liked: true, disliked: false },
        likesCount: state.likesCount + 1,
        dislikesCount: state.dislikesCount - 1
      });
    } else if (!state.myEmotion.liked) {
      return assign({}, state, {
        myEmotion: { liked: true, disliked: false },
        likesCount: state.likesCount + 1
      });
    }
    return state;
  case emotionsActions.SUCCESS_DISLIKE:
    if (state.myEmotion.liked) {
      return assign({}, state, {
        myEmotion: { liked: false, disliked: true },
        likesCount: state.likesCount - 1,
        dislikesCount: state.dislikesCount + 1
      });
    } else if (!state.myEmotion.disliked) {
      return assign({}, state, {
        myEmotion: { liked: false, disliked: true },
        dislikesCount: state.dislikesCount + 1
      });
    }
    return state;
  case emotionsActions.SUCCESS_UNLIKE:
    if (state.myEmotion.liked) {
      return assign({}, state, {
        myEmotion: { liked: false, disliked: false },
        likesCount: state.likesCount - 1
      });
    } else if (state.myEmotion.disliked) {
      return assign({}, state, {
        myEmotion: { liked: false, disliked: false },
        dislikesCount: state.dislikesCount - 1
      });
    }
    return state;
  default:
    return state;
  }
}
