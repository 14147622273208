import { compose, createStore, applyMiddleware } from 'redux';
import { merge } from 'lodash';
import moment from 'moment';
import { syncHistory } from 'react-router-redux';
import thunkMiddleware from 'redux-thunk';
import persistState, { mergePersistedState } from 'redux-localstorage';
import localStorageAdapter from 'redux-localstorage/lib/adapters/localStorage';
import filter from 'redux-localstorage-filter';
import { apiMiddleware } from '../middlewares/api';
import { analyticsMiddleware } from '../middlewares/analytics';
import history from './history';
import { rootReducer } from '../reducers';

const localStorage = compose(filter(['preferences', 'ui.recentViewStocks.list', 'login']))(localStorageAdapter(window.localStorage));

const reduxRouterMiddleware = syncHistory(history);

const middlewares = [apiMiddleware, analyticsMiddleware, thunkMiddleware, reduxRouterMiddleware];

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'local') {
  const createLogger = require('redux-logger');
  const logger = createLogger();
  middlewares.push(logger);
}

const createStoreWithMiddleware = compose(
  applyMiddleware(...middlewares), // any Redux middleware, e.g. redux-thunk
  persistState(localStorage, 'KakaoStock'),
  (process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    typeof window.devToolsExtension !== 'undefined' ? window.devToolsExtension() : f => f
  )
)(createStore);

const mergeLoginState = (initialState, persistedState) => {
  const { login, ...states } = persistedState;
  if (login) {
    const isTokenExpired = moment().isAfter(login.expiresAt);
    if (isTokenExpired) {
      return merge({}, initialState, states);
    }
  }
  return merge({}, initialState, persistedState);
};

const reducer = compose(
  mergePersistedState(mergeLoginState)
)(rootReducer);

const store = createStoreWithMiddleware(reducer);
reduxRouterMiddleware.listenForReplays(store);

export default store;
