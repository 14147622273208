import { Routes } from '../routes';
import { CALL_API } from '../middlewares/api';
import { assetArray } from '../schemas/asset';
import { recentViewAssetsSelector } from '../selectors/recent_view_stocks_selectors';
import fetchActionTypes from '../constants/fetch_actions';

export function fetchAssets(ids) {
  const serializedAssetsIds = (ids || []).join(',');

  return {
    [CALL_API]: {
      types: fetchActionTypes.ASSETS,
      config: {
        method: 'get',
        url: Routes.api_assets_path({ ids: serializedAssetsIds, format: 'json' })
      },
      schema: {
        assets: assetArray
      }
    },
    ids
  };
}

export function fetchRecentViewAssets() {
  return (dispatch, getState) => {
    const list = recentViewAssetsSelector(getState());
    if (list.length === 0) {
      return Promise.resolve();
    }
    return dispatch(fetchAssets(list));
  };
}
