import { Routes } from '../routes';
import { CALL_API } from '../middlewares/api';
import { company, quarterly, yearly } from '../schemas/company';

export const REQUEST_COMPANY = 'REQUEST_COMPANY';
export const RECEIVE_COMPANY = 'RECEIVE_COMPANY';
export const FAILURE_COMPANY = 'FAILURE_COMPANY';

export function fetchCompany(securityId) {
  return {
    [CALL_API]: {
      types: [REQUEST_COMPANY, RECEIVE_COMPANY, FAILURE_COMPANY],
      config: {
        method: 'get',
        url: Routes.api_company_path(securityId, { format: 'json' })
      },
      schema: {
        company
      }
    },
    securityId
  };
}

export function loadCompany(securityId) {
  return (dispatch, getState) => {
    const {
      entities: {
        companies: {
          [securityId]: company
        }
      }
    } = getState();

    if (company) {
      return Promise.resolve();
    }

    return dispatch(fetchCompany(securityId));
  };
}

export const REQUEST_COMPANY_FINANCIAL_STATEMENTS = 'REQUEST_COMPANY_FINANCIAL_STATEMENTS';
export const RECEIVE_COMPANY_FINANCIAL_STATEMENTS = 'RECEIVE_COMPANY_FINANCIAL_STATEMENTS';
export const FAILURE_COMPANY_FINANCIAL_STATEMENTS = 'FAILURE_COMPANY_FINANCIAL_STATEMENTS';

export function fetchFinancialStatements(securityId) {
  return {
    [CALL_API]: {
      types: [REQUEST_COMPANY_FINANCIAL_STATEMENTS, RECEIVE_COMPANY_FINANCIAL_STATEMENTS, FAILURE_COMPANY_FINANCIAL_STATEMENTS],
      config: {
        method: 'get',
        url: Routes.api_company_financial_statements_path(securityId, { format: 'json' })
      },
      schema: {
        quarterly,
        yearly
      }
    },
    securityId
  };
}
