import { assign } from 'lodash';
import { SUCCESS_CREATE_COMMENT, SUCCESS_DELETE_COMMENT } from '../../actions/comments';

const initialState = {
  commentsCount: 0
};

export function commentsReducer(state = initialState, action) {
  switch (action.type) {
  case SUCCESS_CREATE_COMMENT:
    return assign({}, state, {
      commentsCount: state.commentsCount + 1
    });
  case SUCCESS_DELETE_COMMENT:
    return assign({}, state, {
      commentsCount: state.commentsCount - 1
    });
  default:
    return state;
  }
}
