import { Routes } from '../routes';
import { noticeArray } from '../schemas/notice';
import { CALL_API } from '../middlewares/api';

export const REQUEST_NOTICES = 'REQUEST_NOTICES';
export const RECEIVE_NOTICES = 'RECEIVE_NOTICES';
export const FAILURE_NOTICES = 'FAILURE_NOTICES';

export function fetchNotices(limit = 10, cursor = null) {
  return {
    [CALL_API]: {
      types: [REQUEST_NOTICES, RECEIVE_NOTICES, FAILURE_NOTICES],
      config: {
        method: 'get',
        url: Routes.api_notices_path({ limit, cursor, format: 'json' })
      },
      schema: {
        notices: noticeArray
      }
    },
    limit,
    cursor
  };
}

export const TOGGLE_NOTICE = 'TOGGLE_NOTICE';
export function toggleNotice(id) {
  return {
    type: TOGGLE_NOTICE,
    id
  };
}
