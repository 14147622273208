import request from 'superagent';
import superagentPromisePlugin from 'superagent-promise-plugin';
import { Routes } from '../routes';
import { CALL_API } from '../middlewares/api';
import { loginInvalidate, requireLogin } from './login';

export const REQUEST_INDEX_USER = 'REQUEST_INDEX_USER';
export const SUCCESS_INDEX_USER = 'SUCCESS_INDEX_USER';
export const FAILURE_INDEX_USER = 'FAILURE_INDEX_USER';
export function getUser() {
  return {
    [CALL_API]: {
      types: [REQUEST_INDEX_USER, SUCCESS_INDEX_USER, FAILURE_INDEX_USER],
      config: {
        method: 'get',
        url: Routes.api_user_path({ format: 'json' })
      }
    }
  };
}

export const REQUEST_INDEX_SETTINGS = 'REQUEST_INDEX_SETTINGS';
export const SUCCESS_INDEX_SETTINGS = 'SUCCESS_INDEX_SETTINGS';
export const FAILURE_INDEX_SETTINGS = 'FAILURE_INDEX_SETTINGS';
export function getSettings() {
  return {
    [CALL_API]: {
      types: [REQUEST_INDEX_SETTINGS, SUCCESS_INDEX_SETTINGS, FAILURE_INDEX_SETTINGS],
      config: {
        method: 'get',
        url: Routes.setting_api_user_path({ format: 'json' })
      }
    }
  };
}

export const REQUEST_UPDATE_SETTINGS = 'REQUEST_UPDATE_SETTINGS';
export const SUCCESS_UPDATE_SETTINGS = 'SUCCESS_UPDATE_SETTINGS';
export const FAILURE_UPDATE_SETTINGS = 'FAILURE_UPDATE_SETTINGS';
export function updateSettings(openFavoriteStocks) {
  return {
    [CALL_API]: {
      types: [REQUEST_UPDATE_SETTINGS, SUCCESS_UPDATE_SETTINGS, FAILURE_UPDATE_SETTINGS],
      config: {
        method: 'put',
        url: Routes.update_setting_api_user_path({ format: 'json' }),
        data: {
          open_favorite_stocks: openFavoriteStocks
        }
      }
    }
  };
}

export const REQUEST_CREATE_USER = 'REQUEST_CREATE_USER';
export const SUCCESS_CREATE_USER = 'SUCCESS_CREATE_USER';
export const FAILURE_CREATE_USER = 'FAILURE_CREATE_USER';
export function createUser(kakaoAccessToken) {
  return {
    [CALL_API]: {
      types: [REQUEST_CREATE_USER, SUCCESS_CREATE_USER, FAILURE_CREATE_USER],
      config: {
        method: 'post',
        url: Routes.api_user_path({ format: 'json' }),
        data: {
          kakao_access_token: kakaoAccessToken
        }
      }
    }
  };
}

export const REQUEST_UPDATE_USER = 'REQUEST_UPDATE_USER';
export const SUCCESS_UPDATE_USER = 'SUCCESS_UPDATE_USER';
export const FAILURE_UPDATE_USER = 'FAILURE_UPDATE_USER';
export function updateUserProfile({ name, birthYear, gender, email }) {
  return {
    [CALL_API]: {
      types: [REQUEST_UPDATE_USER, SUCCESS_UPDATE_USER, FAILURE_UPDATE_USER],
      config: {
        method: 'put',
        url: Routes.api_user_path({ format: 'json' }),
        data: {
          name,
          birth_year: birthYear,
          gender,
          email
        }
      }
    }
  };
}

export const REQUEST_SERVICE_SECESSION = 'REQUEST_SERVICE_SECESSION';
export const SUCCESS_SERVICE_SECESSION = 'SUCCESS_SERVICE_SECESSION';
export const FAILURE_SERVICE_SECESSION = 'FAILURE_SERVICE_SECESSION';
export function requestServiceSecession() {
  return {
    [CALL_API]: {
      types: [REQUEST_SERVICE_SECESSION, SUCCESS_SERVICE_SECESSION, FAILURE_SERVICE_SECESSION],
      config: {
        method: 'delete',
        url: Routes.api_user_path({ format: 'json' })
      }
    }
  };
}

export function requireServiceSecession() {
  return (dispatch, _) => {
    return dispatch(requireLogin(requestServiceSecession)())
      .then(dispatch(loginInvalidate()));
  };
}

export function requireImageUpload(file) {
  return (dispatch, getState) => {
    const { login } = getState();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (_upload) => {
        request.post(process.env.KAKAOSTOCK_AVATAR_UPLOAD_URL)
          .set('withCredentials', true)
          .field('access_token', login.accessToken)
          .attach('file', file)
          .use(superagentPromisePlugin)
          .catch(reject)
          .then(resolve);
      };

      reader.readAsDataURL(file);
    });
  };
}

export const SUCCESS_UPDATE_USER_NAME = 'SUCCESS_UPDATE_USER_NAME';
export const EDITED_USER_NAME = 'EDITED_USER_NAME';
