/* eslint no-alert: 0 */
/* eslint no-param-reassign: 0 */
/* @flow */
import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { range } from 'lodash';
import { closeModal } from '../../actions/modals';

import { updateSettings, getSettings, getUser, requireImageUpload, updateUserProfile } from '../../actions/user';

const defaultBirth = '----';
const availableAge = 14;
const MALE = 1;
const FEMALE = 2;

class SetProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: '',
      userBirthYear: '',
      userEmail: '',
      userGender: 0,
      userSetting: true
    };
  }

  componentDidMount() {
    this.props.getUser();
    this.props.getSettings();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user && nextProps.user.name) {
      if (this.state.userName === '') {
        this.setState({
          userName: nextProps.user.name,
          userBirthYear: nextProps.user.birthYear,
          userEmail: nextProps.user.email,
          userGender: nextProps.user.gender,
          userSetting: nextProps.user.openFavoriteStocks
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user && (prevState.userGender === 0 || prevState.userGender === null)) {
      this.state.userGender = 1;
      this.props.onUpdateUserProfile({ name: this.state.userName, birth_year: '', gender: 1, email: '' });
      this.props.updateSettings(true);
    }
  }

  props: {
    user: Object,
    settings: Object,
    birthYearOptions: Array,
    updateSettings: typeof updateSettings,
    getUser: typeof getUser,
    getSettings: typeof getSettings,
    onChangePhoto: typeof requireImageUpload,
    onUpdateUserProfile: typeof updateUserProfile, // 프로필 변경 업데이트 요청
    closeModal: typeof closeModal
  };
  static defaultProps = {
    user: {},
    settings: {},
    birthYearOptions: range(new Date().getFullYear() - availableAge, 1900, -1)
  };
  static style = {
    content: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: 0
    }
  };

  /**
   * 뒤로 가기
   * @param e
   */
  handleClickBack(e) {
    this.props.onUpdateUserProfile({ name: '', birth_year: '----', gender: 1, email: ' ' });
    this.props.updateSettings(true);
    this.props.closeModal();
    e.preventDefault();
  }

  /**
   * 이름 변경 업데이트
   * @param e
   */
  handleClickSaveUser(e: Object) {
    e.preventDefault();
    if (this.refs.nickName.value.length >= 12) {
      alert('12자 이내의 닉네임을 적어주세요.');
      this.refs.nickName.value = this.props.user.name;
      this.state.userName = this.props.user.name;
    } else if (this.refs.nickName.value.length === 0) {
      alert('닉네임을 입력해주세요.');
      this.refs.nickName.value = this.props.user.name;
      this.state.userName = this.props.user.name;
    }
  }

  handleUpdateUserBirth(e) {
    e.preventDefault();
    this.setState({
      userBirthYear: e.target.value
    });
  }

  handleUpdateUserGender(gender: number, e: Object) {
    e.preventDefault();
    if (gender !== this.props.user.gender) {
      this.refs.userGender.value = gender;
      this.props.onUpdateUserProfile({ name: '', birth_year: '', gender, email: '' });
    }
  }

  handleUpdateUserSettings(open: Boolean, e: Object) {
    if (open !== this.props.settings.open_favorite_stocks) {
      this.props.updateSettings(open)
        .then((res) => {
          if (res.error) {
            alert(res.error.message);
          }
        });
    }
    e.preventDefault();
  }

  get changeset() {
    return {
      name: this.refs.nickName.value,
      birthYear: this.refs.birthYear.value,
      gender: this.refs.userGender.value,
      email: this.refs.userEmail.value
    };
  }

  saveUser() {
    this.props.onUpdateUserProfile(this.changeset).then((res) => {
      if (res.error) {
        alert(res.error.message);
        if (res.error.code === -1) {
          this.refs.nickName.value = this.props.user.name;
          this.state.userName = this.props.user.name;
        }
      } else {
        this.props.closeModal();
      }
    });
  }

  /**
   * 프로필 이미지
   * @returns {*}
   */
  get avatarUrl() {
    return this.props.user.avatarUrl;
  }

  /**
   * 프로필 이미지 교체 관련 핸들러
   * @param e
   */
  handleSubmit(e) {
    e.preventDefault();
  }

  /**
   * 프로필 이미지 업로드
   * @param e
   */
  handleChangeProfileImage(e) {
    e.preventDefault();
    const profileImage = e.target.files[0];
    this.props.onChangePhoto(profileImage)
      .then((res) => {
        if (res.error) {
          alert(res.error.message);
        }
        this.props.getUser();
      });
  }

  handleChangeName(e) {
    e.preventDefault();
    this.setState({
      userName: e.target.value
    });
  }

  handleChangeEmail(e) {
    e.preventDefault();
    this.setState({
      userEmail: e.target.value
    });
  }

  checkConditions(e) {
    e.preventDefault();
    if (this.state.userBirthYear === null || this.state.userBirthYear === '----') {
      alert('출생년도를 입력해 주세요.');
    } else if (this.props.user.gender === null) {
      alert('성별을 입력해 주세요.');
    } else {
      this.saveUser();
    }
  }

  render() {
    const userStyle = this.avatarUrl ? {
      backgroundImage: `url(${this.avatarUrl})`
    } : {};
    return (
      <div>
        <header>
          <div className="topDef line">
            <h2><a href="#">프로필 설정</a></h2>
            <span className="btnB">
              <Link to="#" onClick={this.handleClickBack.bind(this)} className="btnPrev">이전</Link>
            </span>
          </div>
        </header>
        <main>
          <article className="mystockW">
            <div className="contW02 cb-profile">
              <div className="signupB pb0">
                <span className="thumb">
                  <form encType="multipart/form-data">
                    <a href="#" className="photo" onClick={this.handleSubmit.bind(this)}><img style={userStyle} width="100%" alt="" /></a>
                    <input className="btnPhoto" type="file" accept="image/*" onChange={this.handleChangeProfileImage.bind(this)} />
                  </form>
                </span>
                <ul>
                  <li>
                    <strong>닉네임</strong>
                    <div className="txtB">
                      <input type="text" ref="nickName" value={this.state.userName} onChange={this.handleChangeName.bind(this)} onBlur={this.handleClickSaveUser.bind(this)} />
                    </div>
                  </li>
                  <li className="birth">
                    <strong>출생년도</strong>
                    <div className="txtB">
                      <select ref="birthYear" value={this.state.userBirthYear ? this.state.userBirthYear : defaultBirth} onChange={this.handleUpdateUserBirth.bind(this)}>
                        <option>{defaultBirth}</option>
                        {
                          this.props.birthYearOptions.map((year) => {
                            if (year === this.state.userBirthYear) {
                              return (<option selected value={year}>{year}</option>);
                            }
                            return (<option value={year}>{year}</option>);
                          })
                        }
                      </select>
                      <span className="ico-inverted-triangle" />
                    </div>
                  </li>
                  <li>
                    <strong>성별</strong>
                    <div className="txtB">
                      <a href="#" ref="userGender" className={this.props.user.gender === MALE ? 'selected' : ''} onClick={this.handleUpdateUserGender.bind(this, MALE)}>남</a>
                      <a href="#" ref="userGender" className={this.props.user.gender === FEMALE ? 'selected' : ''} onClick={this.handleUpdateUserGender.bind(this, FEMALE)}>여</a>
                    </div>
                  </li>
                  <li>
                    <strong>이메일</strong>
                    <div className="txtB">
                      <input type="text" ref="userEmail" value={this.state.userEmail} onChange={this.handleChangeEmail.bind(this)} />
                    </div>
                  </li>
                  <li className="fav-stock">
                    <strong>관심종목</strong>
                    <div className="txtB">
                      <a href="#" className={this.props.user.openFavoriteStocks ? 'selected' : ''} onClick={this.handleUpdateUserSettings.bind(this, true)}>공개</a>
                      <a href="#" className={!this.props.user.openFavoriteStocks ? 'selected' : ''} onClick={this.handleUpdateUserSettings.bind(this, false)}>비공개</a>
                    </div>
                  </li>
                </ul>
                <div className="btnB">
                  <a href="#" className="btnNext" onClick={this.checkConditions.bind(this)} title="증권플러스 시작하기">증권플러스 시작하기</a>
                </div>
              </div>
            </div>
          </article>
        </main>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, {
  closeModal,
  getUser,
  updateSettings,
  getSettings,
  onChangePhoto: requireImageUpload,
  onUpdateUserProfile: updateUserProfile
})(SetProfileModal);
