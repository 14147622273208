/* @flow */
import {
  SELECT_FAVORITE_STOCK,
  SUCCESS_DELETE_FAVORITE_STOCKS,
  CLEAR_SELECTED_FAVORITE_STOCKS
} from '../../actions/favorite_stocks';
import { combineReducers } from 'redux';
import { xor } from 'lodash';

type State = {
  selectedStocks: Array<string>
};

export const initialState: State = {
  selectedStocks: []
};

function selectedStocks(state = [], action) {
  switch (action.type) {
  case SELECT_FAVORITE_STOCK:
    return xor(state, [action.id]);
  case SUCCESS_DELETE_FAVORITE_STOCKS:
    return [];
  case CLEAR_SELECTED_FAVORITE_STOCKS:
    return [];
  default:
    return state;
  }
}

export const favoriteStocksReducer = combineReducers({
  selectedStocks
});
