export const SELECT_DEBATES_CATEGORY = 'SELECT_DEBATES_CATEGORY';
export const CLICK_DEBATES_SORT = 'CLICK_DEBATES_SORT';
export const RESET_DEBATES_SORT = 'RESET_DEBATES_SORT';
export const CLICK_DEBATES_PROFIT_FILTER_TYPE = 'CLICK_DEBATES_PROFIT_FILTER_TYPE';
export const RESET_DEBATES_PROFIT_FILTER_TYPE = 'RESET_DEBATES_PROFIT_FILTER_TYPE';
export function selectDebatesCategory(category) {
  return {
    type: SELECT_DEBATES_CATEGORY,
    category
  };
}

export function clickDebatesSort(sort) {
  return {
    type: CLICK_DEBATES_SORT,
    sort
  };
}

export function resetDebatesSort() {
  return {
    type: RESET_DEBATES_SORT
  };
}

export function clickDebatesProfitFilterType(profitFilterType) {
  return {
    type: CLICK_DEBATES_PROFIT_FILTER_TYPE,
    profitFilterType
  };
}

export function resetDebatesProfitFilterType() {
  return {
    type: RESET_DEBATES_PROFIT_FILTER_TYPE
  };
}