import React from 'react';

import { Route, IndexRedirect } from 'react-router';
import homeRoute from './home';
import stocksRoute from './stocks';
import searchRoute from './search';
import myRoute from './my';
import miscRoute from './misc';
import assetsRoute from './assets';
import fallback from './fallback';

import Page from '../page';

export default (
  <Route path="/" component={Page}>
    <IndexRedirect to="m" />
    {homeRoute}
    {assetsRoute}
    {stocksRoute}
    {searchRoute}
    {myRoute}
    {miscRoute}
    {fallback}
  </Route>
);
