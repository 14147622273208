import { combineReducers } from 'redux';
import { routeReducer } from 'react-router-redux';
import { loginReducer } from './login';
import { searchReducer } from './search';
import { paginateReducer } from './paginate';
import { trendReducer } from './trend';
import { errorsReducer } from './errors';
import { userReducer } from './user';
import { preferencesReducer } from './preferences';
import { entitiesReducer } from './entities';
import { agreementReducer } from './agreement';
import { uiReducer } from './ui';
import { featuredReducer } from './featured';
import { investingStrategiesReducer } from './investing_strategies';
import { noticesReducer } from './notices';
import { customerSupportReducer } from './customer_support';
import { pageReducer } from './page';
import { networkReducer } from './network';
import { securityFirmsReducer } from './security_firms';
import { holderDetailReducer } from './holders';

export const rootReducer = combineReducers({
  routing: routeReducer,
  login: loginReducer,
  search: searchReducer,
  paginate: paginateReducer,
  trend: trendReducer,
  errors: errorsReducer,
  preferences: preferencesReducer,
  user: userReducer,
  entities: entitiesReducer,
  ui: uiReducer,
  agreement: agreementReducer,
  featured: featuredReducer,
  investingStrategies: investingStrategiesReducer,
  notices: noticesReducer,
  customerSupport: customerSupportReducer,
  page: pageReducer,
  network: networkReducer,
  securityFirms: securityFirmsReducer,
  holderDetail: holderDetailReducer
});
