import React from 'react';

type Props = {
  onClickConfirm: (text: string, e: Object) => any,
  onClickCancel: (e: Object) => any,
  title: string,
  description: string,
  defaultValue: string
};

export class EditTextModal extends React.Component {
  props: Props;
  static defaultProps = {
    defaultValue: ''
  };

  handleClickConfirm(e) {
    if (this.props.onClickConfirm) {
      this.props.onClickConfirm(this.refs.input.value, e);
    }
    e.preventDefault();
  }

  handleClickCancel(e) {
    if (this.props.onClickCancel) {
      this.props.onClickCancel(e);
    }
    e.preventDefault();
  }

  render() {
    return (
      <div className="topicLayer">
        <h4>{this.props.title}</h4>
        <div className="detailB">
          <p>{this.props.description}</p>
          <input maxLength="12" ref="input" type="text" className="txt" defaultValue={this.props.defaultValue} />
        </div>
        <div className="btnB">
          <a href="#" onClick={this.handleClickCancel.bind(this)} className="btn">취소</a>
          <a href="#" onClick={this.handleClickConfirm.bind(this)} className="btn">확인</a>
        </div>
      </div>
    );
  }
}
