/* @flow */
/* global Kakao */
import React from 'react';
import copy from 'copy-to-clipboard';
import { amplitude } from '../../helpers/amplitude';
import { AMPLITUDE_EVENT, AMPLITUDE_PAGE, AMPLITUDE_PAGE_DETAIL, AMPLITUDE_SHARE_SERVICE } from '../../constants/amplitude';

export class ShareModal extends React.Component {
  props: {
    requestWarp: Function,
    onClickClose: Function,
    thumbnail: string,
    width: string,
    height: string,
    content: string,
    pathname: string,
    id: string
  };

  static defaultProps = {
    thumbnail: 'https://stockplus.com/og_logo.png',
    width: '600',
    height: '315',
  };

  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    };
  }

  componentWillUnmount() {
    this.props.onClickClose();
  }

  handleClickClose(e) {
    amplitude.trackEvent(AMPLITUDE_EVENT.CLICK_CLOSE, {
      page: AMPLITUDE_PAGE.SHARE,
      pageDetail: AMPLITUDE_PAGE_DETAIL.SHARE_POPUP,
    });
    this.props.onClickClose();
    e.preventDefault();
  }

  trackShareEvent(serviceName) {
    amplitude.trackEvent(AMPLITUDE_EVENT.CLICK_SHARE_SERVICE, {
      page: AMPLITUDE_PAGE.SHARE,
      pageDetail: AMPLITUDE_PAGE_DETAIL.SHARE_POPUP,
      eventLabel_1: serviceName,
      eventDetail_1: this.props.id,
    });
  }

  handleClickShareKaKao(e) {
    this.trackShareEvent(AMPLITUDE_SHARE_SERVICE.KAKAO);
    Kakao?.Share.sendScrap({ requestUrl: this.pathName });
    e.preventDefault();
  }

  handleClickShareFacebook(e) {
    this.trackShareEvent(AMPLITUDE_SHARE_SERVICE.FACEBOOK);
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.encodedPathName}`);
    e.preventDefault();
  }

  handleClickShareTwitter(e) {
    this.trackShareEvent(AMPLITUDE_SHARE_SERVICE.TWITTER);
    window.open(`https://twitter.com/intent/tweet?text=%20${this.encodedPathName}`);
    e.preventDefault();
  }

  handleClickShareMail(e) {
    this.trackShareEvent(AMPLITUDE_SHARE_SERVICE.MAIL);
    window.location.href = `mailto:?subject=&body=${this.encodedPathName}`;
    e.preventDefault();
  }

  handleClickShareBand(e) {
    this.trackShareEvent(AMPLITUDE_SHARE_SERVICE.BAND);
    window.open(`http://www.band.us/plugin/share?body=${this.encodedPathName}&route=${this.encodedPathName}`);
    e.preventDefault();
  }

  handleClickCopy(e) {
    this.trackShareEvent(AMPLITUDE_SHARE_SERVICE.COPY);
    copy(this.pathName, {
      format: 'text/plain',
      onCopy: () => this.setState({ copied: true }),
    });
    e.preventDefault();
  }

  handleClickValue(e) {
    if (this.inputElem) {
      this.inputElem.select();
    }
    e.preventDefault();
  }

  get encodedPathName() {
    return encodeURIComponent(this.pathName);
  }

  get pathName() {
    return window.location.href;
  }

  render() {
    const { copied } = this.state;
    return (
      <div className="shareLayerW">
        <div className="titleB">
          <h4>공유하기</h4>
        </div>
        {!copied ? (
          <div className="shareList f_clear">
            <a href="#" onClick={this.handleClickShareKaKao.bind(this)}>카카오톡</a>
            <a href="#" onClick={this.handleClickShareFacebook.bind(this)}>페이스북</a>
            <a href="#" onClick={this.handleClickShareTwitter.bind(this)}>트위터</a>
            <a href="#" onClick={this.handleClickShareMail.bind(this)}>메일</a>
            <a href="#" onClick={this.handleClickShareBand.bind(this)}>밴드</a>
          </div>
        ) : (
          <div className="shareList f_clear">
            <p>주소가 복사되었습니다.<br />원하는 곳에 붙여넣기 해주세요.</p>
          </div>
        )}
        {!copied && (
          <div className="inpB">
            <input
              ref={(input) => {
                this.inputElem = input;
              }}
              type="text"
              className="txt"
              defaultValue={window.location.href}
              onClick={this.handleClickValue.bind(this)}
            />
            <button className="btn" title="복사" onClick={this.handleClickCopy.bind(this)}>복사</button>
          </div>
        )}
        <a href="#" onClick={this.handleClickClose.bind(this)} className="btnClose" title="닫기">닫기</a>
      </div>
    );
  }
}

export default ShareModal;
