export const KAKAOTALK_ITUNES_URL = 'itms-apps://itunes.apple.com/app/id362057947';
export const KAKAOTALK_PKG_NAME = 'com.kakao.talk';

export const KAKAOSTOCK_ITUNES_URL = 'itms-apps://itunes.apple.com/app/id913934976';
export const KAKAOSTOCK_PKG_NAME = 'com.dunamu.stockplus';
export const KAKAOSTOCK_SCHEME = 'stockplus';

export const MAIN_MENU = 'mainMenu';
export const VIEW_STOCK = 'viewStock';
export const REGISTER_ALARM = 'registerAlarm';
export const TOPIC_LIST = 'topicList';
export const TOPIC_HOME = 'topicHome';
export const JOIN_LEAGUE = 'joinLeague';
export const USER_HOME = 'userHome';
