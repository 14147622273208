/* @flow */
import { Schema, arrayOf } from 'normalizr';
import { recentSecurityArray } from './recent_security';

export const wicsSector = new Schema('wicsSectors', {
  idAttribute: 'wicsSectorCode'
});

wicsSector.define({
  components: recentSecurityArray
});

export const wicsSectorArray = arrayOf(wicsSector);
