/* @flow */
import * as SearchActions from '../actions/search';
import { assign } from 'lodash';

import type { SearchState } from '../types/search';
import type { Action } from '../types/action';

export const initialState: SearchState = {
  keyword: '',
  suggestItems: [],
  searchResult: {
    stocks: [],
    topics: [],
    insightArticles: [],
    news: []
  }
};

export function searchReducer(state: SearchState = initialState, action: Action): SearchState {
  switch (action.type) {
  case SearchActions.REQUEST_SUGGEST:
  case SearchActions.SET_KEYWORD:
    return assign({}, state, {
      keyword: action.keyword
    });
  case SearchActions.RECEIVE_SUGGEST:
    if (!action.payload || action.payload.keyword !== state.keyword) {
      return state;
    }
    return assign({}, state, {
      suggestItems: action.payload.suggestItems
    });
  case SearchActions.CLEAR_KEYWORD:
    return assign({}, state, {
      keyword: '',
      suggestItems: []
    });
  case SearchActions.INIT_SEARCH:
    return initialState;
  default:
    return state;
  }
}
