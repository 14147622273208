/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { EditTextModal } from '../../components/common/edit_text_modal';
import { closeModal } from '../../actions/modals';
import { constructFavoriteStockList, fetchFavoriteStockLists } from '../../actions/favorite_stock_lists';

import { recentViewStocksSelector } from '../../selectors/recent_view_stocks_selectors';

class AddRecentStocksModal extends React.Component {
  props: {
    closeModal: typeof closeModal,
    constructFavoriteStockList: typeof constructFavoriteStockList,
    fetchFavoriteStockLists: typeof fetchFavoriteStockLists,
    recentViewStocks: Array<string>
  };

  handleClickConfirm(name: string, e: Object): any {
    if (name.length > 12) {
      // Due to iOS 9 safari click blocking bug
      setTimeout(() => {
        alert('12글자 이내로 입력해주세요.'); 
      }, 500);
    } else {
      this.props.constructFavoriteStockList(name, this.props.recentViewStocks)
        .then(() => this.props.closeModal())
        .then(() => this.props.fetchFavoriteStockLists());
    }
    e.preventDefault();
  }

  render() {
    return (
      <EditTextModal
        title="관심그룹 만들기"
        description="관심그룹 이름을 입력해 주세요"
        defaultValue="관심그룹"
        onClickCancel={this.props.closeModal}
        onClickConfirm={this.handleClickConfirm.bind(this)}
      />
    );
  }
}

export default connect((state, props) => ({
  recentViewStocks: recentViewStocksSelector(state, props)
}), {
  closeModal,
  constructFavoriteStockList,
  fetchFavoriteStockLists
})(AddRecentStocksModal);
