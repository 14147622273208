import { assign } from 'lodash';
import { NETWORK_TURN_OFFLINE, NETWORK_TURN_ONLINE } from '../actions/network';

const initialState = {
  isOnline: true
};

export function networkReducer(state = initialState, action) {
  switch (action.type) {
  case NETWORK_TURN_ONLINE:
    return assign({}, state, {
      isOnline: true
    });
  case NETWORK_TURN_OFFLINE:
    return assign({}, state, {
      isOnline: false
    });
  default:
    return state;
  }
}
