/* @flow */
import React from 'react';
import { find } from 'lodash';
import classNames from 'classnames';

import { FAVORITE_STOCKS_CAPACITY } from '../../constants/favorite_stocks';

import type { FavoriteStockList } from '../../types/favorite_stock_list';
import type { FavoriteStock } from '../../types/favorite_stock';

type Props = {
  favoriteStockLists: Array<FavoriteStockList>,
  registeredFavoriteStocks: Array<FavoriteStock>,
  onAddFavoriteStock?: (favoriteStockListId: number, e: Object) => any,
  onDeleteFavoriteStock?: (favoriteStockListId: number, favoriteStockId: number, e: Object) => any,
  onSaveFavoriteStockList?: (favoriteStockListName: string, e: Object) => any,
  onClickClose?: (e: Object) => any
};

type State = {
  isEditing: boolean,
  favoriteStockListName: string
};

const initialState = {
  isEditing: false,
  favoriteStockListName: '관심그룹'
};

export class EditFavoriteStocksModal extends React.Component {
  props: Props;
  state: State;
  static defaultProps = {
    favoriteStockLists: [],
    registeredFavoriteStocks: []
  };

  constructor() {
    super();
    this.state = { ...initialState };
  }

  isFullOfFavoriteStocks(list: FavoriteStockList): boolean {
    return list.favoriteStocksCount >= FAVORITE_STOCKS_CAPACITY;
  }

  findFavoriteStock(favoriteStockList: FavoriteStockList): FavoriteStock {
    return find(this.props.registeredFavoriteStocks, stock => stock.favoriteStockListId === favoriteStockList.id);
  }

  isRegistered(favoriteStockList: FavoriteStockList): boolean {
    return !!this.findFavoriteStock(favoriteStockList);
  }

  handleClickFavoriteStockList(favoriteStockList: FavoriteStockList, e: Object): any {
    if (this.props.onDeleteFavoriteStock && this.isRegistered(favoriteStockList)) {
      const favoriteStock = this.findFavoriteStock(favoriteStockList);
      // $FlowFixMe flow에서 &&로 existence 체킹을 못하는 듯
      this.props.onDeleteFavoriteStock(favoriteStockList.id, favoriteStock.id, e);
    } else if (this.props.onAddFavoriteStock && !this.isFullOfFavoriteStocks(favoriteStockList)) {
      this.props.onAddFavoriteStock(favoriteStockList.id, e);
    } else {
      e.preventDefault();
    }
  }

  handleClickClose(e: Object): any {
    if (this.props.onClickClose) {
      this.props.onClickClose(e);
    } else {
      e.preventDefault();
    }
  }

  handleChangeFavoriteStockListName(e: Object): any {
    this.setState({
      favoriteStockListName: e.target.value
    });
  }

  handleClearFavoriteStockListName(e: Object): any {
    this.setState({
      favoriteStockListName: ''
    });
    e.preventDefault();
  }

  handleClickAddFavoriteStockList(e: Object): any {
    this.setState({
      favoriteStockListName: '관심그룹',
      isEditing: true
    });
    e.preventDefault();
  }

  handleClickSaveFavoriteStockList(e: Object): any {
    if (this.props.onSaveFavoriteStockList) {
      this.props.onSaveFavoriteStockList(this.state.favoriteStockListName, e)
        .then(() => {
          this.setState({ ...initialState }); 
        });
      return;
    }
    e.preventDefault();
  }

  render() {
    return (
      <div className="favGrpLayer">
        <div className="titB">
          <h4>
            관심그룹 선택하기
          </h4>
        </div>
        <div className="listB">
          <ul>
            {this.props.favoriteStockLists.map(favoriteStockList => (
              <li key={favoriteStockList.id}>
                <a
                  className={classNames({
                    selected: this.isRegistered(favoriteStockList),
                    added: this.isFullOfFavoriteStocks(favoriteStockList)
                  })}
                  href="#"
                  onClick={this.handleClickFavoriteStockList.bind(this, favoriteStockList)}
                >
                  {favoriteStockList.name}
                </a>
                <em>{(this.isRegistered(favoriteStockList) ? '등록됨' : false)}</em>
                <i>{`${favoriteStockList.favoriteStocksCount} / ${FAVORITE_STOCKS_CAPACITY}`}</i>
              </li>
            ))}
            {this.state.isEditing ?
              <li className="add">
                <input
                  onChange={this.handleChangeFavoriteStockListName.bind(this)}
                  type="text"
                  value={this.state.favoriteStockListName}
                />
                <a
                  onClick={this.handleClearFavoriteStockListName.bind(this)}
                  href="#"
                  className="btnDel"
                >
                  삭제
                </a>
                <a
                  onClick={this.handleClickSaveFavoriteStockList.bind(this)}
                  href="#"
                  className="btnSave"
                >
                  저장
                </a>
              </li>
              :
              <li className="addW">
                <a href="#" onClick={this.handleClickAddFavoriteStockList.bind(this)} className="btn">
                  <span className="txt">
                    그룹 추가
                  </span>
                </a>
              </li>
            }
          </ul>
        </div>
        <div className="btnB">
          <a href="#" onClick={this.handleClickClose.bind(this)} className="btn">확인</a>
        </div>
      </div>
    );
  }
}
