import {
  SUCCESS_LOGIN,
  FAILURE_LOGIN,
  LOGIN_INVALIDATE,
  SUCCESS_LOGOUT,
  SUCCESS_ACTIVATE,
  FAILURE_ACTIVATE
} from '../actions/login';
import { assign } from 'lodash';
import moment from 'moment';

export const initialState = {
  isAuthenticated: false,
  accessToken: null,
  expiresAt: null
};

export function loginReducer(state = initialState, action) {
  switch (action.type) {
  case SUCCESS_LOGOUT:
    return initialState;
  case SUCCESS_LOGIN:
  case SUCCESS_ACTIVATE:
    return assign({}, state, {
      isAuthenticated: true,
      accessToken: action.payload.accessToken,
      expiresAt: moment().add(action.payload.expiresIn, 'seconds')
    });
  case FAILURE_LOGIN:
  case FAILURE_ACTIVATE:
    return initialState;
  case LOGIN_INVALIDATE:
    return initialState;
  default:
    return state;
  }
}
