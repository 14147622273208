import { compact, reject, isUndefined } from 'lodash';
import { createSelector } from 'reselect';
import { recentViewAssetsSelector } from './recent_view_stocks_selectors';
import { securityIdSelector } from './params_selectors';
import { debatesProfitFilterTypeSelector } from './debates_selectors';
import { SORT_TYPES } from '../constants/debates';
import { getSocialSecurityKey } from '../helpers/social_security';

export const idSelector = (_, props) => props.params.id;
export const entitiesSelector = state => state.entities;
export const insightArticlesByFeaturedSelector = state => state.paginate.insightArticlesByFeatured.entities.map(id => state.entities.insightArticles[id]);
export const insightArticlesSelector = state => (state.entities.insightArticles || []);

export const insightArticleSelector = createSelector(
  insightArticlesSelector,
  idSelector,
  (articles, id) => articles[id],
);

export const postsSelector = state => (state.entities.posts || {});
export const postSelector = createSelector(
  postsSelector,
  idSelector,
  (posts, id) => posts[id],
);

export const socialPostsSelector = state => (state.entities.socialPosts || {});
export const socialPostSelector = createSelector(
  socialPostsSelector,
  idSelector,
  (socialPosts, id) => socialPosts[id],
);
export const socialPostCommentsSelector = state => (state.entities.socialPostComments || {});
export const socialPostCommentsInCommentSelector = state => (state.entities.socialPostCommentsInComment || {});

export const socialSecuritiesSelector = state => (state.entities.socialSecurities || {});
export const socialSecuritySelector = createSelector(
  socialSecuritiesSelector,
  (_, { boardType, assetCode } = {}) => {
    if (!boardType || !assetCode) {
      return '';
    }
    return getSocialSecurityKey({ boardType, assetCode });
  },
  (socialSecurities, key) => {
    if (!key) {
      return null;
    }

    return socialSecurities[key];
  },
);

export const commentsSelector = state => (state.entities.comments || {});

export const recentSecurityEntitiesSelector = state => (state.entities.recentSecurities || {});
export const assetEntitiesSelector = state => (state.entities.assets || {});

export const newsItemsSelector = state => (state.entities.newsItems || {});
export const dayCandlesSelector = state => (state.entities.dayCandles || {});
export const investorsSelector = state => (state.entities.investors || {});
export const dayTradersSelector = state => (state.entities.dayTraders || {});
export const companiesSelector = state => (state.entities.companies || {});
export const recentTradersSelector = state => (state.entities.recentTraders || {});
export const contributorsSelector = state => (state.entities.contributors || {});
export const noticesEntitySelector = state => (state.entities.notices || {});

export const favoriteStockListsSelector = state => (state.entities.favoriteStockLists || {});
export const favoriteStockListSelector = createSelector(
  favoriteStockListsSelector,
  idSelector,
  (lists, id) => lists[id] || {},
);

export const favoriteStocksSelector = state => (state.entities.favoriteStocks || {});
export const favoriteStocksOfListSelector = createSelector(
  favoriteStockListSelector,
  favoriteStocksSelector,
  (list, stocks) => (list.favoriteStocks || []).map(id => stocks[id] || {}),
);

export const assetsByFavoriteStockListSelectorFactory = givenIdSelector =>
  createSelector(
    givenIdSelector,
    favoriteStockListsSelector,
    favoriteStocksSelector,
    assetEntitiesSelector,
    (favoriteStockListId, favoriteStockLists, favoriteStocks, assets) => {
      const list = favoriteStockLists[favoriteStockListId] || { favoriteStocks: [] };
      const stocks = list.favoriteStocks ? list.favoriteStocks.map(id => favoriteStocks[id] || {}) : [];
      return compact(stocks.map(s => assets[s.assetId]));
    },
  );

export const assetsByFavoriteStockListSelector = assetsByFavoriteStockListSelectorFactory(
  (_, props) => props.params.id,
);

export const assetsByRecentViewAssetsSelector = createSelector(
  recentViewAssetsSelector,
  assetEntitiesSelector,
  (assetIds, assets) => reject(assetIds.map(id => assets[id]), isUndefined),
);

export const rankersEntitySelector = state => (state.entities.rankers || {});

export const contestsEntitySelector = state => (state.entities.contests || {});

export const exchangeTradedAnalysesSelector = state => (state.entities.exchangeTradedAnalyses || {});

export const exchangeTradedAssetsSelector = state => (state.entities.exchangeTradedAssets || {});

export const exchangeTradedValuesSelector = state => (state.entities.exchangeTradedValues || {});

export const favoriteStockExistencesEntitySelector = state => (state.entities.favoriteStockExistences || {});

export const topicsSelector = state => (state.entities.topics || {});

export const wicsSectorsEntitySelector = state => (state.entities.wicsSectors);

export const assetSearchesEntitySelector = state => (state.entities.assetSearches);

export const issueKeywordsEntitySelector = state => (state.entities.issueKeywords);

export const usersEntitySelector = state => (state.entities.users);

export const forexCalcsEntitySelector = state => (state.entities.forexCalcs || {});

export const pureTopRankedSocialPostSelector = createSelector(
  state => state.entities.topRankedSocialPost,
  entities => entities,
);

export const topRankedSocialPostByParamsSelector = createSelector(
  socialPostsSelector,
  pureTopRankedSocialPostSelector,
  securityIdSelector,
  debatesProfitFilterTypeSelector,
  (socialPosts, topRankedSocialPosts, securityId, profitFilter) => {
    const topRankedSocialPostId = topRankedSocialPosts[[securityId, SORT_TYPES.RANK_DESC, profitFilter]] || {};
    return socialPosts[topRankedSocialPostId];
  },
);