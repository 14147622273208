import { SUCCESS_SECURITY_FIRMS } from '../actions/security_firms';

const initialState = [];

const tradingEnabledFilter = firm => {
  return !!firm.trading_enabled;
};

const disabledFirms = ['Kakao'];
const disabledFirmsFilter = firm => {
  return !disabledFirms.includes(firm.key);
};

const tradableSecurityFirmsFilter = firm => {
  return tradingEnabledFilter(firm) && disabledFirmsFilter(firm);
};

export function securityFirmsReducer(state = initialState, action) {
  const securityFirms = (action.payload?.data?.security_firms || [])
    .filter(tradableSecurityFirmsFilter)
    .map(firm => ({ key: firm.key, name: firm.name }));

  switch (action.type) {
  case SUCCESS_SECURITY_FIRMS:
    return securityFirms;
  default:
    return state;
  }
}
