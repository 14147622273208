/* @flow */
import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import type { ModalType } from '../../types/modals';

import ShareModal from './share_modal';
import OrderModal from './order_modal';
import SetProfileModal from './set_profile';
import AddTopicStocksModal from './add_topic_stocks_modal';
import EditFavoriteStocksModal from './edit_favorite_stocks_modal';
import AddRecentStocksModal from './add_recent_stocks_modal';
import CommentSettingModal from './comment_setting_modal';
import AgreeTermsModal from './agree_terms';
import ReportModal from './report_modal';
import DriverEventModal from './driver_event_modal.jsx';
import ReleaseDormantModal from './release_dormant';
import AppDownloadModal from './app_download_modal';
import AlertModal from './alert_modal';

import { merge } from 'lodash';

// type ModalComponent = _ModalComponent<*>;
// type _ModalComponent<T> = T;
type ModalComponent = any;
type ModalMap = {
  [type: ModalType]: ModalComponent
};

const MODALS: ModalMap = {
  share: ShareModal,
  order: OrderModal,
  setProfile: SetProfileModal,
  addTopicStocks: AddTopicStocksModal,
  editFavoriteStocks: EditFavoriteStocksModal,
  addRecentStocks: AddRecentStocksModal,
  commentSetting: CommentSettingModal,
  agreeTerms: AgreeTermsModal,
  releaseDormant: ReleaseDormantModal,
  report: ReportModal,
  driverEvent: DriverEventModal,
  appDownload: AppDownloadModal,
  alert: AlertModal
};

const modalDefaultStyle = {
  content: {
    background: 'none',
    border: 'none',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  overlay: {
    zIndex: '1000',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  }
};

class ModalRoot extends React.Component {
  props: {
    modalType: ?ModalType,
    modalProps: ?Object
  };

  componentWillMount() {
    if (document) {
      Modal.setAppElement(document.body);
    }
  }

  render() {
    if (!this.props.modalType) {
      return <Modal isOpen={false}/>;
    }

    const CurrentModal = MODALS[this.props.modalType];
    const style = merge({}, modalDefaultStyle, CurrentModal.style);

    return (
      <Modal
        isOpen
        style={style}
      >
        <CurrentModal {...this.props.modalProps} />
      </Modal>
    );
  }
}

export default connect(
  state => state.ui.modals
)(ModalRoot);
