/* global Kakao */
import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { ErrorBoundary } from '@sentry/react';
import ga from 'react-ga';
import Scheme from './components/common/scheme';
import rootRoute from './routings';
import history from './stores/history';
import store from './stores';
import * as Sentry from '@sentry/react';
import { amplitude } from './helpers/amplitude';
import { AMPLITUDE_API_KEY } from './constants/env';

const APP_UA = 'StockPlus';

export class App extends React.Component {
  static childContextTypes = {
    serverProps: React.PropTypes.object,
  };

  getChildContext() {
    return { serverProps: this.props };
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scheme = new Scheme();
    const fullPath = `${window.location.pathname}${window.location.search}`;
    if (process.env.NODE_ENV === 'production') {
      ga.initialize(process.env.GA_TRACKING_CODE);
      ga.pageview(fullPath);
    } else {
      console.groupCollapsed('Google Analytics 명령이 감지되었습니다.');
      console.info(`COMMAND: ga('send', 'pageview', '${fullPath}')`);
      console.info('Google Analytics는 production 환경만 트래킹합니다.');
      console.groupEnd();
    }

    amplitude.init(AMPLITUDE_API_KEY);

    const ua = window.navigator.userAgent;

    let appVersion;
    if (ua.includes(APP_UA)) {
      appVersion = ua.split(' ').filter(uaString => uaString.includes(APP_UA)).join('');
    }

    Sentry.setTag('app_version', appVersion || 'empty');

    const kakaoAppKey = '27ee4e26698e2f85d7983bd25de9b0aa';
    try {
      Kakao.init(kakaoAppKey);
    } catch(error) {
      Sentry.captureException(error);
    }
  }

  componentWillUnmount() {
    Kakao.cleanup();
  }

  render() {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <Router history={history} routes={rootRoute} />
        </Provider>
      </ErrorBoundary>
    );
  }
}
