import { CALL_API } from '../middlewares/api';
import { Routes } from '../routes';
import { recentSecurityArray } from '../schemas/recent_security.jsx';

export const SET_SELECTED_POPULAR_SCOPE = 'SET_SELECTED_POPULAR_SCOPE';

export function setSelectedPopularScope(selectedScope) {
  return {
    type: SET_SELECTED_POPULAR_SCOPE,
    selectedScope
  };
}


export const REQUEST_POPULAR_STOCKS = 'REQUEST_POPULAR_STOCKS';
export const RECEIVE_POPULAR_STOCKS = 'RECEIVE_POPULAR_STOCKS';
export const FAILURE_POPULAR_STOCKS = 'FAILURE_POPULAR_STOCKS';

/**
 * 실전투자
 * @param limit
 */
export function fetchPopularTrading(limit = 5) {
  return (dispatch) => {
    // dispatch(setSelectedPopularScope("trading"));
    dispatch({
      [CALL_API]: {
        types: [REQUEST_POPULAR_STOCKS, RECEIVE_POPULAR_STOCKS, FAILURE_POPULAR_STOCKS],
        config: {
          method: 'get',
          url: Routes.trading_value_api_popular_stocks_path({ limit, format: 'json' })
        },
        schema: {
          stocks: recentSecurityArray
        }
      },
      limit,
      popularId: 'trading'
    });
  };
}

/**
 * 뉴스
 * @param limit
 * @returns {{limit: number}}
 */
export function fetchPopularNews(limit = 5) {
  return (dispatch) => {
    // dispatch(setSelectedPopularScope("news"));
    dispatch({
      [CALL_API]: {
        types: [REQUEST_POPULAR_STOCKS, RECEIVE_POPULAR_STOCKS, FAILURE_POPULAR_STOCKS],
        config: {
          method: 'get',
          url: Routes.news_tagging_api_popular_stocks_path({ limit, format: 'json' })
        },
        schema: {
          stocks: recentSecurityArray
        }
      },
      limit,
      popularId: 'news'
    });
  };
}

/**
 * 토론
 * @param limit
 * @returns {{limit: number}}
 */
export function fetchPopularPosts(limit = 5) {
  return (dispatch) => {
    // dispatch(setSelectedPopularScope("posts"));
    dispatch({
      [CALL_API]: {
        types: [REQUEST_POPULAR_STOCKS, RECEIVE_POPULAR_STOCKS, FAILURE_POPULAR_STOCKS],
        config: {
          method: 'get',
          url: Routes.posts_count_api_popular_stocks_path({ limit, format: 'json' })
        },
        schema: {
          stocks: recentSecurityArray
        }
      },
      limit,
      popularId: 'posts'
    });
  };
}

/**
 * 조회
 * @param limit
 * @returns {{limit: number}}
 */
export function fetchPopularStockSearch(limit = 5) {
  return (dispatch) => {
    // dispatch(setSelectedPopularScope("stockSearch"));
    dispatch({
      [CALL_API]: {
        types: [REQUEST_POPULAR_STOCKS, RECEIVE_POPULAR_STOCKS, FAILURE_POPULAR_STOCKS],
        config: {
          method: 'get',
          url: Routes.stock_search_api_popular_stocks_path({ limit, format: 'json' })
        },
        schema: {
          stocks: recentSecurityArray
        }
      },
      limit,
      popularId: 'stockSearch'
    });
  };
}
