/* @flow */
import { createSelector } from 'reselect';

/**
 * props에서 key query parameter 가져오기
 * @param  {Object} _     [Unused]
 * @param  {Object} props
 * @return {String}
 */
export function queryKeySelector(state: Object, props: Object): string {
  return props.location.query.key;
}

/**
 * state에서 trend를 가져오기
 * @param  {Object} state
 * @return {Object}
 */
export const trendSelector = (state: Object): Object => state.trend || {};

/**
 * state -> trend에서 specialStocksByMarket을 가져오기
 * @param  {Object} state
 * @return {Object}
 */
export const specialStocksByMarketSelector = createSelector(
  [trendSelector],
  (trend): Object => (trend.specialStocksByMarket || {})
);

/**
 * specialStocksByMarket[market]를 가져오는 selector를 만들기 위한 factory
 * @param  {String} market [KOSPI | KOSDAQ]
 * @return {Selector}
 */
const specialStocksByMarketSelectorFactory = market =>
  createSelector(
    [specialStocksByMarketSelector],
    specialStock => (specialStock[market] || {})
  );

/**
 * specialStocksByMarket[market][key]를 가져오는 selector를 만들기 위한 factory
 * @param  {String} market [KOSPI | KOSDAQ]
 * @param  {String} key    [top_limit | bottom_limit | ...]
 * @return {Selector}
 */
const specialStocksByMarketAndKeySelectorFactory = (market, key) =>
  createSelector(
    [specialStocksByMarketSelectorFactory(market), () => key],
    (specialStock, queryKey) => (specialStock[queryKey] || {})
  );

/**
 * specialStock에서 topStocks를 가져오기
 * @param  {SpecialStock} specialStock
 * @return {Object}
 */
// const topStocksSelector = (specialStock) => specialStock.topStocks || [];

/**
 * specialStock에서 stocksCount를 가져오기
 * @param  {SpecialStock} specialStock
 * @return {Number}
 */
const stocksCountSelector = (specialStock: Object): number => specialStock.stocksCount || 0;

/**
 * market, key로 special stock 종목 수 가져오기
 * @param  {String} market
 * @param  {String} key
 * @return {Number}
 */
const specialStocksCountByMarketSelectorFactory = (market, key) =>
  createSelector(
    [specialStocksByMarketAndKeySelectorFactory(market, key)],
    stocksCountSelector
  );

/**
 * key로 코스피, 코스닥 special stock 종목 수의 합 가져오기
 * @param  {String} key
 * @return {Number}
 */
const totalSpecialStocksCountByKeySelectorFactory = (key: string) =>
  createSelector([
    specialStocksCountByMarketSelectorFactory('kospi', key),
    specialStocksCountByMarketSelectorFactory('kosdaq', key)
  ], (kospi, kosdaq) => kospi + kosdaq);

/**
 * 상한가 종목 수 총합
 */
export const topLimitStocksCountSelector = totalSpecialStocksCountByKeySelectorFactory('top_limit');

/**
 * 하한가 종목 수 총합
 */
export const bottomLimitStocksCountSelector = totalSpecialStocksCountByKeySelectorFactory('bottom_limit');

/**
 * 신고가 종목 수 총합
 */
export const high52weekStocksCountSelector = totalSpecialStocksCountByKeySelectorFactory('high_52week');

/**
 * 신저가 종목 수 총합
 */
export const low52weekStocksCountSelector = totalSpecialStocksCountByKeySelectorFactory('low_52week');

/**
 * 변동성 완화 종목 수 총합
 */

export const viStocksCountSelector = totalSpecialStocksCountByKeySelectorFactory('vi');

/**
 * 종목 수 요약
 */
export const specialStocksCountSummarySelector = createSelector([
  topLimitStocksCountSelector,
  bottomLimitStocksCountSelector,
  high52weekStocksCountSelector,
  low52weekStocksCountSelector,
  viStocksCountSelector
], (topLimitStocksCount, bottomLimitStocksCount, high52weekStocksCount, low52weekStocksCount, viStocksCount) => ({
  topLimitStocksCount,
  bottomLimitStocksCount,
  high52weekStocksCount,
  low52weekStocksCount,
  viStocksCount
}));

/**
 * state -> trend에서 marketCondition을 가져오기
 * @param  {Object} state
 * @return {Object}
 */
export const marketConditionSelector = createSelector(
  [trendSelector],
  (trend): Object => (trend.marketCondition || null)
);


/**
 * 해외지수 목록
 */
export const foreignIndexesSelector = createSelector(
  [trendSelector],
  (trend): Object => (trend.foreignIndexes || null)
);


/**
 * 해외 대표지수 목록
 */
export const majorIndexesSelector = createSelector(
  [foreignIndexesSelector],
  (indexes): Object => (indexes && indexes.majorIndexes || [])
);

/**
 * 아시아지수 목록
 */
export const asiaIndexesSelector = createSelector(
  [foreignIndexesSelector],
  (indexes): Object => (indexes && indexes.asiaIndexes || [])
);

/**
 * 미국지수 목록
 */
export const americaIndexesSelector = createSelector(
  [foreignIndexesSelector],
  (indexes): Object => (indexes && indexes.americaIndexes || [])
);

/**
 * 유럽지수 목록
 */
export const europeIndexesSelector = createSelector(
  [foreignIndexesSelector],
  (indexes): Object => (indexes && indexes.europeIndexes || [])
);
