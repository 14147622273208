/* @flow */
import { createSelector } from 'reselect';
import { recentViewStocksUiSelector, historySnapshotSelector } from './ui_selectors';

export const recentViewStocksSelector = createSelector([
  recentViewStocksUiSelector
], recentViewStocks => (recentViewStocks.list || []));

export const recentHistorySelector = createSelector(
  recentViewStocksSelector,
  historySnapshotSelector,
  (recentViewStocks, historySnapshot) => (
    recentViewStocks.length > historySnapshot.length
      ? recentViewStocks
      : historySnapshot
  )
);

export const recentViewAssetsSelector = createSelector([
  recentViewStocksUiSelector
], recentViewStocks => (recentViewStocks.list || []));
