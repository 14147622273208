import React from 'react';
import { Route } from 'react-router';
import { loadComponent, enableStylesheet, disableStylesheet } from '../helper';

const assetsPath = (
  <Route path="m">
    <Route
      path="assets/:assetId"
      onEnter={enableStylesheet}
      onLeave={disableStylesheet}
      getComponent={(location, cb) =>
        import('../../containers/assets/show').then(loadComponent(cb))
      }
    />
  </Route>
);

export default assetsPath;
