import {
  USE_SERVICE_ALL_AGREEMENT,
  USE_SERVICE_AGE_AGREEMENT,
  USE_PRIVATE_COLLECT_AGREEMENT,
  USE_SERVICE_AGREEMENT
} from '../actions/agreement.jsx';

export function agreementReducer(state = {
  isUseServiceAllAgreement: false,
  isUseServiceAgeAgreement: false,
  isUseServiceAgreement: false,
  isUsePrivateCollectAgreement: false
}, action = {}) {
  switch (action.type) {
  case USE_SERVICE_ALL_AGREEMENT:
    return Object.assign({}, state, {
      isUseServiceAllAgreement: !state.isUseServiceAllAgreement,
      isUseServiceAgeAgreement: !state.isUseServiceAllAgreement,
      isUseServiceAgreement: !state.isUseServiceAllAgreement,
      isUsePrivateCollectAgreement: !state.isUseServiceAllAgreement
    });

  case USE_SERVICE_AGE_AGREEMENT:
    return Object.assign({}, state, {
      isUseServiceAgeAgreement: !state.isUseServiceAgeAgreement
    });

  case USE_SERVICE_AGREEMENT:
    return Object.assign({}, state, {
      isUseServiceAgreement: !state.isUseServiceAgreement
    });

  case USE_PRIVATE_COLLECT_AGREEMENT:
    return Object.assign({}, state, {
      isUsePrivateCollectAgreement: !state.isUsePrivateCollectAgreement
    });

  default:
    return state;
  }
}
