import { assign } from 'lodash';
import { UPDATE_LOCATION } from 'react-router-redux';
import { REQUEST_PAGE, RECEIVE_PAGE } from '../actions/page';

const initialState = {
  shouldUpdate: true,
  props: {}
};

export function pageReducer(state = initialState, action) {
  switch (action.type) {
  case UPDATE_LOCATION:
    return assign({}, state, {
      shouldUpdate: true
    });
  case REQUEST_PAGE:
    return state;
  case RECEIVE_PAGE:
    return assign({}, state, {
      shouldUpdate: false,
      props: assign({}, state.props, action.payload)
    });
  default:
    return state;
  }
  return state;
}
