import { web2app, userAgent } from './web2app';
import { keys } from 'lodash';
import { KAKAOSTOCK_SCHEME, KAKAOSTOCK_PKG_NAME, KAKAOSTOCK_ITUNES_URL } from '../../constants/scheme';

export default class Scheme {
  constructor() {
    this.ua = userAgent();
    this.web2app = web2app();
  }

  /**
   * User agent object를 리턴하는 함수
   * @returns {*|{ua, browser, platform, os, app}|{ua: (string|*), browser: *, platform: *, os: *, app: *}}
   */
  get userAgent() {
    return this.ua;
  }

  /**
   * Platform을 리턴하는 함수
   * @returns {string}
   */
  get platform() {
    return this.ua.platform;
  }

  /**
   * Custom Scheme 기반 url을 리턴하는 함수
   * @param scheme {string} 스킴 default 값은 stockplus
   * @param destination {string} 호스트와 파라미터의 조합으로 되어 있는 파라미터 ex) mainMenu?selectedTab=favorite
   * @returns {string}
   */
  getCustomScheme(scheme, destination) {
    return `${scheme}://${destination}`;
  }

  /**
   * Android Intent Uri를 리턴하는 함수
   * @param scheme {string} 스킴 default 값은 stockplus
   * @param destination {string} 호스트와 파라미터의 조합으로 되어 있는 파라미터 ex) mainMenu?selectedTab=favorite
   * @param pkgName {string} 플레이스토어에서 사용되는 유니크한 패키지명
   * @returns {string}
   */
  getIntentScheme(scheme, destination, pkgName) {
    return `intent://${destination}#Intent;scheme=${scheme};package=${pkgName};end`;
  }

  /**
   * 증플의 특정 화면을 의미하는 주소를 리턴하는 함수
   * @param host {string} 호스트 주소
   * @param params {object} key와 value로 이루어진 파라미터
   * @param encoding {boolean} value 값 인코딩 여부
   * @returns {string}
   */
  getDestination(host, params = {}, encoding = false) {
    // TODO host, params validation check
    const queryParams = keys(params).filter((key) => {
      if (key === 'utmSource') {
        return false;
      }
      return true;
    }).map(key => `${key}=${encoding ? encodeURIComponent(params[key]) : params[key]}`).join('&');
    return `${host}?${queryParams}`;
  }

  getAppStoreUrl(pkgName, itunesUrl, utmSource = 'kakaostock') {
    return this.ua.os.android ? `market://details?id=${pkgName}&referrer=utm_source%3D${utmSource}` : itunesUrl;
  }

  /**
   * platform에 따라 web에서 app으로 보내는 함수
   * @param host {string} 호스트 주소
   * @param params {object} key와 value로 이루어진 파라미터
   * @param scheme {string} 스킴 default 값은 stockplus
   * @param pkgName {string} 플레이스토어에서 사용되는 유니크한 패키지명
   * @param itunesUrl {string} 아이튠즈 주소
   * @param universalLink {string} 유니버셜 링크
   */
  warpTo(host, params = {}, scheme = KAKAOSTOCK_SCHEME, pkgName = KAKAOSTOCK_PKG_NAME, itunesUrl = KAKAOSTOCK_ITUNES_URL, universalLink = undefined) {
    this.web2app({
      urlScheme: this.getCustomScheme(scheme, this.getDestination(host, params)),
      intentURI: this.getIntentScheme(scheme, this.getDestination(host, params), pkgName),
      storeURL: this.getAppStoreUrl(pkgName, itunesUrl, params.utm_source),
      universalLink,
      appName: '증권플러스',
      onUnsupportedEnvironment() {
        console.log('해당 기능이 지원되지 않는 브라우저입니다.');
      }
    });
  }

  oneLinkWarpTo(oneLinkSubdomain, params) {
    const deeplink_path = (this.getDestination('', params, true));
    window.location.href = `https://${oneLinkSubdomain}${deeplink_path}`;
  }
}
