/* @flow */
import { combineReducers } from 'redux';
import { filter, xor, union, difference } from 'lodash';
import { SELECT_RECENT_VIEW_STOCK, REMOVE_RECENT_VIEW_STOCKS, CLEAR_SELECTED_RECENT_VIEW_STOCKS } from '../../actions/recent_view_stocks';
import { ADD_RECENT_VIEW_ASSET } from '../../actions/recent_view_assets';
import fetchActionTypes from '../../constants/fetch_actions';
import type { Action } from '../../types/action';

export const LOCALSTORAGE_INIT = 'redux-localstorage/INIT';

function selectedStocks(state = [], action: Action) {
  switch (action.type) {
  case SELECT_RECENT_VIEW_STOCK:
    // 원래 선택되었던 것들과 새로 선택된 것의 대칭차집합을 구한다
    return xor(state, [action.stock]);
  case REMOVE_RECENT_VIEW_STOCKS:
    return [];
  case CLEAR_SELECTED_RECENT_VIEW_STOCKS:
    return [];
  default:
    return state;
  }
}

function validateSecurityId(securityId: ?string): boolean {
  if (typeof securityId !== 'string') {
    return false; 
  }
  const segments = securityId.split('-');
  return segments.length === 2;
}

function list(state: Array<string> = [], action: Action) {
  let unionedState;
  switch (action.type) {
  case LOCALSTORAGE_INIT:
    return filter(state, validateSecurityId);
  case REMOVE_RECENT_VIEW_STOCKS:
    return difference(state, action.securityIds);
  case fetchActionTypes.RECENT_SECURITY.RECEIVE:
    if (!validateSecurityId(action.payload.result.recentSecurity)) {
      return state;
    }
    unionedState = union([action.payload.result.recentSecurity], state);
    if (unionedState.length > 30) {
      // 기존에 30개 넘게 가지고 있는 사람이 있었기 때문에 slice를 해준다. 그게 아니면 pop해주면 됨.
      return unionedState.slice(0, 30);
    }
    return unionedState;
  case ADD_RECENT_VIEW_ASSET:
    // fetchActionTypes.RECENT_SECURITY.RECEIVE 와 같은 로직
    if (!validateSecurityId(action.assetId)) {
      return state;
    }
    unionedState = union([action.assetId], state);
    if (unionedState.length > 30) {
      return unionedState.slice(0, 30);
    }
    return unionedState;
  default:
    return state;
  }
}

export const recentViewStocksReducer = combineReducers({
  selectedStocks,
  list
});
