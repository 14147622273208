import { REQUEST_HOLDER_DETAIL, SUCCESS_HOLDER_DETAIL } from '../actions/holders';

const initialHolderDetail = {};

export function holderDetailReducer (state = initialHolderDetail, action) {
  switch (action.type) {
  case REQUEST_HOLDER_DETAIL:
    return initialHolderDetail;
  case SUCCESS_HOLDER_DETAIL:
    return action.payload?.data || initialHolderDetail;
  default:
    return state;
  }
}
