import { createSelector } from 'reselect';
import fetchActionTypes from '../../constants/fetch_actions';
import { paginate } from '../paginate';
import { normalizedResultSelectorFactory } from '../../selectors/paginate_selectors';

const normalizedResultSelector = createSelector(
  normalizedResultSelectorFactory('dayForexes'),
  normalizedResult => normalizedResult
);

const dayForexesByAssetId = paginate(
  fetchActionTypes.DAY_FOREXES,
  normalizedResultSelector,
  action => (action.id)
);

export default dayForexesByAssetId;
