/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { requestWarp, gaWrapper } from '../../actions/scheme';
import { closeModal } from '../../actions/modals';
import Cookies from 'js-cookie';
import { EVENT_CATEGORY7, EVENT_ACTION12 } from '../../constants/analytics';

type Props = {
  closeModal: typeof closeModal,
  requestWarp: typeof requestWarp
};

type State = {
  expireAfter1Week: boolean
};

class DriverEventModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expireAfter1Week: false
    };
    this.handleGoApp = this._handleGoApp.bind(this);
    this.handleClickClose = this._handleClickClose.bind(this);
    this.handleClickCheckBox = this._handleClickCheckBox.bind(this);
  }

  state: State;
  props: Props;
  handleClickClose: () => void;
  handleGoApp: () => void;
  handleClickCheckBox: () => void;

  static style = {
    content: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: '1px solid #ccc',
      background: 'transparent',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '0px',
      outline: 'none',
      padding: 0,
      opacity: 1
    }
  };

  _handleClickClose(e: any) {
    const expires = this.state.expireAfter1Week ? 7 : 1;
    Cookies.set('kakaoDriverEvent', 'popped', { expires });
    this.props.closeModal();
    e.preventDefault();
  }

  _handleGoApp(e: any) {
    if (this.props.requestWarp) {
      this.props.requestWarp('mainMenu', { utmSource: 'kakao_driver' });
      Cookies.set('kakaoDriverEvent', 'popped', { expires: new Date(2016, 6, 8) });
      gaWrapper(EVENT_CATEGORY7, EVENT_ACTION12, 'btn');
    }
    e.preventDefault();
  }

  _handleClickCheckBox(e: any) {
    e.preventDefault();
    this.setState({ expireAfter1Week: !this.state.expireAfter1Week });
  }

  render() {
    return (
      <div className="event">
        <div className="dimmed" />
        <div className="mobile-event-layer-car">
          <h1 className="header">증권플러스 가입하고 ! <br /> 카카오드라이버타요~ <br /> <time dateTime="2016-06-23">6.23</time> - <time dateTime="2016-07-06">7.6</time></h1>
          <div className="content mobile-bg">
            <ol>
              <li>
                이벤트 하나
                <span>
                  증권플러스 신규가입 즉시 <br />
                  카카오드라이버 1만원 쿠폰 <b>무조건 지급!</b>
                </span>
              </li>
              <li>
                이벤트 둘
                <span>
                  추첨을 통해 100명에게는 <br />
                  <strong>5만원 쿠폰 추가 지급!</strong>
                </span>
              </li>
            </ol>
            <button className="coupon-download" onClick={this.handleGoApp}><span className="hide">앱 다운받고 쿠폰 받기</span></button>
          </div>
          <footer>
            <h2>옵션</h2>
            <div className="checkbox" onClick={this.handleClickCheckBox}>
              <input type="checkbox" id="week1" checked={this.state.expireAfter1Week} />
              <label htmlFor="week1">일주일간 보지 않기</label>
            </div>
            <button className="close" onClick={this.handleClickClose}>닫기</button>
          </footer>
        </div>
      </div>
    );
  }
}

export default connect(null, {
  requestWarp,
  closeModal
})(DriverEventModal);
