// @flow
import React from 'react';

type AnchorBtnProps = {
  children?: ?React$Node,
  onClick?: () => any
}

// A tag 이면서 button 처럼 쓰는 것
// 클릭 이벤트 제거
export class ABtn extends React.Component<AnchorBtnProps> {
  handleClick = (e) => {
    const { onClick } = this.props;
    e && e.preventDefault();
    onClick && onClick(e);
  };

  render() {
    const { children, onClick, ...rest } = this.props; // eslint-disable-line

    return (
      <a href="" onClick={ this.handleClick } { ...rest }>
        { children }
      </a>
    );
  }
}