/* @flow */
export const TOGGLE_SHOW_CHART_IMAGE = 'TOGGLE_SHOW_CHART_IMAGE';
export function toggleShowChartImage(): { type: string } {
  return {
    type: TOGGLE_SHOW_CHART_IMAGE
  };
}

export const RESET_MY_UI = 'RESET_MY_UI';
export function resetMyUi(): { type: string } {
  return {
    type: RESET_MY_UI
  };
}
