/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import classNames from 'classnames';

import { REPORT_TYPES, REPORTABLE_TYPE_POST, REPORTABLE_TYPE_COMMENT } from '../../constants/report';
import { POST_REPORT_RADIO_BUTTON_NAME, COMMENT_REPORT_RADIO_BUTTON_NAME } from '../../constants/radio_buttons';

import { closeModal } from '../../actions/modals';
import { report } from '../../actions/report';
import { clickRadioButton, resetRadioButton } from '../../actions/radio_buttons';

import { radioButtonByNameSelector } from '../../selectors/ui_selectors';

type Props = {
  selectedItemIdx: number,
  report: typeof report,
  closeModal: typeof closeModal,
  clickRadioButton: typeof clickRadioButton,
  resetRadioButton: typeof resetRadioButton,
  id: number,
  reportableType: string
};

class ReportModal extends React.Component {
  props: Props;

  static defaultProps = {
    selectedItemIdx: -1
  };

  static style = {
    content: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: 0
    }
  };

  resetButtonBeforeClose() {
    const reportableType = this.props.reportableType;
    if (reportableType === REPORTABLE_TYPE_COMMENT) {
      this.props.resetRadioButton(COMMENT_REPORT_RADIO_BUTTON_NAME);
    } else if (reportableType === REPORTABLE_TYPE_POST) {
      this.props.resetRadioButton(POST_REPORT_RADIO_BUTTON_NAME);
    }
  }

  handleClickListItem(idx: number, e: Object) {
    const reportableType = this.props.reportableType;

    if (reportableType === REPORTABLE_TYPE_COMMENT) {
      if (idx === this.props.selectedItemIdx) {
        this.props.resetRadioButton(COMMENT_REPORT_RADIO_BUTTON_NAME);
      } else {
        this.props.clickRadioButton(COMMENT_REPORT_RADIO_BUTTON_NAME, idx);
      }
    } else if (reportableType === REPORTABLE_TYPE_POST) {
      if (idx === this.props.selectedItemIdx) {
        this.props.resetRadioButton(POST_REPORT_RADIO_BUTTON_NAME);
      } else {
        this.props.clickRadioButton(POST_REPORT_RADIO_BUTTON_NAME, idx);
      }
    }

    e.preventDefault();
  }

  componentWillUnmount() {
    this.resetButtonBeforeClose();
  }

  handleClickBack(e: Object) {
    this.props.closeModal();
    e.preventDefault();
  }

  reportListClassName(reportType: string): string {
    if (this.props.selectedItemIdx >= 0) {
      return classNames({ selected: reportType === REPORT_TYPES[this.props.selectedItemIdx] });
    }
    return '';
  }

  handleClickCancel(e: Object) {
    this.props.closeModal();
    e.preventDefault();
  }

  handleClickReport(e: Object) {
    const reportableType = this.props.reportableType;
    const content = this.lastItemIndex === this.props.selectedItemIdx ? this.refs.textarea.value : '';
    this.props.report(this.props.id, reportableType, REPORT_TYPES[this.props.selectedItemIdx], content);

    if (reportableType === REPORTABLE_TYPE_COMMENT) {
      this.props.resetRadioButton(COMMENT_REPORT_RADIO_BUTTON_NAME);
    } else if (reportableType === REPORTABLE_TYPE_POST) {
      this.props.resetRadioButton(POST_REPORT_RADIO_BUTTON_NAME);
    }
    this.props.closeModal();
    e.preventDefault();
  }

  handleClickTextArea(_e: Object) {
    const reportableType = this.props.reportableType;
    if (reportableType === REPORTABLE_TYPE_COMMENT) {
      this.props.clickRadioButton(COMMENT_REPORT_RADIO_BUTTON_NAME, this.lastItemIndex);
    } else if (reportableType === REPORTABLE_TYPE_POST) {
      this.props.clickRadioButton(POST_REPORT_RADIO_BUTTON_NAME, this.lastItemIndex);
    }
  }

  // $FlowFixMe: get/set properties not yet supported
  get lastItemIndex() {
    return REPORT_TYPES.length - 1;
  }

  render() {
    return (
      <div>
        <header>
          <div className="topDef line">
            <h2><a href="#">신고하기</a></h2>
            <span className="btnB">
              <a href="#" className="btnPrev" title="이전" onClick={this.handleClickBack.bind(this)}>이전</a>
            </span>
          </div>
        </header>
        <main>
          <div className="reportList">
            <ul className="f_clear">
              {REPORT_TYPES.map((reportType, i) => (
                <li key={reportType}>
                  <a href="#" className={this.reportListClassName(reportType)} onClick={this.handleClickListItem.bind(this, i)}>
                    {reportType}
                  </a>
                </li>
              ))}
            </ul>
            <div className="txtInp">
              <textarea disabled={this.props.selectedItemIdx !== this.lastItemIndex} onClick={this.handleClickTextArea.bind(this)} ref="textarea" placeholder="기타 사항 입력 (최대 500자)" />
            </div>
            <div className="btnB">
              <a href="#" className="btnCancel" title="취소" onClick={this.handleClickCancel.bind(this)}>취소</a>
              <a href="#" className="btnReport" title="신고" onClick={this.handleClickReport.bind(this)}>신고</a>
            </div>
          </div>
        </main>
      </div>
    );
  }
}

const nameSelector = (_, props) => (
  props.reportableType === REPORTABLE_TYPE_COMMENT ? COMMENT_REPORT_RADIO_BUTTON_NAME : POST_REPORT_RADIO_BUTTON_NAME
);

const selector = createSelector(
  radioButtonByNameSelector(nameSelector),
  (radioButton) => {
    return {
      selectedItemIdx: radioButton
    };
  }
);

export default connect(selector, {
  report,
  closeModal,
  clickRadioButton,
  resetRadioButton
})(ReportModal);
