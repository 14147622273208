/* @flow */
import { CALL_API } from '../middlewares/api';
import { Routes } from '../routes';
import { favoriteStock, favoriteStockArray } from '../schemas/favorite_stock';
import { favoriteStockExistence, favoriteStockExistenceArray } from '../schemas/favorite_stock_existence';

export const REQUEST_ADD_FAVORITE_STOCK = 'REQUEST_ADD_FAVORITE_STOCK';
export const SUCCESS_ADD_FAVORITE_STOCK = 'SUCCESS_ADD_FAVORITE_STOCK';
export const FAILURE_ADD_FAVORITE_STOCK = 'FAILURE_ADD_FAVORITE_STOCK';

/**
 * 관심종목 리스트에 종목 추가
 * @param {Number} favoriteStockListId
 * @param {String} shortCode
 */
export function addFavoriteStock(favoriteStockListId: number, securityId: string): any {
  return {
    [CALL_API]: {
      types: [REQUEST_ADD_FAVORITE_STOCK, SUCCESS_ADD_FAVORITE_STOCK, FAILURE_ADD_FAVORITE_STOCK],
      config: {
        method: 'post',
        url: Routes.api_favorite_stock_list_favorite_stocks_path(favoriteStockListId, { format: 'json' }),
        data: {
          security_id: securityId
        }
      },
      schema: {
        favoriteStock
      }
    },
    favoriteStockListId,
    securityId
  };
}

export const SELECT_FAVORITE_STOCK = 'SELECT_FAVORITE_STOCK';
export function selectFavoriteStock(id: number): any {
  return {
    type: SELECT_FAVORITE_STOCK,
    id
  };
}

export const CLEAR_SELECTED_FAVORITE_STOCKS = 'CLEAR_SELECTED_FAVORITE_STOCKS';
export function clearSelectedFavoriteStocks(): Object {
  return {
    type: CLEAR_SELECTED_FAVORITE_STOCKS
  };
}

export const REQUEST_FAVORITE_STOCKS = 'REQUEST_FAVORITE_STOCKS';
export const RECEIVE_FAVORITE_STOCKS = 'RECEIVE_FAVORITE_STOCKS';
export const FAILURE_FAVORITE_STOCKS = 'FAILURE_FAVORITE_STOCKS';

export function fetchFavoriteStocks(favoriteStockListId: number): any {
  return {
    [CALL_API]: {
      types: [REQUEST_FAVORITE_STOCKS, RECEIVE_FAVORITE_STOCKS, FAILURE_FAVORITE_STOCKS],
      config: {
        method: 'get',
        url: Routes.mobile_favorite_stock_list_path(favoriteStockListId)
      }
    },
    favoriteStockListId
  };
}

export const REQUEST_DELETE_FAVORITE_STOCKS = 'REQUEST_DELETE_FAVORITE_STOCKS';
export const SUCCESS_DELETE_FAVORITE_STOCKS = 'SUCCESS_DELETE_FAVORITE_STOCKS';
export const FAILURE_DELETE_FAVORITE_STOCKS = 'FAILURE_DELETE_FAVORITE_STOCKS';

export function deleteFavoriteStocks(favoriteStockListId: number, ids: Array<number>): any {
  return {
    [CALL_API]: {
      types: [REQUEST_DELETE_FAVORITE_STOCKS, SUCCESS_DELETE_FAVORITE_STOCKS, FAILURE_DELETE_FAVORITE_STOCKS],
      config: {
        method: 'delete',
        url: Routes.destroy_api_favorite_stocks_path({ format: 'json' }),
        data: {
          ids
        }
      },
      schema: {
        favoriteStocks: favoriteStockArray
      }
    },
    favoriteStockListId,
    ids
  };
}

export const REQUEST_REORDER_FAVORITE_STOCKS = 'REQUEST_REORDER_FAVORITE_STOCKS';
export const SUCCESS_REORDER_FAVORITE_STOCKS = 'SUCCESS_REORDER_FAVORITE_STOCKS';
export const FAILURE_REORDER_FAVORITE_STOCKS = 'FAILURE_REORDER_FAVORITE_STOCKS';

export function reorderFavoriteStocks(favoriteStockListId: number, ids: Array<number>): any {
  return {
    [CALL_API]: {
      types: [REQUEST_REORDER_FAVORITE_STOCKS, SUCCESS_REORDER_FAVORITE_STOCKS, FAILURE_REORDER_FAVORITE_STOCKS],
      config: {
        method: 'post',
        url: Routes.reorder_api_favorite_stock_list_favorite_stocks_path(favoriteStockListId, { format: 'json' }),
        data: {
          ids
        }
      }
    },
    favoriteStockListId,
    ids
  };
}

export const REQUEST_FAVORITE_STOCKS_BY_SECURITY_ID = 'REQUEST_FAVORITE_STOCKS_BY_SECURITY_ID';
export const RECEIVE_FAVORITE_STOCKS_BY_SECURITY_ID = 'RECEIVE_FAVORITE_STOCKS_BY_SECURITY_ID';
export const FAILURE_FAVORITE_STOCKS_BY_SECURITY_ID = 'FAILURE_FAVORITE_STOCKS_BY_SECURITY_ID';

export function fetchFavoriteStocksBySecurityId(securityId: string): any {
  return {
    [CALL_API]: {
      types: [REQUEST_FAVORITE_STOCKS_BY_SECURITY_ID, RECEIVE_FAVORITE_STOCKS_BY_SECURITY_ID, FAILURE_FAVORITE_STOCKS_BY_SECURITY_ID],
      config: {
        method: 'get',
        url: Routes.api_security_favorite_stocks_path(securityId, { format: 'json' })
      },
      schema: {
        favoriteStocks: favoriteStockArray
      }
    },
    securityId
  };
}

export const REQUEST_FAVORITE_STOCK_EXISTENCE = 'REQUEST_FAVORITE_STOCK_EXISTENCE';
export const RECEIVE_FAVORITE_STOCK_EXISTENCE = 'RECEIVE_FAVORITE_STOCK_EXISTENCE';
export const FAILURE_FAVORITE_STOCK_EXISTENCE = 'FAILURE_FAVORITE_STOCK_EXISTENCE';

export function fetchFavoriteStockExistence(securityId: string): any {
  return {
    [CALL_API]: {
      types: [
        REQUEST_FAVORITE_STOCK_EXISTENCE,
        RECEIVE_FAVORITE_STOCK_EXISTENCE,
        FAILURE_FAVORITE_STOCK_EXISTENCE
      ],
      config: {
        method: 'get',
        url: Routes.api_security_favorite_stock_existence_path(securityId, { format: 'json' })
      },
      schema: {
        favoriteStockExistence
      }
    },
    securityId
  };
}

export const REQUEST_FAVORITE_STOCK_EXISTENCES = 'REQUEST_FAVORITE_STOCK_EXISTENCES';
export const RECEIVE_FAVORITE_STOCK_EXISTENCES = 'RECEIVE_FAVORITE_STOCK_EXISTENCES';
export const FAILURE_FAVORITE_STOCK_EXISTENCES = 'FAILURE_FAVORITE_STOCK_EXISTENCES';

export function fetchFavoriteStockExistences(assetIds: Array<string>): any {
  return {
    [CALL_API]: {
      types: [
        REQUEST_FAVORITE_STOCK_EXISTENCES,
        RECEIVE_FAVORITE_STOCK_EXISTENCES,
        FAILURE_FAVORITE_STOCK_EXISTENCES
      ],
      config: {
        method: 'get',
        url: Routes.favorite_stock_existences_api_favorite_stocks_path({
          asset_ids: assetIds.join(','),
          format: 'json'
        })
      },
      schema: {
        favoriteStockExistences: favoriteStockExistenceArray
      }
    },
    assetIds
  };
}
