import { createInstance, Identify }  from '@amplitude/analytics-browser';
import { stockplusSDK } from '@dunamu-stock/stockplus-web-sdk';
import { AMPLITUDE_ENV } from '../constants/env';

const AMPLITUDE_ENV_KEY = 'env';
const SPACE = 2;

class Amplitude {
  _amplitudeBrowser = createInstance();
  _initialized = false;
  constructor() {}

  /**
   * @param {string} apiKey - 앰플리듀드 API 키
   */
  init(apiKey) {
    if (!stockplusSDK.userAgent?.stockplus) {
      this._amplitudeBrowser.init(apiKey);

      const identify = new Identify().set(AMPLITUDE_ENV_KEY, AMPLITUDE_ENV);
      this._amplitudeBrowser.identify(identify);
    }
    this._initialized = true;
  }

  /**
   * @param {string} event - 이벤트 명
   * @param {object} properties - 이벤트 속성
   */
  trackEvent(event, properties) {
    if (!this._initialized) {
      throw new Error('Amplitude is not initialized');
    }
    if (AMPLITUDE_ENV === 'local') {
      console.groupCollapsed(`Amplitude "${event}" 이벤트가 감지되었습니다.`);
      console.info(`event: "${event}"`);
      console.info(`properties: ${JSON.stringify(properties, null, SPACE)}`);
      console.groupEnd();
      return;
    }
    
    if (stockplusSDK.userAgent?.stockplus) {
      stockplusSDK.bridgeInterface?.postAmplitudeEvent({ ...properties, event });
    } else {
      this._amplitudeBrowser.track(event, properties);
    }
  }
}

export const amplitude = new Amplitude();
