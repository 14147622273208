/* eslint-disable */
const serviceName = '증권플러스';

export const terms = `증권플러스 이용약관 (2016. 04)

제 1 조 (목적)
이 약관은 두나무 주식회사(이하 "회사"라고 합니다)가 제공하는 인터넷과 모바일 어플리케이션 증권플러스 관련 제반 서비스의 이용과 관련하여 회원과 "회사" 간에 필요한 사항을 규정함을 목적으로 합니다.

제 2 조 (정의)
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
1. "서비스"란 구현되는 단말기(PC, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 회원이 이용할 수 있는 인터넷과 모바일 어플리케이션 증권플러스 서비스 및 이와 관련된 제반 서비스를 의미하여, 이하 “증권플러스”라고도 합니다.
2. "회원"이란 "서비스"에 접속하여 이 약관에 따라 "회사"와 이용계약을 체결하고, "회사"가 제공하는 "서비스"를 이용하는 고객을 말합니다.
3. “아이디(ID 또는 계정)”라 함은 회원의 식별과 “서비스”의 이용을 위하여 “회원”이 이용약관 및 개인정보처리방침에 동의한 후 “회사”가 부여한 문자 또는 숫자의 조합을 말합니다.
4. "닉네임"이란 "회원"의 "서비스" 이용을 위하여 "회원"이 이용약관 및 개인정보처리방침에 동의한 후 수집한 "회원"의 "닉네임(이름)"을 말합니다.
5. "게시물"이란 "회원"이 "서비스"를 이용하면서 "서비스"에 게시한 부호·문자·음성·음향·화상·동영상 등의 정보 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을 의미합니다.
6. "콘텐츠"라 함은 "회원"이 "증권플러스"를 이용할 수 있도록 "회사"가 제공하는 개별 또는 일체의 "서비스"를 의미합니다.
7. "오픈마켓"이라 함은 "오픈마켓 사업자"가 운영하는 어플리케이션 거래 사이트를 의미합니다.
8. "오픈마켓 사업자"라 함은 "서비스"의 설치, 결제 기능을 제공하는 전자상거래 업체를 의미합니다.
9. "결제업체"라 함은 신용카드, 휴대폰결제 등 "오픈마켓"에서 사용가능한 전자지급수단을 제공하는 업체를 말합니다.
10. "유료 결제"라 함은 "서비스"내에서 유료로 "콘텐츠" 등을 구매하기 위한 결제행위를 말합니다.
11. "유료 콘텐츠"라 함은 "유료 결제"를 통해 유료로 구매한 "콘텐츠"를 의미합니다.

제 3 조 (약관의 게시와 개정)
1. "회사"는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 "서비스" 내 또는 연결화면을 통하여 게시합니다.
2. "회사"는 필요한 경우 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
3. "회사"가 이 약관을 개정할 경우에는 개정내용과 적용일자를 명시하여 "서비스"에서 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, "회원"에게 불리한 변경의 경우에는 적용일자 30일 전부터 공지합니다.
4. "회사"가 전항에 따라 공지하면서 "회원"에게 적용일자 전일까지 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 "회원"이 명시적으로 거부의 의사표시를 하지 아니한 경우 "회원"이 개정약관에 동의한 것으로 봅니다.
5. "회원"은 개정 약관에 동의하지 않는 경우 적용일자 전일까지 "회사"에 거부의사를 표시하고 "서비스" 이용계약을 해지할 수 있습니다.

제 4 조 (약관의 해석)
1. "회사"는 이 약관 외에 별도의 운영정책을 둘 수 있습니다.
2. 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 운영정책, 이용안내, 관련법령에 따릅니다.

제 5 조 (이용계약 체결)
1. 이용계약은 "회원"이 되고자 하는 자(이하 "가입신청자"라고 합니다.)가 "회사"가 제공하는 약관의 내용에 대하여 동의를 한 다음 "회사"가 정한 절차에 따라 가입신청을 완료하고, "회사"가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
2. "회사"는 "가입신청자"의 신청에 대하여 아래 각 호에 해당하는 사유가 있는 경우에는 승낙을 하지 않을 수 있으며, 가입 이후에도 아래 각 호의 사유가 확인될 경우에는 승낙을 취소하거나 이용계약을 해지할 수 있습니다.
① "가입신청자"가 이 약관에 의하여 이전에 "회원"자격을 상실한 적이 있는 경우
② 실명이 아닌 명의 또는 타인의 명의를 이용한 경우
③ 허위 또는 잘못된 정보를 기재 또는 제공하거나, "회사"가 제시하는 내용을 기재하지 않은 경우
④ "가입신청자"의 귀책사유로 인하여 승인이 불가능하거나 기타 이 약관에서 규정한 제반 사항을 위반하며 신청하는 경우
⑤ 부정한 용도 또는 영리를 추구할 목적으로 “서비스”를 이용하고자 하는 경우
⑥ 관련법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우
⑦ 기타 이 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우 또는 “회사”가 합리적인 판단에 의하여 필요하다고 인정하는 경우
3. 이용계약의 성립 시기는 "회사"가 계정 생성 완료를 신청절차상에서 표시하거나 제17조의 방식에 따른 통지가 "가입신청자"에게 도달한 시점으로 합니다.

제 6 조 ("회원" 정보의 변경)
1. "회원"은 "서비스"를 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, "서비스" 관리를 위해 필요한 "아이디"는 수정이 불가능합니다.
2. "회원"은 회원가입신청 시 기재한 사항이 변경되었을 경우 "서비스"에서 직접 수정하거나 고객센터를 통하여 "회사"에 변경 사항을 통지하여야 합니다.
3. "회원"이 전항의 변경사항을 "회사"에 통지하지 않아 발생한 불이익에 대하여 "회사"는 책임을 지지 않습니다.

제 7 조 ("회원"의 닉네임 관리 의무)
1. "회사"는 "회원"의 "닉네임"이 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 "회사" 및 "회사"의 운영자로 오인할 우려가 있는 경우, 해당 "닉네임"의 이용을 제한할 수 있습니다.
2. "회원"은 비밀번호가 도용되거나 제3자가 사용하고 있음을 안 경우 이를 즉시 "회사"에 통지하고 "회사"의 안내에 따라야 합니다.
3. "회원"이 전항을 준수하지 않아 발생한 불이익에 대하여 "회사"는 책임을 지지 않습니다.

제 8 조 (개인정보의 보호)
"회사"는 정보통신망이용촉진및정보보호등에관한법률, 개인정보보호법 등 관련 법령이 정하는 바에 따라 "회원"의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 이용에 대해서는 관련 법령 및 "회사"의 개인정보처리방침이 적용됩니다. 다만, "회사"가 제작한 화면 이외의 링크된 화면 등에서는 "회사"의 개인정보처리방침이 적용되지 않습니다.

제 9 조 ("회사"의 의무)
1. "회사"는 관련 법령과 이 약관을 준수하며, 계속적이고 안정적으로 "서비스"를 제공하기 위하여 최선을 다하여 노력합니다.
2. "회사"는 "회원"이 안전하게 "서비스"를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖출 수 있으며, 개인정보처리방침을 공시하고 준수합니다.
3. "회사"는 "서비스" 이용과 관련하여 "회원"으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는 이를 처리하여야 하며, "서비스" 내 게시판, 전자우편 등을 통하여 "회원"에게 처리과정 및 결과를 전달할 수 있습니다.

제 10 조 ("회원"의 의무)
1. "회원"은 아래의 행위를 하여서는 안 됩니다.
① 타인의 정보도용
② "회사"가 게시한 정보의 변경
③ "회사"가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
④ "회사"와 기타 제3자의 저작권 등 지적재산권에 대한 침해
⑤ "회사" 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
⑥ 외설 또는 폭력적인 메시지, 화상, 음성, 허위사실, 기타 공서양속에 반하는 정보를 "서비스"에 공개 또는 게시하는 행위
⑦ "회사"의 사전 동의 없이 영리를 목적으로 "서비스"를 사용하는 행위
⑧ "회사"의 사전 승낙 없이 에이전트(Agent), 스크립트(Script), 스파이더(Spider), 스파이웨어(Spyware), 툴바(Toolbar) 등의 자동화된 수단, 기타 부정한 방법을 통하여 "서비스"에 접속하는 행위, 노출횟수 및 클릭횟수를 부정하게 생성하거나 증가시키는 행위, "서비스" 이용 신청을 하는 행위, "회사"의 서버에 부하를 야기하는 행위
⑨ 다른 "회원"의 개인정보 및 계정정보를 수집하는 행위
⑩ 기타 불법적이거나 부당한 행위
2. "회원"은 관계 법령, 이 약관, 이용안내 및 "서비스"와 관련하여 "회사"가 공지하거나 통지한 사항 등을 준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 하여서는 안 됩니다.

제 11 조 ("게시물"의 저작권)
1. "회원"이 "서비스" 내에 게시한 "게시물"의 저작권은 저작권법에 의하여 보호를 받으며, 적법한 절차와 방법으로 "회사"에 다음과 같이 사용할 수 있는 영구적인 라이선스를 제공합니다. 상기 라이선스의 사용 및 허용 범위는 아래와 같습니다.
① "서비스"의 운영, 향상, 개선, 신규 서비스 개발, 프로모션 등을 위하여 "게시물"을 사용, 편집, 저장, 복제, 수정, 공개, 전송, 공개적 실연, 공개적인 게시, 배포 또는 주식회사 카카오에 제공할 수 있는 권리
② "게시물"의 2차적 저작물 제작 및 배포할 수 있는 권리
③ "서비스" 홍보를 위한 목적으로 미디어, 통신사 등이 "게시물"의 내용을 보도, 방영하게 할 수 있는 권리
2. "회사"가 전항 이외의 방법으로 "회원"의 "게시물"을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 "회원"의 동의를 얻을 수 있습니다.
3. "회원"이 이용계약을 해지하거나 제19조에 의해 이용계약이 해지되는 경우에도 "서비스"의 운영 향상, 개선, 홍보 등 제1항에 규정된 목적 범위 내에서 본 라이선스는 존속됩니다.

제 12 조 ("게시물"의 이용권)
1. "회원"의 "게시물"을 임의로 무단 사용하여 발생하는 손실이나 기타의 문제는 전적으로 "회원" 개인의 판단에 따른 책임이며, "회사"는 이에 대하여 책임지지 않습니다.
2. "회원"은 타인의 초상권, 저작권 등 지적재산권 및 기타 권리를 침해하는 목적으로 "게시물"을 사용할 수 없으며, 만일 타인의 권리를 침해하는 행위로 인하여 발생하는 결과에 대한 모든 책임은 "회원" 본인에게 있습니다.
3. "회원"은 본 서비스에 게시된 "게시물"을 무단으로 상업적이거나 기타 개인적인 이익을 위한 용도로 사용할 수 없습니다.
4. "회원"은 본 서비스에 게시된 "게시물"을 무단 도용하여 "회사"에 피해를 입힌 경우, 법적인 절차에 따라 보상해야 합니다.

제 13 조 ("게시물"의 관리)
1. "회원"의 "게시물"은 타인의 저작권을 침해하거나 허위 사실을 내용으로 하여서는 아니됩니다. "회원"의 "게시물"이 정보통신망이용촉진및정보보호등에관한법률 및 저작권법 등 관련 법령에 위반되는 내용을 포함하는 경우, 권리자는 관련 법령이 정한 절차에 따라 해당 "게시물"의 게시중단 및 삭제 등을 요청할 수 있으며, "회사"는 관련 법령에 따라 조치를 취하여야 합니다.
2. "회사"는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나, 허위 사실로 판단되거나, 기타 회사 정책 및 관련 법령에 위반될 경우에는 관련 법령에 따라 해당 "게시물"에 대하여 게시거부나 삭제 등의 조치를 취할 수 있습니다. 다만, “회사”가 모든 "게시물"을 검토할 의무가 있는 것은 아닙니다.

제 14 조 (권리의 귀속)
1. "서비스"에 대한 저작권 및 지적재산권은 "회사"에 귀속됩니다.
2. "회사"는 "서비스"와 관련하여 "회원"에게 "회사"가 정한 이용조건에 따라 "서비스" 등을 이용할 수 있는 이용권만을 부여하며, "회원"은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.

제 15 조 ("서비스"의 제공 등)
1. "회사"는 "서비스"를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만 이러한 경우에는 그 내용을 사전에 공지합니다.
2. "서비스"는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
3. "회사"는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 합리적인 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 "회사"는 제17조에 따른 방법으로 "회원"에게 통지합니다. 다만, "회사"가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
4. "회사"는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 서비스제공화면에 공지한 바에 따릅니다.

제 16 조 ("서비스"의 변경)
1. "회사"는 안정적인 "서비스" 제공을 위하여 "서비스"의 내용, 운영상, 기술상 사항 등을 변경할 수 있습니다.
2. "회사"는 "서비스"를 변경할 경우 변경내용과 적용일자를 명시하여 사전에 공지합니다. 단, "회원"의 권리나 의무 및 "서비스"의 이용과 관련되는 실질적인 사항을 변경할 경우 적용일자 7일 전부터 공지하며, "회원"에게 불리한 변경의 경우 적용일자 30일 전부터 공지합니다.
3. "회원"은 "서비스" 변경에 동의하지 않을 경우 "회사"에 거부의사를 표시하고 이용계약을 해지할 수 있습니다.
4. "회사"는 "서비스" 제공과 관련한 회사 정책의 변경 등 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 "서비스"를 변경 또는 중단할 수 있으며, 이에 대해 관련 법령에 특별한 규정이 없는 한 "회원"에게 별도의 보상을 하지 않습니다.

제 17 조 ("회원"에 대한 통지)
1. "회사"가 "회원"에 대하여 통지를 하는 경우 이 약관에 별도의 규정이 없는 한 "회원"이 제공한 전자우편주소 등으로 할 수 있습니다.
2. "회사"는 "회원" 전체에 대하여 통지를 하는 경우 7일 이상 "서비스" 내 게시판에 게시함으로써 전항의 통지에 갈음할 수 있습니다.

제 18 조 (이용제한 등)
1. "회사"는 "회원"이 관련 법령 및 이 약관의 의무를 위반하거나 "서비스"의 정상적인 운영을 방해한 경우, 아래와 같이 경고, 일시정지, 이용계약해지 등으로 "서비스" 이용을 단계적으로 제한할 수 있습니다.

약관 위반 - 경고
경고 3회 누적 - 일시정지 (30일 간 로그인 제한)
일시정지 후 재경고 - 이용계약해지 (재가입 제한)

2. "회사"는 전항에도 불구하고, 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망이용촉진및정보보호등에관한법률을 위반한 불법통신 및 해킹, 악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련 법령을 위반한 경우에는 즉시 이용계약을 해지할 수 있습니다. 본 항에 따른 이용계약 해지 시 "서비스" 이용을 통해 획득한 모든 혜택이 소멸되며, "회사"는 이에 대해 별도로 보상하지 않습니다.
3. "회사"는 본 조의 이용제한 범위 내에서 제한의 조건 및 세부내용은 운영정책, 이용안내 등에서 정하는 바에 의합니다.
4. 본 조에 따라 "서비스" 이용을 제한하거나 이용계약을 해지하는 경우에는 "회사"는 제17조에 따른 방법으로 "회원"에게 통지합니다.
5. "회원"은 본 조에 따른 이용제한 등에 대해 "회사"가 정한 절차에 따라 이의신청을 할 수 있습니다. 이 때 이의가 정당하다고 "회사"가 인정하는 경우 "회사"는 즉시 "서비스"의 이용을 재개합니다.

제 19 조 (이용계약 해지)
1. "회원"은 언제든지 "서비스" 내 정보 관리 메뉴 또는 고객센터 등을 통하여 이용계약 해지를 신청할 수 있으며, "회사"는 관련 법령이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
2. "회사"는 "회원"에게 다음과 같은 사유가 발생할 경우 이용계약을 해지할 수 있습니다.
① 본 약관을 위반한 경우
② 관계 법령을 위반한 경우
③ 회사가 제공하는 "서비스"의 원활한 진행을 방해하는 행위를 하거나 시도한 경우
④ 기타 "회사"가 합리적인 판단에 기하여 "서비스"의 제공을 거부할 필요가 있다고 인정할 경우
3. 이용계약 해지가 완료되는 경우 관련 법령 및 개인정보처리방침에 따라 "회사"가 보유하여야 하는 정보를 제외한 모든 "회원"의 정보가 삭제됩니다.
4. 전항에도 불구하고 제2항에 따라 "회사"가 이용계약을 해지하는 경우 "회사"는 "회원"의 이의신청 접수 및 처리 등을 위하여 일정 기간 동안 "회원"의 정보를 보관할 수 있으며, 해당 기간이 경과한 후에 "회원"의 정보를 삭제합니다.

제 20 조 (책임제한)
1. 증권플러스에서 제공하는 데이터 및 분석자료는 투자를 위한 정보제공을 목적으로 하는 것이며, 투자 및 주식 거래를 목적으로 하지 않습니다. 따라서 증권플러스 및 정보제공자가 제공하는 정보는 오류가 있을 수 있고, 서비스가 지연될 수도 있으며, 이러한 정보의 오류나 서비스의 지연에 대해 "회사" 및 정보제공자는 어떠한 책임도 지지 않습니다.
2. 증권플러스에서 제공하는 서비스 및 서비스를 이용하여 얻은 정보에 따른 투자는 손실이 발생할 수 있으며, 이에 대한 최종 판단은 이용자 자신이 하여야 하고, 그에 따른 책임은 전적으로 이용자 자신에게 있으며, "회사"는 그로 인하여 발생하는 손해에 대해서 어떠한 책임도 지지 않습니다.
3. 증권플러스의 게시판에 게시된 정보나 의견, 자료 등은 "회사"와 아무런 관련이 없으며 게시물의 내용과 관련하여 발생한 법적 책임은 게시자 또는 이를 열람하는 이용자가 부담하여야 합니다. "회사"는 "회원"간 또는 "회원"과 제3자 간에 서비스를 매개하여 발생한 분쟁에 관여할 법적 의무가 없으며, 이와 관련하여 어떠한 책임도 지지 않습니다.
4. "회사"는 천재지변, 디도스(DDOS)공격, IDC장애, 기간통신사업자의 회선 장애 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.
5. "회사"는 "회원"의 귀책사유로 인한 "서비스" 이용의 장애에 대하여는 책임을 지지 않습니다.
6. "회사"는 "회원"이 "서비스"와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성, 적법성 등에 관하여는 책임을 지지 않습니다.
7. "회사"는 무료로 제공되는 "서비스" 이용과 관련하여 관련 법령에 특별한 규정이 없는 한 책임을 지지 않습니다.
8. "회원"이 "서비스"를 이용함에 있어 "회원" 본인이 행한 불법행위나 이 약관 위반행위로 인하여 회사가 해당 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 해당 "회원"은 자신의 책임과 비용으로 반드시 "회사"를 면책 시켜야 합니다.

제 21 조 (대금결제)
1. "유료 콘텐츠"의 가격 등은 "서비스" 내 상점 등에서 표시된 가격에 의하나, 외화 결제 시 환율 및 수수료 등으로 인하여 구매 시점의 예상 지불 금액과 실제 청구금액이 달라질 수 있습니다.
2. "회원"은 "오픈마켓 사업자" 또는 "결제업체" 등이 정하는 정책, 방법 등에 따라 결제금액을 납부해야 합니다.
3. 결제한도는 "회사" 및 "오픈마켓 사업자", "결제업체"의 정책, 정부의 방침 등에 따라 조정될 수 있습니다.

제 22 조 (청약철회)
1. "회원"이 구매하는 "유료 콘텐츠"는 청약철회가 가능한 "콘텐츠"와 청약철회가 불가능한 "콘텐츠"로 구분됩니다. 청약철회가 가능한 "콘텐츠"는 구매 후 7일 이내에 청약철회를 할 수 있으며, 이 기간이 경과한 “콘텐츠"이거나 전자상거래등에서의소비자보호에관한법률 등 기타 관련 법령에서 정한 청약철회 제한 사유에 해당하는 “콘텐츠"는 청약철회가 제한됩니다. 청약철회가 제한되는 “콘텐츠”는 제한되는 사실을 표시하거나 시험사용할 수 있는 무료 “콘텐츠”를 제공합니다.
2. 청약철회가 가능한 "유료 콘텐츠"의 경우에도 구매 후 7일이 지났거나 사용한 경우, 재화 등의 가치가 현저히 감소한 경우, 기타 "회원"의 청약철회가 제한될 수 있는 사유가 발생한 경우에는 청약철회가 제한될 수 있습니다.
3. "회사"가 "회원"에게 무료로 지급한 "콘텐츠(유,무료 콘텐츠 포함)", 선물 받은 "유료 콘텐츠" 등 "회원"이 직접 구매하지 않은 "콘텐츠"는 청약철회의 대상에서 제외됩니다.

제 23 조 (청약철회 등의 효과)
"회사"는 "회원"으로부터 청약철회의 신청을 받은 경우 3영업일 이내(수납확인이 필요한 경우 수납확인일로부터 3영업일 이내)에 대금을 환불합니다. 이 경우 "회사"가 환불을 지연한 때에는 그 지연기간에 대하여 전자상거래등에서의소비자보호에관한법률에서 정한 이율을 곱하여 산정한 지연이자를 지급합니다.

제 24 조 (과오금의 환불)
1. "회원"에게 과오금이 발생한 경우 환불이 이루어집니다. 결제과정에서 과오금이 발생하는 경우 원칙적으로는 "오픈마켓 사업자"에게 환불을 요청하여야 하나, "오픈마켓 사업자"의 정책, 시스템 상 환불 절차의 처리 지원이 가능한 경우, "회사"가 "오픈마켓 사업자"에게 필요한 환불절차의 이행을 요청할 수도 있습니다.
2. 환불은 "회원"이 결제한 방법과 동일한 방법으로 환불하는 것을 원칙으로 하되, 동일한 방법으로 환불이 불가능할 때에는 다른 방법으로 환불할 수 있습니다.
3. "회원"의 책임있는 사유로 과오금이 발생한 경우, 환불에 소요되는 수수료 등은 "회원"이 부담합니다.
4. 어플리케이션 다운로드 또는 네트워크 서비스를 이용하여 발생되는 요금(통화료, 데이터 통화료 등)은 환불대상에서 제외됩니다.

제 25 조 (“유료 콘텐츠”의 환불)
1. "유료 콘텐츠"와 관련하여 다음의 각 호와 같은 경우에 해당된다면, 고객센터를 통한 환불을 받을 수 있습니다.
① "유료 콘텐츠"를 구매하였으나, 구매한 "콘텐츠"를 이용할 수 있는 "서비스"가 전무하여 그에 대한 책임이 전적으로 "회사"에 있는 경우 (단, 사전에 공지된 "서비스" 점검의 경우 등 제외)
② 기타 소비자 보호를 위해 "회사"에서 따로 정하는 경우
2. "유료 콘텐츠"의 환불을 원하는 "회원"은 "회사"가 정한 절차를 통해 환불을 신청해야 하며, "회사"는 환불 신청이 적당한지 여부를 심사합니다.
3. "회사"가 "회원"의 환불 신청에 대하여 환불하는 것으로 결정한 경우, "회사"는 해당 "회원"이 환불 받을 수 있는 "유료 콘텐츠"의 잔액을 산출하여, 산출된 잔액 중 환불 수수료 10%를 제한 나머지 금액을 환불합니다. 단 본 조 제1항 제1호와 같이 "회사"의 귀책사유로 "서비스"를 이용하지 못한 경우, "회사"는 환불 수수료를 제하지 않습니다.
4. "회사"가 "회원"에게 무료로 지급한 "콘텐츠", 선물받은 "콘텐츠" 등 "회원"이 직접 유료로 구매하지 않은 "콘텐츠"는 환불대상에서 제외됩니다.

제 26 조 (준거법 및 재판관할)
1. "회사"와 "회원"간 발생한 분쟁에 대하여는 대한민국법을 준거법으로 합니다.
2. "회사"와 "회원"간 발생한 분쟁에 관한 소송은 서울중앙지방법원을 전속 관할로 합니다.

부칙

이 약관은 2017년 04월 21일부터 적용됩니다.`;

export const privacyPolicyEssential = `두나무 주식회사가 제공하는 ${serviceName} 서비스는 아래의 목적으로 개인정보를 수집 및 이용하며, 회원의 개인정보를 안전하게 취급하는데 최선을 다합니다.

필수정보
  - 항목: 카카오 이용자 이름, 프로필 사진, 친구목록, 생년, 이메일, 성별, 서비스 이용기록, 기기정보
  - 목적: 회원가입, 이용자 식별, 서비스 이용 및 분석, 민원처리, 주요사항 공지 및 연락, 법령상 의무 이행
  - 기간: 회원탈퇴 시 파기

더 자세한 내용에 대해서는 고객센터 ’${serviceName} 개인정보처리방침’을 참고하시길 바랍니다.`;


export const dunamuContentsTerms = `두나무 콘텐츠 중개 서비스 이용약관

제1조 목적
이 약관은 두나무 주식회사(이하 “회사”라 합니다)가 ${serviceName}에 부수된 제반 서비스 중 콘텐츠 중개 서비스를 이용자에게 제공함에 있어 회사와 이용자의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.

제2조 용어의 정의
이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
① “${serviceName}”라 함은 구현되는 단말기(PC, 휴대형단말기 등의 각종 유무선 장치를 포함)와 상관없이 회원이 이용할 수 있는 인터넷과 모바일 어플리케이션 ${serviceName} 서비스 및 이와 관련된 제반 서비스를 의미합니다.
② "콘텐츠 중개 서비스"라 함은 ${serviceName} 내에서 콘텐츠회사가 제공하는 콘텐츠를 회원이 구매하는 경우에 이를 용이하게 할 수 있도록 회사가 제공하는 통신판매 중개서비스를 의미합니다.
② “회원”이라 함은 ${serviceName} 서비스에 접속하여 “${serviceName} 이용약관”에 따라 회사와 이용계약을 체결하고, 회사가 제공하는 ${serviceName} 서비스를 이용하는 고객을 말하며 “${serviceName} 회원”이라고도 합니다.
④ "콘텐츠회사"라 함은 회사와 계약을 체결하여 콘텐츠 중개 서비스를 이용해 회원에게 콘텐츠를 판매하는 회사를 의미합니다.
⑤ “콘텐츠”라 함은 콘텐츠회사와 구매자가 “콘텐츠회사의 이용약관”에 따라 이용계약을 맺음으로써 콘텐츠회사가 구매자에게 제공하는 부호, 문자, 도형, 색채, 음선, 음향, 이미지, 영상 등(이들의 복합체를 포함한다) 온라인 디지털 형태의 정보나 자료를 말합니다.
⑥ “구매자”라 함은 콘텐츠회사와 콘텐츠 이용계약을 체결하고 콘텐츠회사가 제공하는 콘텐츠를 이용하는 ${serviceName} 회원을 의미합니다.
본 약관에 대해서는 본 조에서 정한 용어가 우선적으로 적용되며, 본 조에서 정의되지 않은 약관 상의 용어는 ${serviceName} 운영약관에 따릅니다.

제3조 약관의 게시와 개정
① 회사는 이 약관의 내용을 구매자가 쉽게 알 수 있도록 중개 대상이 되는 콘텐츠 이용 초기 화면 내 또는 연결화면을 통하여 게시합니다.
② 회사는 필요한 경우 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
③ 회사가 이 약관을 개정할 경우에는 개정 내용과 적용 일자를 명시하여 콘텐츠 이용 초기 화면에서 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 구매자에게 불리한 변경의 경우에는 적용일자 30일 전부터 공지합니다.
④ 회사가 전항에 따라 공지하면서 구매자에게 적용일자 전일까지 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지하였음에도 구매자가 명시적으로 거부의 의사표시를 하지 아니한 경우 구매자가 개정약관에 동의한 것으로 봅니다.
⑤ 구매자는 개정 약관에 동의하지 않는 경우 적용일자 전일까지 회사에 거부의사를 표시하고 콘텐츠 중개 서비스 이용계약을 해지할 수 있습니다.

제4조 콘텐츠 중개 서비스의 제공
① 회사는 통신판매중개자로서 구매자와 콘텐츠회사 간의 자유로운 콘텐츠의 거래를 위한 시스템을 운영 및 관리하고, 콘텐츠 관련 회원관리, 마케팅 및 결제 서비스를 제공합니다.
② 회사는 콘텐츠회사가 콘텐츠를 올려 판매할 수 있는 플랫폼을 제공하며, 직접적으로 콘텐츠를 생산하지 않습니다. 콘텐츠회사는 자신이 생산하여 플랫폼에 제공한 콘텐츠에 대한 저작권을 갖습니다. 따라서, ${serviceName}에 올려진 콘텐츠에 대한 책임은 콘텐츠를 생산해서 올린 콘텐츠회사에 있습니다.

제5조 회사의 의무
① 회사는 콘텐츠 중개자로서 안전하고 효율적인 서비스를 위한 시스템 운영 및 관리를 위하여 노력합니다.
② 회사는 각종 설비와 자료를 관리하고, 시스템이 제공 목적에 맞게 이용되고 있는지 확인합니다. 이 경우 구매자에게 이용 목적에 위반되는 부분이 있는 것으로 확인되면 사유의 소명을 요청할 수 있고, 주문취소 등 필요한 조치를 할 수 있습니다.
③ 회사는 서비스 관리자로서 콘텐츠를 통하여 제공하는 정보에 대해 정확성이나 신뢰성이 있는 정보를 제공하기 위하여 노력합니다.

제6조 회원의 의무
구매자는 상품을 구매하기 전에 반드시 콘텐츠회사가 사이트 내에 작성한 콘텐츠의 상세 내용과 거래의 조건을 정확하게 확인해야 합니다. 구매하려는 상품의 내용과 거래의 조건을 확인하지 않고 구매하여 발생한 구매자에게 귀책사유 있는 손실과 손해는 구매자 본인이 부담합니다.

제7조 개인정보보호
① 회사는 정보통신망이용촉진및정보보호등에관한법률, 개인정보보호법 등 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다.
② 개인정보의 보호 및 이용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다.
③ 다만, 회사가 제작한 화면 이외의 링크된 화면 등에서는 회사의 개인정보처리방침이 적용되지 않습니다.

제8조 책임제한
① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 콘텐츠를 제공할 수 없는 경우에 고의 또는 과실이 없는 한 구매자에게 발생한 손해에 대하여 책임을 지지 않습니다.
② 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로 제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
③ 회사는 서비스용 설비의 보수, 교체, 점검, 공사 등 서비스 운영에 필요한 상당한 사유로 발생한 손해에 대해 회사의 고의 또는 과실이 없는 한 책임이 면제됩니다.
④ 회사는 구매자의 귀책사유로 인한 콘텐츠 이용의 장애 또는 손해에 대하여 책임을 지지 않습니다.
⑤ 회사는 구매자의 컴퓨터 오류에 의해 손해가 발생한 경우, 또는 신상정보 및 결제정보 등을 부실하게 기재하여 손해가 발생한 경우 책임을 지지 않습니다.
⑥ 회사는 콘텐츠회사 및 정보제공자가 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여 회사의 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다.

제9조 약관의 해석
① 본 약관은 콘텐츠 중개 서비스의 제공에 한하여 우선하여 적용되는 약관으로, 본 약관에서 정하지 않은 사항이나 해석에 대해서는 ${serviceName} 운영약관에 따릅니다.
② 본 약관과 ${serviceName} 운영약관에서 정하지 아니한 사항이나 해석에 대해서는 기타 운영정책, 이용안내, 관련법령에 따릅니다.

부칙
(시행일) 본 약관은 2017년 8월 18일부터 시행됩니다.`;
