import React from 'react';
import { Route } from 'react-router';
import { loadComponent, requireAuth } from '../helper';

export default (
  <Route path="m">
    <Route
      path="settings" onEnter={requireAuth} getComponent={(location, cb) =>
        import('../../containers/my/settings_page').then(loadComponent(cb))
      }
    />
    <Route
      path="notices" getComponent={(location, cb) =>
        import('../../containers/notices/notices_container').then(loadComponent(cb))
      }
    />
    <Route
      path="customer_support" getComponent={(location, cb) =>
        import('../../containers/customer_support/customer_support_container').then(loadComponent(cb))
      }
    />
  </Route>
);
