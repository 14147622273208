/* @flow */
export const UPDATE_HISTORY_SNAPSHOT = 'UPDATE_HISTORY_SNAPSHOT';
export function updateHistorySnapshot(assetIds): any {
  return {
    type: UPDATE_HISTORY_SNAPSHOT,
    assetIds
  };
}

export const CLEAR_HISTORY_SNAPSHOT = 'CLEAR_HISTORY_SNAPSHOT';
export function clearHistorySnapshot(): any {
  return {
    type: CLEAR_HISTORY_SNAPSHOT
  };
}
