import { createSelector } from 'reselect';
import { commentsSelector } from './entities_selectors';

const commentBoxSelector = state => (state.comments.commentBox);

export const isReplyActivatedSelector = createSelector(
  commentBoxSelector,
  box => box.isReplyActivated
);

export const commentSelectorFactory = commentIdSelector => (
  createSelector(
    commentsSelector,
    commentIdSelector,
    (comments, commentId) => comments[commentId]
  )
);
