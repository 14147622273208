import { assign } from 'lodash';
import fetchActionTypes from '../../constants/fetch_actions';

const initialState = {};

export function topRankedSocialPostReducer(state = initialState, action) {
  switch (action.type) {
  case fetchActionTypes.SOCIAL_POSTS.RECEIVE:
    if (!action.isTopRankedSocialPosts) {
      return state;
    }

    /* eslint-disable no-case-declarations */
    const FIRST_INDEX = 0;
    const topRankedSocialPostId = action.payload.result.posts[FIRST_INDEX];
    /* eslint-enable no-case-declarations */
    if (!topRankedSocialPostId) {
      return state;
    }

    return assign({}, state, {
      [[
        action.securityId,
        action.sort,
        action.profitFilterType
      ]]: topRankedSocialPostId
    });
  default:
    return state;
  }
}
