import { SET_FONTSIZE_TYPE } from '../actions/preferences.jsx';
import { assign } from 'lodash';

export const initialState = {
  fontSizeType: 'size2' // size1, size2, size3
};

export function preferencesReducer(state = initialState, action) {
  switch (action.type) {
  case SET_FONTSIZE_TYPE: // 최근 검색 관심 목록
    return assign({}, state, {
      fontSizeType: action.fontSizeType
    });
  default:
    return state;
  }
}
