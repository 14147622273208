/* @flow */
export const SELECT_RECENT_VIEW_STOCK = 'SELECT_RECENT_VIEW_STOCK';
export function selectRecentViewStock(stock: string): Object {
  return {
    type: SELECT_RECENT_VIEW_STOCK,
    stock
  };
}

export const REMOVE_RECENT_VIEW_STOCKS = 'REMOVE_RECENT_VIEW_STOCKS';
export function removeRecentViewStocks(securityIds: Array<string>): Object {
  return {
    type: REMOVE_RECENT_VIEW_STOCKS,
    securityIds
  };
}

export const CLEAR_SELECTED_RECENT_VIEW_STOCKS = 'CLEAR_SELECTED_RECENT_VIEW_STOCKS';
export function clearSelectedRecentViewStocks(): Object {
  return {
    type: CLEAR_SELECTED_RECENT_VIEW_STOCKS
  };
}
