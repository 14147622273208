import React from 'react';
import { connect } from 'react-redux';
import { requestOneLinkWarp } from '../../actions/scheme.jsx';
import { BASE_LINK } from '../../constants/onelink';

import ga from 'react-ga';
import {
  EVENT_CATEGORY1,
  REALTIME_QUOTATION_EVENT_ACTION1,
  REALTIME_QUOTATION_EVENT_ACTION2,
  REALTIME_QUOTATION_EVENT_ACTION3,
  REALTIME_QUOTATION_EVENT_ACTION4,
  REALTIME_QUOTATION_EVENT_ACTION5,
  REALTIME_QUOTATION_EVENT_ACTION6,
  REALTIME_QUOTATION_EVENT_ACTION7,
  REALTIME_QUOTATION_EVENT_ACTION8,
  REALTIME_QUOTATION_EVENT_ACTION9,
  UNKNOWN_EVENT_ACTION
} from '../../constants/analytics';

export class RealTimeQuotationButtonFloatingContainer extends React.Component {
  handleClick(e) {
    e.preventDefault();

    const { shortCode } = { ...this.props.floatingProps };

    if (this.props.requestOneLinkWarp && shortCode) {
      this.props.requestOneLinkWarp(BASE_LINK, {
        pid: '다음금융',
        c: 'kakao_finance',
        af_adset: 'kakao_finance_mobile',
        af_ad: 'kakao_finance_mobile_price',
        af_dp: `stockplus://viewStock?code=${shortCode}`,
        tabIndex: 0,
        subTabIndex: 0,
        af_web_dp: 'https://stockplus-static.dunamu.com/daum/daum_setup/html/inSite.html'
      });

      let action = UNKNOWN_EVENT_ACTION;

      const regexp_array = [
        /stocks\/[^/]+/,
        /stocks\/[^/]+\/debates/,
        /stocks\/[^/]+\/articles/,
        /stocks\/[^/]+\/articles\/news/,
        /stocks\/[^/]+\/articles\/disclosures/,
        /stocks\/[^/]+\/quotations\/daily/,
        /stocks\/[^/]+\/quotations\/foreign/,
        /stocks\/[^/]+\/quotations\/trader/,
        /stocks\/[^/]+\/analysis/
      ];

      const event_name_array = [
        REALTIME_QUOTATION_EVENT_ACTION1,
        REALTIME_QUOTATION_EVENT_ACTION2,
        REALTIME_QUOTATION_EVENT_ACTION3,
        REALTIME_QUOTATION_EVENT_ACTION4,
        REALTIME_QUOTATION_EVENT_ACTION5,
        REALTIME_QUOTATION_EVENT_ACTION6,
        REALTIME_QUOTATION_EVENT_ACTION7,
        REALTIME_QUOTATION_EVENT_ACTION8,
        REALTIME_QUOTATION_EVENT_ACTION9
      ];

      regexp_array.forEach((regexp, index) => {
        if (location.href.match(regexp)) {
          action = event_name_array[index];
        }
      });

      if (process.env.NODE_ENV === 'production') {
        ga.event({ category: EVENT_CATEGORY1, action, label: shortCode });
      } else {
        console.groupCollapsed('Google Analytics 명령이 감지되었습니다.');
        console.info(`ga.event({ category: '${EVENT_CATEGORY1}', action: '${action}', label: '${shortCode}' });`);
        console.info('Google Analytics는 production 환경만 트래킹합니다.');
        console.groupEnd();
      }
    }
  }

  render() {
    return (
      <a href="#" className="btnPrice" title="위로" onClick={this.handleClick.bind(this)}>위로</a>
    );
  }
}

export default connect(
  state => state.ui.floatings, {
    requestOneLinkWarp
  }
)(RealTimeQuotationButtonFloatingContainer);
