/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { gaWrapper, requestOneLinkWarp } from '../../actions/scheme';
import { closeModal } from '../../actions/modals';
import { EVENT_ACTION11, EVENT_CATEGORY6 } from '../../constants/analytics';
import { BASE_LINK } from '../../constants/onelink';
import { securityFirmsSelector } from '../../selectors/security_firms_selector';

class OrderModal extends React.Component {
  props: {
    closeModal: typeof closeModal,
    requestOneLinkWarp: typeof requestOneLinkWarp,
    shortCode: string,
    securityFirms: Array,
  };

  handleClickClose(e: Object) {
    this.props.closeModal();
    e.preventDefault();
  }

  handleGoApp(e) {
    const { shortCode } = this.props || {};
    if (this.props.requestOneLinkWarp && shortCode) {
      this.props.requestOneLinkWarp(BASE_LINK, {
        pid: '다음금융',
        c: 'kakao_finance',
        af_adset: 'kakao_finance_mobile',
        af_ad: 'kakao_finance_mobile_order',
        af_dp: `stockplus://viewStock?code=${shortCode}`,
        tabIndex: 0,
        subTabIndex: 0,
        af_web_dp: 'https://stockplus-static.dunamu.com/daum/daum_setup/html/inSite.html'
      });
      gaWrapper(EVENT_CATEGORY6, EVENT_ACTION11, shortCode);
    }
    e.preventDefault();
  }

  renderSecurityFirms() {
    return (this.props?.securityFirms || []).map(firm => (
      <li key={firm.key}>{firm.name}</li>
    ));
  }

  render() {
    return (
      <div className="order-area">
        <div className="order_layer" style={{ display: 'block' }}>
          <div className="order_layer_inner">
            <h2 className="head">증권플러스 앱으로 주문하기
              <a href="#" onClick={this.handleClickClose.bind(this)} className="close" title="닫기">X</a></h2>
            <div className="body">
              <p>증권플러스 앱을 설치해두면, 앱으로 바로<br />이동해 실시간 호가를 보며 거래할 수 있습니다.</p>
              <div className="gray_box">
                <h3>거래 지원 중인 증권사</h3>
                <ul>
                  { this.renderSecurityFirms() }
                </ul>
              </div>
              <div className="desc">거래 시 증권사 수수료 외 별도의 추가 수수료가 없습니다.</div>
            </div>
            <button className="install" onClick={this.handleGoApp.bind(this)}><span>증권플러스 앱 설치</span></button>
          </div>
        </div>
      </div>
    );
  }
}

const selector = createSelector(
  securityFirmsSelector,
  (securityFirms) => {
    return {
      securityFirms
    };
  }
);

export default connect(selector, {
  requestOneLinkWarp,
  closeModal
})(OrderModal);
