import { Routes } from '../routes';
import { CALL_API } from '../middlewares/api';
import fetchActionTypes from '../constants/fetch_actions';

export const RECEIVE_MAJOR_FOREX_IDS = 'RECEIVE_MAJOR_FOREX_IDS';
export function receiveMajorForexIds() {
  return {
    [CALL_API]: {
      types: fetchActionTypes.MAJOR_FOREX_IDS,
      config: {
        method: 'get',
        url: Routes.major_forex_ids_api_assets_path({ format: 'json' })
      }
    }
  };
}

export function requestMajorForexIds() {
  return (dispatch) => {
    return dispatch(receiveMajorForexIds());
  };
}
