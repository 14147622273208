// @flow
export const ADD_RECENT_VIEW_ASSET = 'ADD_RECENT_VIEW_ASSET';
function addRecentViewAsset(assetId: string): any {
  return {
    type: ADD_RECENT_VIEW_ASSET,
    assetId
  };
}

export function promisedAddRecentViewAsset(assetId: string): any {
  return dispatch => Promise.resolve(dispatch(addRecentViewAsset(assetId)));
}
