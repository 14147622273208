import { CALL_API } from '../middlewares/api';

export const REQUEST_HOLDER_DETAIL = 'REQUEST_HOLDER_DETAIL';
export const SUCCESS_HOLDER_DETAIL = 'SUCCESS_HOLDER_DETAIL';
export const FAILURE_HOLDER_DETAIL = 'FAILURE_HOLDER_DETAIL';

export function fetchHolderDetail ({ shortCode, assetCode }) {
  return {
    [CALL_API]: {
      types: [REQUEST_HOLDER_DETAIL, SUCCESS_HOLDER_DETAIL, FAILURE_HOLDER_DETAIL],
      config: {
        method: 'get',
        isMainAPI: true,
        url: '/v14/holders/config/detail',
        params: {
          shorten_code: shortCode,
          asset_code: assetCode
        }
      }
    }
  };
}