/* @flow */
import React from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { EditFavoriteStocksModal } from '../../components/modals/edit_favorite_stocks_modal';

import { loadFavoriteStockLists, addFavoriteStockList } from '../../actions/favorite_stock_lists';
import {
  fetchFavoriteStocksBySecurityId,
  addFavoriteStock,
  deleteFavoriteStocks
} from '../../actions/favorite_stocks';
import { closeModal } from '../../actions/modals';

import { allFavoriteStockListsSelector } from '../../selectors/paginate_selectors';
import { favoriteStocksBySecurityIdSelector } from '../../selectors/paginate_selectors';

type Props = {
  securityId: string,
  loadFavoriteStockLists: typeof loadFavoriteStockLists,
  fetchFavoriteStocksBySecurityId: typeof fetchFavoriteStocksBySecurityId,
  addFavoriteStock: typeof addFavoriteStock,
  deleteFavoriteStocks: typeof deleteFavoriteStocks,
  closeModal: typeof closeModal,
  addFavoriteStockList: typeof addFavoriteStockList
};

class EditFavoriteStocksModalContainer extends React.Component {
  props: Props;

  componentDidMount() {
    this.props.loadFavoriteStockLists();
    this.props.fetchFavoriteStocksBySecurityId(this.props.securityId);
  }

  handleAddFavoriteStock(favoriteStockListId: number, e: Object): any {
    this.props.addFavoriteStock(favoriteStockListId, this.props.securityId)
      .then(() => this.props.fetchFavoriteStocksBySecurityId(this.props.securityId));
    e.preventDefault();
  }

  handleDeleteFavoriteStock(favoriteStockListId: number, favoriteStockId: number, e: Object): any {
    this.props.deleteFavoriteStocks(favoriteStockListId, [favoriteStockId])
      .then(() => this.props.fetchFavoriteStocksBySecurityId(this.props.securityId));
    e.preventDefault();
  }

  handleClickClose(e: Object): any {
    this.props.closeModal();
    e.preventDefault();
  }

  handleSaveFavoriteStockList(favoriteStockListName: string, e: Object): any {
    e.preventDefault();
    return this.props.addFavoriteStockList(favoriteStockListName);
  }

  render() {
    return (
      <EditFavoriteStocksModal
        onAddFavoriteStock={this.handleAddFavoriteStock.bind(this)}
        onDeleteFavoriteStock={this.handleDeleteFavoriteStock.bind(this)}
        onClickClose={this.handleClickClose.bind(this)}
        onSaveFavoriteStockList={this.handleSaveFavoriteStockList.bind(this)}
        {...this.props}
      />
    );
  }
}

const selector = createSelector([
  allFavoriteStockListsSelector,
  favoriteStocksBySecurityIdSelector
], (favoriteStockLists, registeredFavoriteStocks) => ({
  favoriteStockLists,
  registeredFavoriteStocks
}));

export default connect(selector, {
  loadFavoriteStockLists,
  fetchFavoriteStocksBySecurityId,
  addFavoriteStock,
  deleteFavoriteStocks,
  closeModal,
  addFavoriteStockList
})(EditFavoriteStocksModalContainer);
