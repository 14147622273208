/* @flow */
import React from 'react';
import { connect } from 'react-redux';
import { closeModal, openSetProfileModal } from '../../actions/modals';
import { activateLogin } from '../../actions/login';

class ReleaseDormantModal extends React.Component {
  props: {
    token: action.error.response.body.error.code,
    onSuccess?: (user: Object) => any,
    onError?: (error: Object) => any,
    activateLogin: typeof activateLogin,
    closeModal: typeof closeModal,
  };

  static style = {
    content: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      border: '1px solid #ccc',
      background: '#fff',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch',
      borderRadius: '4px',
      outline: 'none',
      padding: 0
    }
  };

  constructor() {
    super();
    this.handleClickBack = this.handleClickBack.bind(this);
    this.handleClickRelease = this.handleClickRelease.bind(this);
  }

  handleClickRelease(e) {
    e.preventDefault();

    this.props.activateLogin(this.props.token)
      .then((user) => {
        if(user.error) {
          // 휴면계정 해제 실패
          const error = user.error;
          window.alert(error.message);
          if (this.props.onError) {
            this.props.onError(error);
          }
        } else {
          // 휴면계정 해제 성공
          window.alert('휴면해제가 완료되었습니다.');
          this.props.closeModal();
          if (this.props.onSuccess) {
            this.props.onSuccess(user);
          }
        }
      })
      .catch((error) => {
        // 기타 에러
        if (this.props.onError) {
          this.props.onError(error);
        }
      });
  }
  
  handleClickBack(e) {
    this.props.closeModal();
    location.reload();
    e.preventDefault();
  }
  
  render() {
    return (
      <div className='dormantW'>
        <main className="dormantBody">
          <div className="dormantInner">
            <i className="dormantIco"></i>
            <strong className="dormantTit">휴면계정을 해제하세요!</strong>
            <p className="dormantDesc">증권플러스를 계속 이용하시려면 <br/><span className="colorRed">&lsquo;휴면 해제하기&lsquo;</span> 버튼을 클릭해 주세요.</p>
            <a href="#" className="btnDormant" onClick={this.handleClickRelease}>휴면 해제하기</a>
            <a href="#" className="btnOtherId" onClick={this.handleClickBack}>다른 계정으로 로그인</a>
          </div>
        </main>
      </div>
    );
  }
}

const agreementSelector = state => (state.agreement);

export default connect(agreementSelector, {
  activateLogin,
  closeModal,
  openSetProfileModal
})(ReleaseDormantModal);
