import { reactionsReducer } from './reactions';
import { assign } from 'lodash';
import fetchActionTypes from '../../constants/fetch_actions';

export function socialPostCommentsReducer(state = {}, action) {
  switch (action.type) {
  case fetchActionTypes.ADD_SOCIAL_POST_COMMENT_REACTION.RECEIVE:
  case fetchActionTypes.REMOVE_SOCIAL_POST_COMMENT_REACTION.RECEIVE:
    return assign({}, state, {
      [action.id]: {
        ...state[action.id],
        reply: reactionsReducer(state[action.id].reply, action)
      }
    });
  default:
    return state;
  }
}
