const { NODE_ENV } = process.env;
const { NEWS_WEB_HOST } = process.env;
const { KAKAOSTOCK_HOST } = process.env;
const { KAKAOSTOCK_AVATAR_UPLOAD_URL } = process.env;
const { GA_TRACKING_CODE } = process.env;
const { API_ENDPOINT } = process.env;
const { SOCIAL_API_ENDPOINT } = process.env;
const { SENTRY_DSN } = process.env;
const { SENTRY_ENV } = process.env;
const { STOCKPLUS_MAIN_API_ENDPOINT } = process.env;
const { AMPLITUDE_ENV } = process.env;
const { AMPLITUDE_API_KEY } = process.env;

const SENTRY_ENV_TYPES = {
  LOCAL: 'local',
  DEV: 'development',
  STAGE: 'staging',
  PROD: 'production'
};

export {
  NODE_ENV,
  NEWS_WEB_HOST,
  KAKAOSTOCK_HOST,
  KAKAOSTOCK_AVATAR_UPLOAD_URL,
  GA_TRACKING_CODE,
  API_ENDPOINT,
  SOCIAL_API_ENDPOINT,
  SENTRY_DSN,
  SENTRY_ENV,
  SENTRY_ENV_TYPES,
  STOCKPLUS_MAIN_API_ENDPOINT,
  AMPLITUDE_ENV,
  AMPLITUDE_API_KEY
};
