/* @flow */
import URL from 'url-parse';
import { CALL_API } from '../middlewares/api';

export const REQUEST_PAGE = 'REQUEST_PAGE';
export const RECEIVE_PAGE = 'RECEIVE_PAGE';
export const FAILURE_PAGE = 'FAILURE_PAGE';

export function updatePage(path: string): any {
  console.warn(`DEPRECATE WARNING: 페이지 props가 필요한 경우는 명시적으로 updatePage를 호출하여야 합니다.
자동으로 updatePage를 불러주는 것은 곧 사라질 예정입니다. `);
  if (!path) {
    return null;
  }

  const url = new URL(path);

  let pathname = url.pathname;
  // trailing slash 처리
  if (pathname[pathname.length - 1] === '/') {
    pathname = pathname.substr(0, pathname.length - 1);
  }
  url.set('slashes', '//');
  url.set('pathname', `${pathname}.json`);

  return {
    [CALL_API]: {
      types: [REQUEST_PAGE, RECEIVE_PAGE, FAILURE_PAGE],
      config: {
        method: 'get',
        url: url.toString()
      }
    },
    path
  };
}
