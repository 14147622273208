import { postsReducer } from './posts';
import { insightArticlesReducer } from './insight_articles';
import { favoriteStockListsReducer } from './favorite_stock_lists';
import { companiesReducer } from './companies';
import { favoriteStocksReducer } from './favorite_stocks';
import { favoriteStockExistencesReducer } from './favorite_stock_existences';
import { issueKeywordsReducer } from './issue_keywords';
import { usersReducer } from './users';
import { topRankedSocialPostReducer } from './top_ranked_social_post';
import { socialPostsReducer } from './social_posts';
import { socialPostCommentsReducer } from './social_post_comments';
import { socialPostCommentsInCommentReducer } from './social_post_comments_in_comment';
import { socialSecuritiesReducer } from './social_securities';

export default {
  postsReducer,
  socialPostsReducer,
  insightArticlesReducer,
  favoriteStockListsReducer,
  companiesReducer,
  favoriteStocksReducer,
  favoriteStockExistencesReducer,
  issueKeywordsReducer,
  usersReducer,
  topRankedSocialPostReducer,
  socialPostCommentsReducer,
  socialPostCommentsInCommentReducer,
  socialSecuritiesReducer,
};
