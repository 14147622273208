/* @flow */
import { Routes } from '../routes';
import { CALL_API } from '../middlewares/api';
import fetchActionTypes from '../constants/fetch_actions';

export const REQUEST_COLLECTION_STOCKS = 'REQUEST_COLLECTION_STOCKS';
export const RECEIVE_COLLECTION_STOCKS = 'RECEIVE_COLLECTION_STOCKS';
export const FAILURE_COLLECTION_STOCKS = 'FAILURE_COLLECTION_STOCKS';

export function fetchCollectionStocks(key: string, market: string, limit: number, cursor: ?string = null): any {
  return {
    [CALL_API]: {
      types: [REQUEST_COLLECTION_STOCKS, RECEIVE_COLLECTION_STOCKS, FAILURE_COLLECTION_STOCKS],
      config: {
        method: 'get',
        url: Routes.collection_stocks_api_trends_path({
          key,
          market,
          limit,
          cursor,
          format: 'json'
        })
      }
    },
    key,
    market,
    limit,
    cursor
  };
}

export function loadCollectionStocks(key: string, market: string, limit: number, cursor: ?string = null): any {
  return (dispatch, getState) => {
    const {
      paginate: {
        collectionStocksByKeyAndMarket
      }
    } = getState();

    const paginate = collectionStocksByKeyAndMarket[[key, market]];
    if (paginate && paginate.pageCount > 0 && !cursor) {
      return Promise.resolve();
    }

    return dispatch(fetchCollectionStocks(key, market, limit, cursor));
  };
}

export const REQUEST_ORDERED_STOCKS = 'REQUEST_ORDERED_STOCKS';
export const RECEIVE_ORDERED_STOCKS = 'RECEIVE_ORDERED_STOCKS';
export const FAILURE_ORDERED_STOCKS = 'FAILURE_ORDERED_STOCKS';

export function fetchOrderedStocks(key: string, market: string, limit: number, cursor: ?string = null): any {
  return {
    [CALL_API]: {
      types: [REQUEST_ORDERED_STOCKS, RECEIVE_ORDERED_STOCKS, FAILURE_ORDERED_STOCKS],
      config: {
        method: 'get',
        url: Routes.ordered_stocks_api_trends_path({
          key,
          market,
          limit,
          cursor,
          format: 'json'
        })
      }
    },
    key,
    market,
    limit,
    cursor
  };
}

export function loadOrderedStocks(key: string, market: string, limit: number, cursor: ?string = null): any {
  return (dispatch, getState) => {
    const {
      paginate: {
        orderedStocksByKeyAndMarket
      }
    } = getState();

    const paginate = orderedStocksByKeyAndMarket[[key, market]];
    if (paginate && paginate.pageCount > 0 && !cursor) {
      return Promise.resolve();
    }

    return dispatch(fetchOrderedStocks(key, market, limit, cursor));
  };
}

export const REQUEST_MARKET_CONDITION = 'REQUEST_MARKET_CONDITION';
export const RECEIVE_MARKET_CONDITION = 'RECEIVE_MARKET_CONDITION';
export const FAILURE_MARKET_CONDITION = 'FAILURE_MARKET_CONDITION';

export function fetchMarketCondition(): any {
  return {
    [CALL_API]: {
      types: [REQUEST_MARKET_CONDITION, RECEIVE_MARKET_CONDITION, FAILURE_MARKET_CONDITION],
      config: {
        method: 'get',
        url: Routes.api_market_condition_path({ format: 'json' })
      }
    }
  };
}

export function fetchForeignIndexes(): any {
  return {
    [CALL_API]: {
      types: fetchActionTypes.FOREIGN_INDEXES,
      config: {
        method: 'get',
        url: Routes.foreign_indexes_api_trends_path({ format: 'json' })
      }
    }
  };
}

export function loadForeignIndexes(): any {
  return (dispatch, getState) => {
    const {
      trend: {
        foreignIndexes
      }
    } = getState();

    if (foreignIndexes) {
      return Promise.resolve(foreignIndexes);
    }

    return dispatch(fetchForeignIndexes());
  };
}

export function fetchForex(): any {
  return {
    [CALL_API]: {
      types: fetchActionTypes.FOREX,
      config: {
        method: 'get',
        url: Routes.foreign_indexes_api_trends_path({ format: 'json' })
      }
    }
  };
}

export function loadForex(): any {
  return (dispatch, getState) => {
    const {
      trend: {
        forexIndexes
      }
    } = getState();

    if (forexIndexes) {
      return Promise.resolve(forexIndexes);
    }

    return dispatch(fetchForex());
  };
}

export const REQUEST_DAILY_NET_TRADES = 'REQUEST_DAILY_NET_TRADES';
export const RECEIVE_DAILY_NET_TRADES = 'RECEIVE_DAILY_NET_TRADES';
export const FAILURE_DAILY_NET_TRADES = 'FAILURE_DAILY_NET_TRADES';

export const REQUEST_CONSECUTIVE_NET_TRADES = 'REQUEST_CONSECUTIVE_NET_TRADES';
export const RECEIVE_CONSECUTIVE_NET_TRADES = 'RECEIVE_CONSECUTIVE_NET_TRADES';
export const FAILURE_CONSECUTIVE_NET_TRADES = 'FAILURE_CONSECUTIVE_NET_TRADES';

export const RECEIVE_SPECIAL_STOCKS = 'RECEIVE_SPECIAL_STOCKS';

export function fetchSpecialStocks(market: string): any {
  return {
    market,
    [CALL_API]: {
      types: fetchActionTypes.SPECIAL_STOCKS,
      config: {
        method: 'get',
        url: Routes.special_stocks_api_trends_path({ format: 'json' }),
        params: {
          market
        }
      }
    }
  };
}

const KOSPI = 'kospi';
const KOSDAQ = 'kosdaq';
export function loadSpecialStocks(): any {
  return (dispatch, getState) => {
    const {
      trend: {
        specialStocks
      }
    } = getState();

    if (specialStocks) {
      return Promise.resolve(specialStocks);
    }

    return Promise.all([dispatch(fetchSpecialStocks(KOSPI)), dispatch(fetchSpecialStocks(KOSDAQ))]);

  };

}