/* @flow */
import { connect } from 'react-redux';
import { ShareModal } from '../../components/common/share_modal';

import { closeModal } from '../../actions/modals';
import { requestWarp } from '../../actions/scheme';

export default connect((state, _props) => ({
  pathname: state.routing.location.pathname
}), {
  requestWarp,
  onClickClose: closeModal
})(ShareModal);
