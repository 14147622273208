import { Routes } from '../routes';
import { CALL_API } from '../middlewares/api';
import { requireLogin } from '../actions/login';
import { loadMe } from '../actions/me';
import { RELOAD_LOCATION } from '../constants/ui';

export const REQUEST_LIKE = 'REQUEST_LIKE';
export const SUCCESS_LIKE = 'SUCCESS_LIKE';
export const FAILURE_LIKE = 'FAILURE_LIKE';

export function like(emotionableType, emotionableId, myId) {
  return {
    [CALL_API]: {
      types: [REQUEST_LIKE, SUCCESS_LIKE, FAILURE_LIKE],
      config: {
        method: 'post',
        url: Routes.like_api_emotions_path({
          type: emotionableType,
          id: emotionableId,
          format: 'json'
        })
      }
    },
    emotionableType,
    emotionableId,
    myId
  };
}

export const likePost = requireLogin((id) => {
  return (dispatch, getState) => {
    const {
      entities: {
        posts = {}
      }
    } = getState();

    const post = posts[id];
    if (!post) {
      return null;
    }

    // 로그인이 되어 있지 않을 경우 myEmotion이 누락되어 있기 때문에 이를 caller에게 알려준다.
    if (!post.myEmotion) {
      return Promise.resolve(RELOAD_LOCATION);
    }

    return (
      dispatch(loadMe())
        .then((res) => {
          const myId = res.id ? res.id : res.payload.result.user;
          if (post.myEmotion && post.myEmotion.liked) {
            return dispatch(unlike('post', id, myId));
          }
          return dispatch(like('post', id, myId));
        })
    );
  };
});

export const likeInsightArticle = requireLogin((id) => {
  return (dispatch, getState) => {
    const {
      entities: {
        insightArticles = {}
      }
    } = getState();

    const insightArticle = insightArticles[id];
    if (!insightArticle) {
      return null;
    }

    // 로그인이 되어 있지 않을 경우 myEmotion이 누락되어 있기 때문에 이를 caller에게 알려준다.
    if (!insightArticle.myEmotion) {
      return Promise.resolve(RELOAD_LOCATION);
    }

    return (
      dispatch(loadMe())
        .then((res) => {
          const myId = res.id ? res.id : res.payload.result.user;
          if (insightArticle.myEmotion && insightArticle.myEmotion.liked) {
            return dispatch(unlike('insight_article', id, myId));
          }
          return dispatch(like('insight_article', id, myId));
        })
    );
  };
});

export const REQUEST_DISLIKE = 'REQUEST_DISLIKE';
export const SUCCESS_DISLIKE = 'SUCCESS_DISLIKE';
export const FAILURE_DISLIKE = 'FAILURE_DISLIKE';

export function dislike(emotionableType, emotionableId, myId) {
  return {
    [CALL_API]: {
      types: [REQUEST_DISLIKE, SUCCESS_DISLIKE, FAILURE_DISLIKE],
      config: {
        method: 'post',
        url: Routes.dislike_api_emotions_path({
          type: emotionableType,
          id: emotionableId,
          format: 'json'
        })
      }
    },
    emotionableType,
    emotionableId,
    myId
  };
}

export const dislikePost = requireLogin((id) => {
  return (dispatch, getState) => {
    const {
      entities: {
        posts = {}
      }
    } = getState();

    const post = posts[id];
    if (!post) {
      return null;
    }

    // 로그인이 되어 있지 않을 경우 myEmotion이 누락되어 있기 때문에 이를 caller에게 알려준다.
    if (!post.myEmotion) {
      return Promise.resolve(RELOAD_LOCATION);
    }

    return (
      dispatch(loadMe())
        .then((res) => {
          const myId = res.id ? res.id : res.payload.result.user;
          if (post.myEmotion && post.myEmotion.disliked) {
            return dispatch(unlike('post', id, myId));
          }
          return dispatch(dislike('post', id, myId));
        })
    );
  };
});

export const REQUEST_UNLIKE = 'REQUEST_UNLIKE';
export const SUCCESS_UNLIKE = 'SUCCESS_UNLIKE';
export const FAILURE_UNLIKE = 'FAILURE_UNLIKE';

export function unlike(emotionableType, emotionableId, myId) {
  return {
    [CALL_API]: {
      types: [REQUEST_UNLIKE, SUCCESS_UNLIKE, FAILURE_UNLIKE],
      config: {
        method: 'post',
        url: Routes.unlike_api_emotions_path({
          type: emotionableType,
          id: emotionableId,
          format: 'json'
        })
      }
    },
    emotionableType,
    emotionableId,
    myId
  };
}
