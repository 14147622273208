import { assign } from 'lodash';
import fetchActionTypes from '../../constants/fetch_actions';
import { getSocialSecurityKey } from '../../helpers/social_security';

export function socialSecuritiesReducer(state = {}, action) {
  switch (action.type) {
  case fetchActionTypes.SOCIAL_SECURITY.RECEIVE: {
    const { payload, assetCode, boardType } = action;
    const key = getSocialSecurityKey({ boardType, assetCode });
    return assign({}, state, {
      [key]: payload,
    });
  }
  default:
    return state;
  }
}
