import { assign } from 'lodash';
import * as MajorForexIdsActions from '../../actions/major_forex_ids';

const initialState = {
  featuredAssetIds: null,
  krwAssetIds: null,
  usdAssetIds: null
};

export function majorForexIdsReducer(state = initialState, action = {}) {
  switch (action.type) {
  case MajorForexIdsActions.RECEIVE_MAJOR_FOREX_IDS:
    if (action.payload === null) {
      return state;
    }
    return assign({}, state, { ...action.payload });
  default:
    return state;
  }
}
