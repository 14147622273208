import { Routes } from '../routes';
import { CALL_API } from '../middlewares/api';
import fetchActionTypes from '../constants/fetch_actions';
import { post, postArray } from '../schemas/post';
import { userArray } from '../schemas/user';

export const REQUEST_SECURITY_POSTS = 'REQUEST_SECURITY_POSTS';
export const RECEIVE_SECURITY_POSTS = 'RECEIVE_SECURITY_POSTS';
export const FAILURE_SECURITY_POSTS = 'FAILURE_SECURITY_POSTS';

export function fetchSecurityPosts(securityId, scope = 'all', cursor = null) {
  return {
    [CALL_API]: {
      types: [REQUEST_SECURITY_POSTS, RECEIVE_SECURITY_POSTS, FAILURE_SECURITY_POSTS],
      config: {
        method: 'get',
        url: Routes.api_security_posts_path(securityId, { scope, cursor, format: 'json' })
      },
      schema: { posts: postArray }
    },
    securityId,
    scope,
    cursor
  };
}

export function loadSecurityPosts(securityId, scope = 'all', cursor = null) {
  return (dispatch, getState) => {
    const {
      paginate: {
        postsBySecurityIdAndScope
      }
    } = getState();

    const pagination = postsBySecurityIdAndScope[[securityId, scope]];
    if (!cursor && pagination && pagination.pageCount > 0) {
      return null;
    }

    return dispatch(fetchSecurityPosts(securityId, scope, cursor));
  };
}

export const REQUEST_SECURITY_POST = 'REQUEST_SECURITY_POST';
export const RECEIVE_SECURITY_POST = 'RECEIVE_SECURITY_POST';
export const FAILURE_SECURITY_POST = 'FAILURE_SECURITY_POST';

export function fetchSecurityPost(securityId, id, scope = 'all') {
  return {
    [CALL_API]: {
      types: [REQUEST_SECURITY_POST, RECEIVE_SECURITY_POST, FAILURE_SECURITY_POST],
      config: {
        method: 'get',
        url: `/api/posts/${id}.json`,
        params: { scope }
      },
      schema: {
        post
      }
    },
    securityId,
    id,
    scope
  };
}

export const REQUEST_DELETE_POST = 'REQUEST_DELETE_POST';
export const SUCCESS_DELETE_POST = 'SUCCESS_DELETE_POST';
export const FAILURE_DELETE_POST = 'FAILURE_DELETE_POST';

export function deletePost(securityId, id) {
  return {
    [CALL_API]: {
      types: [REQUEST_DELETE_POST, SUCCESS_DELETE_POST, FAILURE_DELETE_POST],
      config: {
        method: 'delete',
        url: Routes.api_security_post_path(securityId, id, { format: 'json' })
      }
    },
    securityId,
    id
  };
}

export const REQUEST_CREATE_POST = 'REQUEST_CREATE_POST';
export const SUCCESS_CREATE_POST = 'SUCCESS_CREATE_POST';
export const FAILURE_CREATE_POST = 'FAILURE_CREATE_POST';

export function createPost(securityId, content, side = null, period = null) {
  return {
    [CALL_API]: {
      types: [REQUEST_CREATE_POST, SUCCESS_CREATE_POST, FAILURE_CREATE_POST],
      config: {
        method: 'post',
        url: Routes.api_security_posts_path(securityId, { format: 'json' }),
        data: {
          side,
          period,
          content
        }
      },
      schema: {
        post
      }
    },
    securityId,
    content,
    side,
    period
  };
}

export function fetchLikedUsers(securityId, postId, limit = 5, cursor = null) {
  return {
    [CALL_API]: {
      types: fetchActionTypes.LIKED_USERS,
      config: {
        method: 'get',
        url: Routes.api_security_post_liked_users_path(securityId, postId, { cursor, limit, format: 'json' })
      },
      schema: {
        users: userArray
      }
    },
    postId,
    cursor
  };
}
