/* @flow */
import { RECEIVE_ISSUE_KEYWORDS } from '../../actions/search';

export function issueKeywordsReducer(state: Object = {}, action: Object): Object {
  switch (action.type) {
  case RECEIVE_ISSUE_KEYWORDS:
    return action.payload.result.issueKeywords;
  default:
    return state;
  }
}
