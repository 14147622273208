/*
File generated by js-routes 1.1.2
Based on Rails routes of KakaoFinance::Application
 */
(function() {
  var NodeTypes, ParameterMissing, Utils, createGlobalJsRoutesObject, defaults, root,
    hasProp = {}.hasOwnProperty;

  root = typeof exports !== 'undefined' && exports !== null ? exports : this;

  ParameterMissing = function(message) {
    this.message = message;
  };

  ParameterMissing.prototype = new Error();

  defaults = {
    prefix: '',
    default_url_options: {}
  };

  NodeTypes = { 'GROUP':1,'CAT':2,'SYMBOL':3,'OR':4,'STAR':5,'LITERAL':6,'SLASH':7,'DOT':8 };

  Utils = {
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return '';
      }
      if (!prefix && !(this.get_object_type(object) === 'object')) {
        throw new Error('Url parameters should be a javascript hash');
      }
      s = [];
      switch (this.get_object_type(object)) {
      case 'array':
        for (i = j = 0, len = object.length; j < len; i = ++j) {
          element = object[i];
          s.push(this.default_serializer(element, prefix + '[]'));
        }
        break;
      case 'object':
        for (key in object) {
          if (!hasProp.call(object, key)) {
            continue;
          }
          prop = object[key];
          if ((prop == null) && (prefix != null)) {
            prop = '';
          }
          if (prop != null) {
            if (prefix != null) {
              key = prefix + '[' + key + ']';
            }
            s.push(this.default_serializer(prop, key));
          }
        }
        break;
      default:
        if (object != null) {
          s.push((encodeURIComponent(prefix.toString())) + '=' + (encodeURIComponent(object.toString())));
        }
      }
      if (!s.length) {
        return '';
      }
      return s.join('&');
    },
    custom_serializer: null,
    serialize: function(object) {
      if ((this.custom_serializer != null) && this.get_object_type(this.custom_serializer) === 'function') {
        return this.custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split('://');
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, '/');
      return path.join('://');
    },
    set_default_url_options: function(optional_parts, options) {
      var i, j, len, part, results;
      results = [];
      for (i = j = 0, len = optional_parts.length; j < len; i = ++j) {
        part = optional_parts[i];
        if (!options.hasOwnProperty(part) && defaults.default_url_options.hasOwnProperty(part)) {
          results.push(options[part] = defaults.default_url_options[part]);
        }
      }
      return results;
    },
    extract_anchor: function(options) {
      var anchor;
      anchor = '';
      if (options.hasOwnProperty('anchor')) {
        anchor = '#' + options.anchor;
        delete options.anchor;
      }
      return anchor;
    },
    extract_trailing_slash: function(options) {
      var trailing_slash;
      trailing_slash = false;
      if (defaults.default_url_options.hasOwnProperty('trailing_slash')) {
        trailing_slash = defaults.default_url_options.trailing_slash;
      }
      if (options.hasOwnProperty('trailing_slash')) {
        trailing_slash = options.trailing_slash;
        delete options.trailing_slash;
      }
      return trailing_slash;
    },
    extract_options: function(number_of_params, args) {
      var last_el;
      last_el = args[args.length - 1];
      if (args.length > number_of_params || ((last_el != null) && 'object' === this.get_object_type(last_el) && !this.look_like_serialized_model(last_el))) {
        return args.pop();
      } else {
        return {};
      }
    },
    look_like_serialized_model: function(object) {
      return 'id' in object || 'to_param' in object;
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return '0';
      }
      if (!object) {
        return '';
      }
      property = object;
      if (this.get_object_type(object) === 'object') {
        if ('to_param' in object) {
          property = object.to_param;
        } else if ('id' in object) {
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === 'function') {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || 'object' !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) {
          continue;
        }
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    prepare_parameters: function(required_parameters, actual_parameters, options) {
      var i, j, len, result, val;
      result = this.clone(options) || {};
      for (i = j = 0, len = required_parameters.length; j < len; i = ++j) {
        val = required_parameters[i];
        if (i < actual_parameters.length) {
          result[val] = actual_parameters[i];
        }
      }
      return result;
    },
    build_path: function(required_parameters, optional_parts, route, args) {
      var anchor, opts, parameters, result, trailing_slash, url, url_params;
      args = Array.prototype.slice.call(args);
      opts = this.extract_options(required_parameters.length, args);
      if (args.length > required_parameters.length) {
        throw new Error('Too many parameters provided for path');
      }
      parameters = this.prepare_parameters(required_parameters, args, opts);
      this.set_default_url_options(optional_parts, parameters);
      anchor = this.extract_anchor(parameters);
      trailing_slash = this.extract_trailing_slash(parameters);
      result = '' + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path('' + result);
      if (trailing_slash === true) {
        url = url.replace(/(.*?)[\/]?$/, '$1/');
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += '?' + url_params;
      }
      url += anchor;
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
      case NodeTypes.GROUP:
        return this.visit(left, parameters, true);
      case NodeTypes.STAR:
        return this.visit_globbing(left, parameters, true);
      case NodeTypes.LITERAL:
      case NodeTypes.SLASH:
      case NodeTypes.DOT:
        return left;
      case NodeTypes.CAT:
        left_part = this.visit(left, parameters, optional);
        right_part = this.visit(right, parameters, optional);
        if (optional && (((left[0] === NodeTypes.SYMBOL || left[0] === NodeTypes.CAT) && !left_part) || ((right[0] === NodeTypes.SYMBOL || right[0] === NodeTypes.CAT) && !right_part))) {
          return '';
        }
        return '' + left_part + right_part;
      case NodeTypes.SYMBOL:
        value = parameters[left];
        if (value != null) {
          delete parameters[left];
          return this.path_identifier(value);
        }
        if (optional) {
          return '';
        } else {
          throw new ParameterMissing('Route parameter missing: ' + left);
        }
        break;
      default:
        throw new Error('Unknown Rails node type');
      }
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
      case NodeTypes.GROUP:
        return '(' + (this.build_path_spec(left)) + ')';
      case NodeTypes.CAT:
        return '' + (this.build_path_spec(left)) + (this.build_path_spec(right));
      case NodeTypes.STAR:
        return this.build_path_spec(left, true);
      case NodeTypes.SYMBOL:
        if (wildcard === true) {
          return '' + (left[0] === '*' ? '' : '*') + left;
        } else {
          return ':' + left;
        }
        break;
      case NodeTypes.SLASH:
      case NodeTypes.DOT:
      case NodeTypes.LITERAL:
        return left;
      default:
        throw new Error('Unknown Rails node type');
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      if (left.replace(/^\*/i, '') !== left) {
        route[1] = left = left.replace(/^\*/i, '');
      }
      value = parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      parameters[left] = (function() {
        switch (this.get_object_type(value)) {
        case 'array':
          return value.join('/');
        default:
          return value;
        }
      }).call(this);
      return this.visit(route, parameters, optional);
    },
    get_prefix: function() {
      var prefix;
      prefix = defaults.prefix;
      if (prefix !== '') {
        prefix = (prefix.match('/$') ? prefix : prefix + '/');
      }
      return prefix;
    },
    route: function(required_parts, optional_parts, route_spec) {
      var path_fn;
      path_fn = function() {
        return Utils.build_path(required_parts, optional_parts, route_spec, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = 'Boolean Number String Function Array Date RegExp Object Error'.split(' ');
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache['[object ' + name + ']'] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return '' + obj;
      }
      if (typeof obj === 'object' || typeof obj === 'function') {
        return this._classToType()[Object.prototype.toString.call(obj)] || 'object';
      } else {
        return typeof obj;
      }
    }
  };

  createGlobalJsRoutesObject = function() {
    var namespace;
    namespace = function(mainRoot, namespaceString) {
      var current, parts;
      parts = (namespaceString ? namespaceString.split('.') : []);
      if (!parts.length) {
        return;
      }
      current = parts.shift();
      mainRoot[current] = mainRoot[current] || {};
      return namespace(mainRoot[current], parts.join('.'));
    };
    namespace(root, 'Routes');
    root.Routes = {
      // add_mobile_favorite_stock_list_favorite_stocks => /m/my/favorite_stock_lists/:favorite_stock_list_id/favorite_stocks/add(.:format)
      // function(favorite_stock_list_id, options)
      add_mobile_favorite_stock_list_favorite_stocks_path: Utils.route(['favorite_stock_list_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'my',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[3,'favorite_stock_list_id',false],[2,[7,'/',false],[2,[6,'favorite_stocks',false],[2,[7,'/',false],[2,[6,'add',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]]]], arguments),
      // all_news_api_news_items => /api/news_items/all_news(.:format)
      // function(options)
      all_news_api_news_items_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'news_items',false],[2,[7,'/',false],[2,[6,'all_news',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // analysis_mobile_stock => /m/stocks/:id/analysis(.:format)
      // function(id, options)
      analysis_mobile_stock_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'id',false],[2,[7,'/',false],[2,[6,'analysis',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_asset_day_forexes => /api/assets/:asset_id/day_forexes(.:format)
      // function(asset_id, options)
      api_asset_day_forexes_path: Utils.route(['asset_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'assets',false],[2,[7,'/',false],[2,[3,'asset_id',false],[2,[7,'/',false],[2,[6,'day_forexes',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_asset_foreign_index => /api/assets/:asset_id/quotations/foreign(.:format)
      // function(asset_id, options)
      api_asset_foreign_index_path: Utils.route(['asset_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'assets',false],[2,[7,'/',false],[2,[3,'asset_id',false],[2,[7,'/',false],[2,[6,'quotations',false],[2,[7,'/',false],[2,[6,'foreign',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]], arguments),
      // api_asset_tick_forexes => /api/assets/:asset_id/tick_forexes(.:format)
      // function(asset_id, options)
      api_asset_tick_forexes_path: Utils.route(['asset_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'assets',false],[2,[7,'/',false],[2,[3,'asset_id',false],[2,[7,'/',false],[2,[6,'tick_forexes',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_assets => /api/assets(.:format)
      // function(options)
      api_assets_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'assets',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // api_comment => /api/comments/:id(.:format)
      // function(id, options)
      api_comment_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'comments',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // api_comments => /api/comments(.:format)
      // function(options)
      api_comments_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'comments',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // api_company => /api/companies/:id(.:format)
      // function(id, options)
      api_company_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'companies',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // api_company_financial_statements => /api/companies/:company_id/financial_statements(.:format)
      // function(company_id, options)
      api_company_financial_statements_path: Utils.route(['company_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'companies',false],[2,[7,'/',false],[2,[3,'company_id',false],[2,[7,'/',false],[2,[6,'financial_statements',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_contest => /api/contests/:id(.:format)
      // function(id, options)
      api_contest_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'contests',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // api_contest_rankers => /api/contests/:contest_id/rankers(.:format)
      // function(contest_id, options)
      api_contest_rankers_path: Utils.route(['contest_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'contests',false],[2,[7,'/',false],[2,[3,'contest_id',false],[2,[7,'/',false],[2,[6,'rankers',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_contests => /api/contests(.:format)
      // function(options)
      api_contests_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'contests',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // api_contributor => /api/contributors/:id(.:format)
      // function(id, options)
      api_contributor_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'contributors',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // api_contributor_articles => /api/contributors/:contributor_id/articles(.:format)
      // function(contributor_id, options)
      api_contributor_articles_path: Utils.route(['contributor_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'contributors',false],[2,[7,'/',false],[2,[3,'contributor_id',false],[2,[7,'/',false],[2,[6,'articles',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_favorite_stock_list => /api/favorite_stock_lists/:id(.:format)
      // function(id, options)
      api_favorite_stock_list_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // api_favorite_stock_list_favorite_stocks => /api/favorite_stock_lists/:favorite_stock_list_id/favorite_stocks(.:format)
      // function(favorite_stock_list_id, options)
      api_favorite_stock_list_favorite_stocks_path: Utils.route(['favorite_stock_list_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[3,'favorite_stock_list_id',false],[2,[7,'/',false],[2,[6,'favorite_stocks',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_favorite_stock_lists => /api/favorite_stock_lists(.:format)
      // function(options)
      api_favorite_stock_lists_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // api_forex_calcs => /api/forex_calcs(.:format)
      // function(options)
      api_forex_calcs_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'forex_calcs',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // api_forex_charts => /api/forex/:forex_id/charts(.:format)
      // function(forex_id, options)
      api_forex_charts_path: Utils.route(['forex_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'forex',false],[2,[7,'/',false],[2,[3,'forex_id',false],[2,[7,'/',false],[2,[6,'charts',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_insight_article => /api/insight_articles/:id(.:format)
      // function(id, options)
      api_insight_article_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'insight_articles',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // api_insight_articles => /api/insight_articles(.:format)
      // function(options)
      api_insight_articles_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'insight_articles',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // api_market_condition => /api/market_condition(.:format)
      // function(options)
      api_market_condition_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'market_condition',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // api_me_index => /api/me(.:format)
      // function(options)
      api_me_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'me',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // api_notices => /api/notices(.:format)
      // function(options)
      api_notices_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'notices',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // api_ranker => /api/rankers/:id(.:format)
      // function(id, options)
      api_ranker_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'rankers',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // api_reports => /api/reports(.:format)
      // function(options)
      api_reports_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'reports',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // api_securities => /api/securities(.:format)
      // function(options)
      api_securities_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // api_security => /api/securities/:id(.:format)
      // function(id, options)
      api_security_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // api_security_analysis => /api/securities/:security_id/analysis(.:format)
      // function(security_id, options)
      api_security_analysis_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'analysis',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_articles => /api/securities/:security_id/articles(.:format)
      // function(security_id, options)
      api_security_articles_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'articles',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_assets => /api/securities/:security_id/assets(.:format)
      // function(security_id, options)
      api_security_assets_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'assets',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_charts => /api/securities/:security_id/charts(.:format)
      // function(security_id, options)
      api_security_charts_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'charts',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_day_candles => /api/securities/:security_id/day_candles(.:format)
      // function(security_id, options)
      api_security_day_candles_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'day_candles',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_day_traders => /api/securities/:security_id/day_traders(.:format)
      // function(security_id, options)
      api_security_day_traders_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'day_traders',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_disclosures => /api/securities/:security_id/disclosures(.:format)
      // function(security_id, options)
      api_security_disclosures_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'disclosures',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_favorite_stock_existence => /api/securities/:security_id/favorite_stock_existence(.:format)
      // function(security_id, options)
      api_security_favorite_stock_existence_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'favorite_stock_existence',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_favorite_stocks => /api/securities/:security_id/favorite_stocks(.:format)
      // function(security_id, options)
      api_security_favorite_stocks_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'favorite_stocks',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_insight_articles => /api/securities/:security_id/insight_articles(.:format)
      // function(security_id, options)
      api_security_insight_articles_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'insight_articles',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_investors => /api/securities/:security_id/investors(.:format)
      // function(security_id, options)
      api_security_investors_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'investors',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_news_index => /api/securities/:security_id/news(.:format)
      // function(security_id, options)
      api_security_news_index_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'news',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_post => /api/securities/:security_id/posts/:id(.:format)
      // function(security_id, id, options)
      api_security_post_path: Utils.route(['security_id','id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'posts',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]], arguments),
      // api_security_post_liked_users => /api/securities/:security_id/posts/:post_id/liked_users(.:format)
      // function(security_id, post_id, options)
      api_security_post_liked_users_path: Utils.route(['security_id','post_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'posts',false],[2,[7,'/',false],[2,[3,'post_id',false],[2,[7,'/',false],[2,[6,'liked_users',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]]]], arguments),
      // api_security_posts => /api/securities/:security_id/posts(.:format)
      // function(security_id, options)
      api_security_posts_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'posts',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_recent_trader_index => /api/securities/:security_id/recent_trader(.:format)
      // function(security_id, options)
      api_security_recent_trader_index_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'recent_trader',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_topics => /api/securities/:security_id/topics(.:format)
      // function(security_id, options)
      api_security_topics_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'topics',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_security_values => /api/securities/:security_id/values(.:format)
      // function(security_id, options)
      api_security_values_path: Utils.route(['security_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[3,'security_id',false],[2,[7,'/',false],[2,[6,'values',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_session => /api/session(.:format)
      // function(options)
      api_session_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'session',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // api_stocks_for_change_type_statistic => /api/stocks_for_change_type_statistics/:id(.:format)
      // function(id, options)
      api_stocks_for_change_type_statistic_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'stocks_for_change_type_statistics',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // api_topic => /api/topics/:id(.:format)
      // function(id, options)
      api_topic_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'topics',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // api_user => /api/user(.:format)
      // function(options)
      api_user_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'user',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // api_wics_sector_securities => /api/wics_sectors/:wics_sector_id/securities(.:format)
      // function(wics_sector_id, options)
      api_wics_sector_securities_path: Utils.route(['wics_sector_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'wics_sectors',false],[2,[7,'/',false],[2,[3,'wics_sector_id',false],[2,[7,'/',false],[2,[6,'securities',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // api_wics_sectors => /api/wics_sectors(.:format)
      // function(options)
      api_wics_sectors_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'wics_sectors',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // article_mobile_investing_strategies => /m/investing_strategies/articles/:id(.:format)
      // function(id, options)
      article_mobile_investing_strategies_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'investing_strategies',false],[2,[7,'/',false],[2,[6,'articles',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // articles_api_news_items => /api/news_items/articles(.:format)
      // function(options)
      articles_api_news_items_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'news_items',false],[2,[7,'/',false],[2,[6,'articles',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // assets_api_search_index => /api/search/assets(.:format)
      // function(options)
      assets_api_search_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'search',false],[2,[7,'/',false],[2,[6,'assets',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // autocomplete_api_search_index => /api/search/autocomplete(.:format)
      // function(options)
      autocomplete_api_search_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'search',false],[2,[7,'/',false],[2,[6,'autocomplete',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // collection_a_mobile_trend_index => /m/trend/collection_a(.:format)
      // function(options)
      collection_a_mobile_trend_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'trend',false],[2,[7,'/',false],[2,[6,'collection_a',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // collection_b_mobile_trend_index => /m/trend/collection_b(.:format)
      // function(options)
      collection_b_mobile_trend_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'trend',false],[2,[7,'/',false],[2,[6,'collection_b',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // collection_stocks_api_trends => /api/trends/collection_stocks(.:format)
      // function(options)
      collection_stocks_api_trends_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'trends',false],[2,[7,'/',false],[2,[6,'collection_stocks',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // construct_api_favorite_stock_lists => /api/favorite_stock_lists/construct(.:format)
      // function(options)
      construct_api_favorite_stock_lists_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[6,'construct',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // contest_rankers_mobile_rankers => /m/rankers/contest_rankers(.:format)
      // function(options)
      contest_rankers_mobile_rankers_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'rankers',false],[2,[7,'/',false],[2,[6,'contest_rankers',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // contributor_mobile_investing_strategies => /m/investing_strategies/contributors/:id(.:format)
      // function(id, options)
      contributor_mobile_investing_strategies_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'investing_strategies',false],[2,[7,'/',false],[2,[6,'contributors',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // daily_mobile_stock_quotations => /m/stocks/:stock_id/quotations/daily(.:format)
      // function(stock_id, options)
      daily_mobile_stock_quotations_path: Utils.route(['stock_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'stock_id',false],[2,[7,'/',false],[2,[6,'quotations',false],[2,[7,'/',false],[2,[6,'daily',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]], arguments),
      // destroy_api_favorite_stock_lists => /api/favorite_stock_lists/destroy(.:format)
      // function(options)
      destroy_api_favorite_stock_lists_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[6,'destroy',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // destroy_api_favorite_stocks => /api/favorite_stocks/destroy(.:format)
      // function(options)
      destroy_api_favorite_stocks_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'favorite_stocks',false],[2,[7,'/',false],[2,[6,'destroy',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // destroy_api_session => /api/session/destroy(.:format)
      // function(options)
      destroy_api_session_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'session',false],[2,[7,'/',false],[2,[6,'destroy',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // disclosures_api_news_items => /api/news_items/disclosures(.:format)
      // function(options)
      disclosures_api_news_items_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'news_items',false],[2,[7,'/',false],[2,[6,'disclosures',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // disclosures_mobile_stock_articles => /m/stocks/:stock_id/articles/disclosures(.:format)
      // function(stock_id, options)
      disclosures_mobile_stock_articles_path: Utils.route(['stock_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'stock_id',false],[2,[7,'/',false],[2,[6,'articles',false],[2,[7,'/',false],[2,[6,'disclosures',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]], arguments),
      // dislike_api_emotions => /api/emotions/dislike(.:format)
      // function(options)
      dislike_api_emotions_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'emotions',false],[2,[7,'/',false],[2,[6,'dislike',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // domestic_mobile_trend_index => /m/trend/domestic(.:format)
      // function(options)
      domestic_mobile_trend_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'trend',false],[2,[7,'/',false],[2,[6,'domestic',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // edit_mobile_favorite_stock_list_favorite_stock => /m/my/favorite_stock_lists/:favorite_stock_list_id/favorite_stocks/:id/edit(.:format)
      // function(favorite_stock_list_id, id, options)
      edit_mobile_favorite_stock_list_favorite_stock_path: Utils.route(['favorite_stock_list_id','id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'my',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[3,'favorite_stock_list_id',false],[2,[7,'/',false],[2,[6,'favorite_stocks',false],[2,[7,'/',false],[2,[3,'id',false],[2,[7,'/',false],[2,[6,'edit',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]]]]]], arguments),
      // edit_mobile_favorite_stock_list_favorite_stocks => /m/my/favorite_stock_lists/:favorite_stock_list_id/favorite_stocks/edit(.:format)
      // function(favorite_stock_list_id, options)
      edit_mobile_favorite_stock_list_favorite_stocks_path: Utils.route(['favorite_stock_list_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'my',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[3,'favorite_stock_list_id',false],[2,[7,'/',false],[2,[6,'favorite_stocks',false],[2,[7,'/',false],[2,[6,'edit',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]]]], arguments),
      // edit_mobile_favorite_stock_lists => /m/my/favorite_stock_lists/edit(.:format)
      // function(options)
      edit_mobile_favorite_stock_lists_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'my',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[6,'edit',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // edit_mobile_recent_view_stocks => /m/my/history/edit(.:format)
      // function(options)
      edit_mobile_recent_view_stocks_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'my',false],[2,[7,'/',false],[2,[6,'history',false],[2,[7,'/',false],[2,[6,'edit',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // edit_name_api_user => /api/user/edit_name(.:format)
      // function(options)
      edit_name_api_user_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'user',false],[2,[7,'/',false],[2,[6,'edit_name',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // favorite_stock_existences_api_favorite_stocks => /api/favorite_stocks/favorite_stock_existences(.:format)
      // function(options)
      favorite_stock_existences_api_favorite_stocks_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'favorite_stocks',false],[2,[7,'/',false],[2,[6,'favorite_stock_existences',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // favorite_stocks_api_topics => /api/topics/favorite_stocks(.:format)
      // function(options)
      favorite_stocks_api_topics_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'topics',false],[2,[7,'/',false],[2,[6,'favorite_stocks',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // featured_api_insight_articles => /api/insight_articles/featured(.:format)
      // function(options)
      featured_api_insight_articles_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'insight_articles',false],[2,[7,'/',false],[2,[6,'featured',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // featured_api_topics => /api/topics/featured(.:format)
      // function(options)
      featured_api_topics_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'topics',false],[2,[7,'/',false],[2,[6,'featured',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // feed => /feed(.:format)
      // function(options)
      feed_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'feed',false],[1,[2,[8,'.',false],[3,'format',false]],false]]], arguments),
      // foreign_indexes_api_trends => /api/trends/foreign_indexes(.:format)
      // function(options)
      foreign_indexes_api_trends_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'trends',false],[2,[7,'/',false],[2,[6,'foreign_indexes',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // foreign_mobile_stock_quotations => /m/stocks/:stock_id/quotations/foreign(.:format)
      // function(stock_id, options)
      foreign_mobile_stock_quotations_path: Utils.route(['stock_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'stock_id',false],[2,[7,'/',false],[2,[6,'quotations',false],[2,[7,'/',false],[2,[6,'foreign',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]], arguments),
      // foreign_mobile_trend_index => /m/trend/foreign(.:format)
      // function(options)
      foreign_mobile_trend_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'trend',false],[2,[7,'/',false],[2,[6,'foreign',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // forex_mobile_trend_index => /m/trend/forex(.:format)
      // function(options)
      forex_mobile_trend_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'trend',false],[2,[7,'/',false],[2,[6,'forex',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // import_api_favorite_stock_lists => /api/favorite_stock_lists/import(.:format)
      // function(options)
      import_api_favorite_stock_lists_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[6,'import',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // insight_articles_api_search_index => /api/search/insight_articles(.:format)
      // function(options)
      insight_articles_api_search_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'search',false],[2,[7,'/',false],[2,[6,'insight_articles',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // issue_keywords_api_search_index => /api/search/issue_keywords(.:format)
      // function(options)
      issue_keywords_api_search_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'search',false],[2,[7,'/',false],[2,[6,'issue_keywords',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // kosdaq_mobile_trend_index => /m/trend/kosdaq(.:format)
      // function(options)
      kosdaq_mobile_trend_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'trend',false],[2,[7,'/',false],[2,[6,'kosdaq',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // kospi_mobile_trend_index => /m/trend/kospi(.:format)
      // function(options)
      kospi_mobile_trend_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'trend',false],[2,[7,'/',false],[2,[6,'kospi',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // latest_mobile_news_index => /m/news/latest(.:format)
      // function(options)
      latest_mobile_news_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'news',false],[2,[7,'/',false],[2,[6,'latest',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // like_api_emotions => /api/emotions/like(.:format)
      // function(options)
      like_api_emotions_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'emotions',false],[2,[7,'/',false],[2,[6,'like',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // m => /m(.:format)
      // function(options)
      m_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[1,[2,[8,'.',false],[3,'format',false]],false]]], arguments),
      // major_forex_ids_api_assets => /api/assets/major_forex_ids(.:format)
      // function(options)
      major_forex_ids_api_assets_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'assets',false],[2,[7,'/',false],[2,[6,'major_forex_ids',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // mobile => /m/web2app/:host(.:format)
      // function(host, options)
      mobile_path: Utils.route(['host'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'web2app',false],[2,[7,'/',false],[2,[3,'host',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // mobile_asset => /m/assets/:id(.:format)
      // function(id, options)
      mobile_asset_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'assets',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // mobile_customer_support => /m/customer_support(.:format)
      // function(options)
      mobile_customer_support_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'customer_support',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // mobile_favorite_stock_list => /m/my/favorite_stock_lists/:id(.:format)
      // function(id, options)
      mobile_favorite_stock_list_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'my',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // mobile_favorite_stock_list_favorite_stock => /m/my/favorite_stock_lists/:favorite_stock_list_id/favorite_stocks/:id(.:format)
      // function(favorite_stock_list_id, id, options)
      mobile_favorite_stock_list_favorite_stock_path: Utils.route(['favorite_stock_list_id','id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'my',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[3,'favorite_stock_list_id',false],[2,[7,'/',false],[2,[6,'favorite_stocks',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]]]], arguments),
      // mobile_favorite_stock_list_favorite_stocks => /m/my/favorite_stock_lists/:favorite_stock_list_id/favorite_stocks(.:format)
      // function(favorite_stock_list_id, options)
      mobile_favorite_stock_list_favorite_stocks_path: Utils.route(['favorite_stock_list_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'my',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[3,'favorite_stock_list_id',false],[2,[7,'/',false],[2,[6,'favorite_stocks',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]], arguments),
      // mobile_favorite_stock_lists => /m/my/favorite_stock_lists(.:format)
      // function(options)
      mobile_favorite_stock_lists_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'my',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // mobile_home => /m/home(.:format)
      // function(options)
      mobile_home_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'home',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // mobile_investing_strategies => /m/investing_strategies(.:format)
      // function(options)
      mobile_investing_strategies_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'investing_strategies',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // mobile_news_index => /m/news(.:format)
      // function(options)
      mobile_news_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'news',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // mobile_notices => /m/notices(.:format)
      // function(options)
      mobile_notices_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'notices',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // mobile_ranker => /m/rankers/:id(.:format)
      // function(id, options)
      mobile_ranker_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'rankers',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // mobile_rankers => /m/rankers(.:format)
      // function(options)
      mobile_rankers_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'rankers',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // mobile_recent_view_stocks => /m/my/history(.:format)
      // function(options)
      mobile_recent_view_stocks_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'my',false],[2,[7,'/',false],[2,[6,'history',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // mobile_search_index => /m/search(.:format)
      // function(options)
      mobile_search_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'search',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // mobile_settings => /m/settings(.:format)
      // function(options)
      mobile_settings_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'settings',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // mobile_stock => /m/stocks/:id(.:format)
      // function(id, options)
      mobile_stock_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // mobile_stock_articles => /m/stocks/:stock_id/articles(.:format)
      // function(stock_id, options)
      mobile_stock_articles_path: Utils.route(['stock_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'stock_id',false],[2,[7,'/',false],[2,[6,'articles',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // mobile_stock_debate => /m/stocks/:stock_id/debates/:id(.:format)
      // function(stock_id, id, options)
      mobile_stock_debate_path: Utils.route(['stock_id','id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'stock_id',false],[2,[7,'/',false],[2,[6,'debates',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]], arguments),
      // mobile_stock_debate_liked_users => /m/stocks/:stock_id/debates/:debate_id/liked_users(.:format)
      // function(stock_id, debate_id, options)
      mobile_stock_debate_liked_users_path: Utils.route(['stock_id','debate_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'stock_id',false],[2,[7,'/',false],[2,[6,'debates',false],[2,[7,'/',false],[2,[3,'debate_id',false],[2,[7,'/',false],[2,[6,'liked_users',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]]]], arguments),
      // mobile_stock_debates => /m/stocks/:stock_id/debates(.:format)
      // function(stock_id, options)
      mobile_stock_debates_path: Utils.route(['stock_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'stock_id',false],[2,[7,'/',false],[2,[6,'debates',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // mobile_stock_quotations => /m/stocks/:stock_id/quotations(.:format)
      // function(stock_id, options)
      mobile_stock_quotations_path: Utils.route(['stock_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'stock_id',false],[2,[7,'/',false],[2,[6,'quotations',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // mobile_trend_index => /m/trend(.:format)
      // function(options)
      mobile_trend_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'trend',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // mobile_trend_wics_sector => /m/trend/wics_sectors/:id(.:format)
      // function(id, options)
      mobile_trend_wics_sector_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'trend',false],[2,[7,'/',false],[2,[6,'wics_sectors',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // mobile_trend_wics_sectors => /m/trend/wics_sectors(.:format)
      // function(options)
      mobile_trend_wics_sectors_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'trend',false],[2,[7,'/',false],[2,[6,'wics_sectors',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // mobile_web2app_test => /m/web2app/test(.:format)
      // function(options)
      mobile_web2app_test_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'web2app',false],[2,[7,'/',false],[2,[6,'test',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // new_mobile_favorite_stock_list_favorite_stock => /m/my/favorite_stock_lists/:favorite_stock_list_id/favorite_stocks/new(.:format)
      // function(favorite_stock_list_id, options)
      new_mobile_favorite_stock_list_favorite_stock_path: Utils.route(['favorite_stock_list_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'my',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[3,'favorite_stock_list_id',false],[2,[7,'/',false],[2,[6,'favorite_stocks',false],[2,[7,'/',false],[2,[6,'new',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]]]], arguments),
      // new_mobile_stock_debate => /m/stocks/:stock_id/debates/new(.:format)
      // function(stock_id, options)
      new_mobile_stock_debate_path: Utils.route(['stock_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'stock_id',false],[2,[7,'/',false],[2,[6,'debates',false],[2,[7,'/',false],[2,[6,'new',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]], arguments),
      // news_api_news_items => /api/news_items/news(.:format)
      // function(options)
      news_api_news_items_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'news_items',false],[2,[7,'/',false],[2,[6,'news',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // news_api_search_index => /api/search/news(.:format)
      // function(options)
      news_api_search_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'search',false],[2,[7,'/',false],[2,[6,'news',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // news_mobile_stock_articles => /m/stocks/:stock_id/articles/news(.:format)
      // function(stock_id, options)
      news_mobile_stock_articles_path: Utils.route(['stock_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'stock_id',false],[2,[7,'/',false],[2,[6,'articles',false],[2,[7,'/',false],[2,[6,'news',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]], arguments),
      // news_related_assets_api_search_index => /api/search/news_related_assets(.:format)
      // function(options)
      news_related_assets_api_search_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'search',false],[2,[7,'/',false],[2,[6,'news_related_assets',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // news_tagging_api_popular_stocks => /api/popular_stocks/news_tagging(.:format)
      // function(options)
      news_tagging_api_popular_stocks_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'popular_stocks',false],[2,[7,'/',false],[2,[6,'news_tagging',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // ordered_stocks_api_trends => /api/trends/ordered_stocks(.:format)
      // function(options)
      ordered_stocks_api_trends_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'trends',false],[2,[7,'/',false],[2,[6,'ordered_stocks',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // popular_mobile_news_index => /m/news/popular(.:format)
      // function(options)
      popular_mobile_news_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'news',false],[2,[7,'/',false],[2,[6,'popular',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // posts_count_api_popular_stocks => /api/popular_stocks/posts_count(.:format)
      // function(options)
      posts_count_api_popular_stocks_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'popular_stocks',false],[2,[7,'/',false],[2,[6,'posts_count',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // posts_mobile_stock_debates => /m/stocks/:stock_id/debates/posts(.:format)
      // function(stock_id, options)
      posts_mobile_stock_debates_path: Utils.route(['stock_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'stock_id',false],[2,[7,'/',false],[2,[6,'debates',false],[2,[7,'/',false],[2,[6,'posts',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]], arguments),
      // query_api_search_index => /api/search/query(.:format)
      // function(options)
      query_api_search_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'search',false],[2,[7,'/',false],[2,[6,'query',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // query_mobile_search_index => /m/search/query(.:format)
      // function(options)
      query_mobile_search_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'search',false],[2,[7,'/',false],[2,[6,'query',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // rails_info => /rails/info(.:format)
      // function(options)
      rails_info_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'rails',false],[2,[7,'/',false],[2,[6,'info',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]], arguments),
      // rails_info_properties => /rails/info/properties(.:format)
      // function(options)
      rails_info_properties_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'rails',false],[2,[7,'/',false],[2,[6,'info',false],[2,[7,'/',false],[2,[6,'properties',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // rails_info_routes => /rails/info/routes(.:format)
      // function(options)
      rails_info_routes_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'rails',false],[2,[7,'/',false],[2,[6,'info',false],[2,[7,'/',false],[2,[6,'routes',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // reorder_api_favorite_stock_list_favorite_stocks => /api/favorite_stock_lists/:favorite_stock_list_id/favorite_stocks/reorder(.:format)
      // function(favorite_stock_list_id, options)
      reorder_api_favorite_stock_list_favorite_stocks_path: Utils.route(['favorite_stock_list_id'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[3,'favorite_stock_list_id',false],[2,[7,'/',false],[2,[6,'favorite_stocks',false],[2,[7,'/',false],[2,[6,'reorder',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]], arguments),
      // reorder_api_favorite_stock_lists => /api/favorite_stock_lists/reorder(.:format)
      // function(options)
      reorder_api_favorite_stock_lists_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'favorite_stock_lists',false],[2,[7,'/',false],[2,[6,'reorder',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // root => /
      // function(options)
      root_path: Utils.route([], [], [7,'/',false], arguments),
      // sector_api_securities => /api/securities/sector/:code(.:format)
      // function(code, options)
      sector_api_securities_path: Utils.route(['code'], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'securities',false],[2,[7,'/',false],[2,[6,'sector',false],[2,[7,'/',false],[2,[3,'code',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // setting_api_user => /api/user/setting(.:format)
      // function(options)
      setting_api_user_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'user',false],[2,[7,'/',false],[2,[6,'setting',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // special_stocks_api_trends => /api/trends/special_stocks(.:format)
      // function(options)
      special_stocks_api_trends_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'trends',false],[2,[7,'/',false],[2,[6,'special_stocks',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // stock_search_api_popular_stocks => /api/popular_stocks/stock_search(.:format)
      // function(options)
      stock_search_api_popular_stocks_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'popular_stocks',false],[2,[7,'/',false],[2,[6,'stock_search',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // top_wics_sectors_api_trends => /api/trends/top_wics_sectors(.:format)
      // function(options)
      top_wics_sectors_api_trends_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'trends',false],[2,[7,'/',false],[2,[6,'top_wics_sectors',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // topic_mobile_investing_strategies => /m/investing_strategies/topics/:id(.:format)
      // function(id, options)
      topic_mobile_investing_strategies_path: Utils.route(['id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'investing_strategies',false],[2,[7,'/',false],[2,[6,'topics',false],[2,[7,'/',false],[2,[3,'id',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]], arguments),
      // topics_api_search_index => /api/search/topics(.:format)
      // function(options)
      topics_api_search_index_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'search',false],[2,[7,'/',false],[2,[6,'topics',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // trader_mobile_stock_quotations => /m/stocks/:stock_id/quotations/trader(.:format)
      // function(stock_id, options)
      trader_mobile_stock_quotations_path: Utils.route(['stock_id'], ['format'], [2,[7,'/',false],[2,[6,'m',false],[2,[7,'/',false],[2,[6,'stocks',false],[2,[7,'/',false],[2,[3,'stock_id',false],[2,[7,'/',false],[2,[6,'quotations',false],[2,[7,'/',false],[2,[6,'trader',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]]]]]], arguments),
      // trading_value_api_popular_stocks => /api/popular_stocks/trading_value(.:format)
      // function(options)
      trading_value_api_popular_stocks_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'popular_stocks',false],[2,[7,'/',false],[2,[6,'trading_value',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // unlike_api_emotions => /api/emotions/unlike(.:format)
      // function(options)
      unlike_api_emotions_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'emotions',false],[2,[7,'/',false],[2,[6,'unlike',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // update_profile_api_user => /api/user/update_profile(.:format)
      // function(options)
      update_profile_api_user_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'user',false],[2,[7,'/',false],[2,[6,'update_profile',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments),
      // update_setting_api_user => /api/user/update_setting(.:format)
      // function(options)
      update_setting_api_user_path: Utils.route([], ['format'], [2,[7,'/',false],[2,[6,'api',false],[2,[7,'/',false],[2,[6,'user',false],[2,[7,'/',false],[2,[6,'update_setting',false],[1,[2,[8,'.',false],[3,'format',false]],false]]]]]]], arguments) }
    ;
    root.Routes.options = defaults;
    root.Routes.default_serializer = function(object, prefix) {
      return Utils.default_serializer(object, prefix);
    };
    return root.Routes;
  };
  
  if (typeof define === 'function' && define.amd) {
    define([], function() {
      return createGlobalJsRoutesObject();
    });
  } else {
    createGlobalJsRoutesObject();
  }
}).call(this);
