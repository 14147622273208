import { union, difference, assign } from 'lodash';
import { SUCCESS_ADD_FAVORITE_STOCK, SUCCESS_DELETE_FAVORITE_STOCKS } from '../../actions/favorite_stocks';

function updateFavoriteStockList(state, action) {
  let favoriteStocks;

  switch (action.type) {
  case SUCCESS_ADD_FAVORITE_STOCK:
    favoriteStocks = union(state.favoriteStocks, [action.payload.result.favoriteStock]);
    return assign({}, state, {
      favoriteStocksCount: state.favoriteStocksCount + 1,
      favoriteStocks
    });
  case SUCCESS_DELETE_FAVORITE_STOCKS:
    favoriteStocks = difference(state.favoriteStocks, action.ids);
    return assign({}, state, {
      favoriteStocksCount: state.favoriteStocksCount - 1,
      favoriteStocks
    });
  default:
    return state;
  }
}

export function favoriteStockListsReducer(state = {}, action) {
  switch (action.type) {
  case SUCCESS_DELETE_FAVORITE_STOCKS:
  case SUCCESS_ADD_FAVORITE_STOCK:
    return assign({}, state, {
      [action.favoriteStockListId]: updateFavoriteStockList(state[action.favoriteStockListId], action)
    });
  default:
    return state;
  }
}
