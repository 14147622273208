// @flow
import { isStylesheetEnabledSelector } from '../selectors/ui_selectors';

export const ENABLE_DNM_STYLESHEET = 'ENABLE_DNM_STYLESHEET';
export function enableDnmStylesheet(): any {
  return (dispatch, getState) => {
    const isEnabled = isStylesheetEnabledSelector(getState());
    if (isEnabled) {
      return Promise.resolve();
    }

    return dispatch({
      type: ENABLE_DNM_STYLESHEET
    });
  };
}

export const DISABLE_DNM_STYLESHEET = 'DISABLE_DNM_STYLESHEET';
export function disableDnmStylesheet(): any {
  return (dispatch, getState) => {
    const isEnabled = isStylesheetEnabledSelector(getState());
    if (!isEnabled) {
      return Promise.resolve();
    }

    return dispatch({
      type: DISABLE_DNM_STYLESHEET
    });
  };
}
