export default (items, options = {}) => {
  if (!Array.isArray(items)) {
    throw new Error('actionTypeCreator(...): argument must be an array.');
  }

  const { prefix, postfix } = options;
  const container = {};
  items.forEach(item => container[item] = `${(prefix ? `${prefix}_` : '')}${item}${(postfix ? `_${postfix}` : '')}`);
  return container;
};
