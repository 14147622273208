/* @flow */
export const REPORT_TYPE_1 = '영리목적/홍보성';
export const REPORT_TYPE_2 = '불법정보';
export const REPORT_TYPE_3 = '음란성/선정성';
export const REPORT_TYPE_4 = '욕설/인신공격';
export const REPORT_TYPE_5 = '개인정보 노출';
export const REPORT_TYPE_6 = '같은 내용 반복';
export const REPORT_TYPE_99 = '기타';

export const REPORT_TYPES = [
  REPORT_TYPE_1,
  REPORT_TYPE_2,
  REPORT_TYPE_3,
  REPORT_TYPE_4,
  REPORT_TYPE_5,
  REPORT_TYPE_6,
  REPORT_TYPE_99
];

export const REPORTABLE_TYPE_POST = 'post';
export const REPORTABLE_TYPE_COMMENT = 'comment';
