import { createSelector, createStructuredSelector } from 'reselect';
import {
  newsItemsSelector,
  dayCandlesSelector,
  investorsSelector,
  dayTradersSelector,
  commentsSelector,
  favoriteStockListsSelector,
  favoriteStocksSelector,
  exchangeTradedAssetsSelector,
  exchangeTradedValuesSelector,
  recentSecurityEntitiesSelector,
  insightArticlesSelector as insightArticlesEntitySelector,
  socialPostCommentsInCommentSelector
} from './entities_selectors';
import { recentViewStocksSelector } from './recent_view_stocks_selectors';
import { topicSelector } from './topic_selectors';
import { debatesCategorySelector, debatesProfitFilterTypeSelector, debatesSortSelector } from './debates_selectors';
import { orderBy } from 'lodash';


const idSelector = (_, props) => props.params.id;
const securityIdSelector = (_, props) => props.params.securityId;

const entitiesSelector = paginate => (paginate.entities || []);
const isFetchingSelector = paginate => (paginate.isFetching || false);
const reachedEndSelector = paginate => (paginate.reachedEnd || false);
const nextCursorSelector = paginate => (paginate.nextCursor || null);
const lastKeySelector = paginate => (paginate.lastKey || null);
const pageCountSelector = paginate => (paginate.pageCount || 0);
const isErrorSelector = paginate => (paginate.isError || false);

const resultSelector = action => action.payload.result;
export const normalizedResultSelectorFactory = key => (
  createSelector(
    resultSelector,
    result => result && result[key]
  )
);

export const paginateSelector = createStructuredSelector({
  entities: entitiesSelector,
  isFetching: isFetchingSelector,
  reachedEnd: reachedEndSelector,
  nextCursor: nextCursorSelector,
  pageCount: pageCountSelector
});

export const socialPaginateSelector = createStructuredSelector({
  entities: entitiesSelector,
  isFetching: isFetchingSelector,
  reachedEnd: reachedEndSelector,
  lastKey: lastKeySelector,
  pageCount: pageCountSelector,
  isError: isErrorSelector
});

export const collectionStocksSelectorFactory = (key, market) =>
  createSelector(
    state => (state.paginate.collectionStocksByKeyAndMarket[[key, market]] || {}),
    paginateSelector
  );

export const orderedStocksSelectorFactory = (key, market) =>
  createSelector(
    state => (state.paginate.orderedStocksByKeyAndMarket[[key, market]] || {}),
    paginateSelector
  );

export const insightArticlesByScopeSelector = state => (state.paginate.insightArticlesByScope);
export const insightArticlesSelectorFactory = scope =>
  createSelector(
    insightArticlesByScopeSelector,
    scopePaginate => paginateSelector(scopePaginate[scope] || {})
  );
export const allInsightArticlesSelector = insightArticlesSelectorFactory('all');
export const popularInsightArticlesSelector = insightArticlesSelectorFactory('popular');
export const insightArticlesSelector = allInsightArticlesSelector;

export const insightArticlesPaginateByContributorIdSelector = createSelector(
  state => (state.paginate.insightArticlesByContributorId),
  idSelector,
  (paginate, id) => paginateSelector(paginate[id] || {})
);

export const insightArticlesByContributorIdSelector = createSelector(
  insightArticlesEntitySelector,
  insightArticlesPaginateByContributorIdSelector,
  (insightArticleEntity, paginate) => paginate.entities.map(id => insightArticleEntity[id])
);

export const insightArticlesPaginateBySecurityIdSelector = createSelector(
  state => (state.paginate.insightArticlesBySecurityId),
  securityIdSelector,
  (paginate, securityId) => paginateSelector(paginate[securityId] || {})
);

export const insightArticlesBySecurityIdSelector = createSelector(
  insightArticlesEntitySelector,
  insightArticlesPaginateBySecurityIdSelector,
  (insightArticleEntity, paginate) => paginate.entities.map(id => insightArticleEntity[id])
);

export const purePostsBySecurityIdAndScopeSelector  = createSelector(
  state => (state.paginate.postsBySecurityIdAndScope),
  (paginate) =>
    paginate
);

export const postsBySecurityIdAndScopeSelector = createSelector(
  purePostsBySecurityIdAndScopeSelector,
  securityIdSelector,
  debatesCategorySelector,
  (paginate, securityId, scope) =>
    paginateSelector(paginate[[securityId, scope]] || {})
);

export const pureSocialPostsByParamsSelector  = createSelector(
  state => (state.paginate.socialPostsByParams),
  (paginate) =>
    paginate
);

export const socialPostsByParamsSelector = createSelector(
  pureSocialPostsByParamsSelector,
  securityIdSelector,
  debatesSortSelector,
  debatesProfitFilterTypeSelector,
  (paginate, securityId, sort, profitFilterType) =>
    socialPaginateSelector(paginate[[securityId, sort, profitFilterType]] || {})
);

export const articlesPaginateBySecurityIdSelector = createSelector(
  state => (state.paginate.articlesBySecurityId),
  securityIdSelector,
  (paginate, securityId) =>
    socialPaginateSelector(paginate[securityId] || {})
);

export const articlesBySecurityIdSelector = createSelector(
  newsItemsSelector,
  articlesPaginateBySecurityIdSelector,
  (newsItems, paginate) => paginate.entities.map(id => newsItems[id])
);

export const newsPaginateBySecurityIdSelector = createSelector(
  state => (state.paginate.newsBySecurityId),
  securityIdSelector,
  (paginate, securityId) =>
    paginateSelector(paginate[securityId] || {})
);

export const newsBySecurityIdSelector = createSelector(
  newsItemsSelector,
  newsPaginateBySecurityIdSelector,
  (newsItems, paginate) => paginate.entities.map(id => newsItems[id])
);

export const disclosuresPaginateBySecurityIdSelector = createSelector(
  state => (state.paginate.disclosuresBySecurityId),
  securityIdSelector,
  (paginate, securityId) =>
    paginateSelector(paginate[securityId] || {})
);

export const disclosuresBySecurityIdSelector = createSelector(
  newsItemsSelector,
  disclosuresPaginateBySecurityIdSelector,
  (newsItems, paginate) => paginate.entities.map(id => newsItems[id])
);

// dayCandles
export const dayCandlesPaginateBySecurityIdSelector = createSelector(
  state => (state.paginate.dayCandlesBySecurityId),
  securityIdSelector,
  (paginate, securityId) =>
    paginateSelector(paginate[securityId] || {})
);

export const dayCandlesBySecurityIdSelector = createSelector(
  dayCandlesSelector,
  dayCandlesPaginateBySecurityIdSelector,
  (dayCandles, paginate) => paginate.entities.map(id => dayCandles[id])
);

// investors
export const investorsPaginateByIdSelectorFactory = idSelector =>
  createSelector(
    state => (state.paginate.investorsBySecurityId),
    idSelector,
    (paginate, id) =>
      paginateSelector(paginate[id] || {})
  );

// investors kospi
export const investorsLatestKospiSelector = createSelector(
  state => (state.entities.investors),
  investorsPaginateByIdSelectorFactory(() => 'KOREA-KGG01P'),
  (investors, kospi) => {
    return investors[kospi.entities[0]];
  }
);

// investors kosdaq
export const investorsLatestKosdaqSelector = createSelector(
  state => (state.entities.investors),
  investorsPaginateByIdSelectorFactory(() => 'KOREA-QGG01P'),
  (investors, kosdaq) => {
    return investors[kosdaq.entities[0]];
  }
);

export const investorsPaginateBySecurityIdSelector = investorsPaginateByIdSelectorFactory(securityIdSelector);

export const investorsBySecurityIdSelector = createSelector(
  investorsSelector,
  investorsPaginateBySecurityIdSelector,
  (investors, paginate) => paginate.entities.map(id => investors[id])
);

// dayTraders
export const dayTradersPaginateByIdSelectorFactory = idSelector =>
  createSelector(
    state => (state.paginate.dayTradersBySecurityId),
    idSelector,
    (paginate, id) =>
      paginateSelector(paginate[id] || {})
  );

export const dayTradersPaginateBySecurityIdSelector = dayTradersPaginateByIdSelectorFactory(securityIdSelector);

export const dayTradersBySecurityIdSelector = createSelector(
  dayTradersSelector,
  dayTradersPaginateBySecurityIdSelector,
  (dayTraders, paginate) => paginate.entities.map(id => dayTraders[id])
);

export const commentsPaginateByPostIdSelector = createSelector(
  state => (state.paginate.commentsByPostId),
  idSelector,
  (paginate, id) => paginateSelector(paginate[id] || {})
);

export const commentsByPostIdSelector = createSelector(
  commentsPaginateByPostIdSelector,
  (paginate) => paginate.entities
);

export const commentsPaginateByCommentIdSelector = createSelector(
  state => (state.paginate.commentsByCommentId),
  (paginate) => (id) => paginateSelector(paginate[id] || {})
);

export const commentsPaginateByInsightArticleIdSelector = createSelector(
  state => (state.paginate.commentsByInsightArticleId),
  idSelector,
  (paginate, id) =>
    paginateSelector(paginate[id] || {})
);

export const commentsByInsightArticleIdSelector = createSelector(
  commentsSelector,
  commentsPaginateByInsightArticleIdSelector,
  (comments, paginate) => paginate.entities.map(id => comments[id])
);

export const commentsPaginateBySocialPostIdSelector = createSelector(
  state => (state.paginate.commentsBySocialPostId),
  idSelector,
  (paginate, id) => socialPaginateSelector(paginate[id] || {})
);

export const secondCommentsPaginateBySocialPostCommentIdSelector = createSelector(
  state => (state.paginate.secondCommentsBySocialPostCommentId),
  (paginate) => (id) => socialPaginateSelector(paginate[id] || {})
);

export const secondCommentsBySocialPostIdSelector = createSelector(
  socialPostCommentsInCommentSelector,
  (commentsInComment) => (id) => {
    return commentsInComment[id] || [];
  }
);

export const commentsBySocialPostIdSelector = createSelector(
  commentsPaginateBySocialPostIdSelector,
  (paginate) => paginate.entities
);

export const favoriteStocksListsPaginateSelector = state =>
  paginateSelector(state.paginate.favoriteStockLists || {});

export const allFavoriteStockListsSelector = createSelector(
  favoriteStockListsSelector,
  favoriteStocksListsPaginateSelector,
  (favoriteStockLists, paginate) => {
    if (paginate.entities.length === 0) {
      return [{
        id: -1,
        name: '관심종목',
        favoriteStocksCount: 0
      }];
    }
    const lists = paginate.entities.map(id => favoriteStockLists[id]);
    return lists;
  }
);

export const articlesBySecurityIdsSelector = state => (state.paginate.articlesBySecurityIds || {});
export const newsBySecurityIdsSelector = state => (state.paginate.newsBySecurityIds || {});
export const disclosuresBySecurityIdsSelector = state => (state.paginate.disclosuresBySecurityIds || {});

export const recentViewStocksArticlesPaginateSelector = createSelector(
  recentViewStocksSelector,
  articlesBySecurityIdsSelector,
  (securityIds, paginates) => paginateSelector(paginates[securityIds.join(',')] || {})
);

export const recentViewStocksArticlesSelector = createSelector(
  newsItemsSelector,
  recentViewStocksArticlesPaginateSelector,
  (newsItems, paginate) => (paginate.entities.map(id => newsItems[id] || {}))
);

export const recentViewStocksNewsPaginateSelector = createSelector(
  recentViewStocksSelector,
  newsBySecurityIdsSelector,
  (securityIds, paginates) => paginateSelector(paginates[securityIds.join(',')] || {})
);

export const recentViewStocksNewsSelector = createSelector(
  newsItemsSelector,
  recentViewStocksNewsPaginateSelector,
  (newsItems, paginate) => (paginate.entities.map(id => newsItems[id] || {}))
);

export const recentViewStocksDisclosuresPaginateSelector = createSelector(
  recentViewStocksSelector,
  disclosuresBySecurityIdsSelector,
  (securityIds, paginates) => paginateSelector(paginates[securityIds.join(',')] || {})
);

export const recentViewStocksDisclosuresSelector = createSelector(
  newsItemsSelector,
  recentViewStocksDisclosuresPaginateSelector,
  (newsItems, paginate) => (paginate.entities.map(id => newsItems[id] || {}))
);

export const favoriteStocksPaginateBySecurityIdSelector = createSelector([
  state => (state.paginate.favoriteStocksBySecurityId),
  (_, props) => props.securityId
], (paginate, id) => paginateSelector(paginate[id] || {}));

export const favoriteStocksBySecurityIdSelector = createSelector([
  favoriteStocksSelector,
  favoriteStocksPaginateBySecurityIdSelector
], (favoriteStocks, paginate) => paginate.entities.map(id => favoriteStocks[id]));

export const exchangeTradedAssetsBySecurityIdPaginateSelector = createSelector(
  state => (state.paginate.exchangeTradedAssetsBySecurityId),
  securityIdSelector,
  (exchangeTradedAssetsPaginate, securityId) => paginateSelector(exchangeTradedAssetsPaginate[securityId] || {})
);

export const exchangeTradedAssetsBySecurityIdSelector = createSelector(
  exchangeTradedAssetsSelector,
  exchangeTradedAssetsBySecurityIdPaginateSelector,
  (exchangeTradedAssetEntity, exchangeTradedAssetsPaginate) => exchangeTradedAssetsPaginate.entities.map(id => exchangeTradedAssetEntity[id])
);

export const exchangeTradedValuesBySecurityIdPaginateSelector = createSelector(
  state => (state.paginate.exchangeTradedValuesBySecurityId),
  securityIdSelector,
  (exchangeTradedValuesPaginate, securityId) => paginateSelector(exchangeTradedValuesPaginate[securityId] || {})
);

export const exchangeTradedValuesBySecurityIdSelector = createSelector(
  exchangeTradedValuesSelector,
  exchangeTradedValuesBySecurityIdPaginateSelector,
  (exchangeTradedValueEntity, exchangeTradedValuesPaginate) => exchangeTradedValuesPaginate.entities.map(id => exchangeTradedValueEntity[id])
);

// all news
export const newsPaginateByScopeSelector = state => (state.paginate.newsByScope);
export const newsSelectorFactory = scopeSelector =>
  createSelector([
    newsPaginateByScopeSelector,
    scopeSelector
  ], (scopePaginate, scope) => paginateSelector(scopePaginate[scope] || {}));

export const popularNewsSelector = newsSelectorFactory(() => 'popular');
export const latestNewsSelector = newsSelectorFactory(() => 'latest');
export const newsByScopeSelector = newsSelectorFactory((_, props) => props.scope);

export const topRisingWicsSectorsPaginateSelector = createSelector([
  state => (state.paginate.topRisingWicsSectors)
], pagination => paginateSelector(pagination));

export const securitiesByWicsSectorIdPaginateSelector = createSelector([
  state => state.paginate.securitiesByWicsSectorId,
  (_, props) => props.params.id
], (paginate, id) => paginateSelector(paginate[id] || {}));

export const recentSecuritiesByTopicStocksSecurityId = createSelector(
  topicSelector,
  recentSecurityEntitiesSelector,
  (topic, recentSecurityEntity) => {
    if (topic.stocks) {
      const securities = topic.stocks.map(stock => recentSecurityEntity[stock.securityId]);
      return orderBy(securities, 'changePriceRate', 'desc');
    }
    return [];
  }
);

export const noticePaginateSelector = createSelector([
  state => (state.paginate.notices)
], paginate => paginateSelector(paginate));

export const assetsSearchResultByKeywordPaginateSelector = state => state.paginate.assetsSearchResultByKeyword;
export const topicsSearchResultByKeywordPaginateSelector = state => state.paginate.topicsSearchResultByKeyword;
export const newsRelatedAssetsSearchResultByKeywordPaginateSelector = state => state.paginate.newsRelatedAssetsSearchResultByKeyword;
export const newsSearchResultByKeywordPaginateSelector = state => state.paginate.newsSearchResultByKeyword;
export const insightArticlesSearchResultByKeywordPaginateSelector = state => state.paginate.insightArticlesSearchResultByKeyword;

export const searchedKeywordSelector = state => (state.routing.location && state.routing.location.query && state.routing.location.query.keyword);

const searchResultPaginateSelectorFactory = (searchResultPaginateSelector, keywordSelector) =>
  createSelector([
    searchResultPaginateSelector,
    keywordSelector
  ], (paginate, keyword) => paginateSelector(paginate[keyword] || {}));

export const assetsSearchResultPaginateSelector = searchResultPaginateSelectorFactory(
  assetsSearchResultByKeywordPaginateSelector,
  searchedKeywordSelector
);

export const topicsSearchResultPaginateSelector = searchResultPaginateSelectorFactory(
  topicsSearchResultByKeywordPaginateSelector,
  searchedKeywordSelector
);

export const newsRelatedAssetsSearchResultPaginateSelector = searchResultPaginateSelectorFactory(
  newsRelatedAssetsSearchResultByKeywordPaginateSelector,
  searchedKeywordSelector
);

export const newsSearchResultPaginateSelector = searchResultPaginateSelectorFactory(
  newsSearchResultByKeywordPaginateSelector,
  searchedKeywordSelector
);

export const insightArticlesSearchResultPaginateSelector = searchResultPaginateSelectorFactory(
  insightArticlesSearchResultByKeywordPaginateSelector,
  searchedKeywordSelector
);

export const likedUsersByPostIdPaginateSelector = state => state.paginate.likedUsersByPostId;

export const dayForexesByAssetIdPaginateSelector = state => state.paginate.dayForexesByAssetId;
export const dayForexesByAssetIdSelectorFactory = idSelector => createSelector(
  dayForexesByAssetIdPaginateSelector,
  idSelector,
  (paginate, id) => paginateSelector(paginate[id] || {}) || { entities: [] }
);

export const tickForexesByAssetIdPaginateSelector = state => state.paginate.tickForexesByAssetId;
export const tickForexesByAssetIdSelectorFactory = idSelector => createSelector(
  tickForexesByAssetIdPaginateSelector,
  idSelector,
  (paginate, id) => paginateSelector(paginate[id] || {}) || { entities: [] }
);
