/* @flow */
import { assign } from 'lodash';
import { UPDATE_HISTORY_SNAPSHOT, CLEAR_HISTORY_SNAPSHOT } from '../../actions/ui';

type Action = {
  type: string,
  assetIds: Array<string>
}

type State = {
  assetIds: Array<string>
};

const initialState: State = {
  assetIds: []
};

export function historySnapshotReducer(state: State = initialState, action: Action) : State {
  switch (action.type) {
  case UPDATE_HISTORY_SNAPSHOT:
    return assign({}, state, { assetIds: action.assetIds });
  case CLEAR_HISTORY_SNAPSHOT:
    return assign({}, initialState);
  default:
    return state;
  }
}
