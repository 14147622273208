import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './app/app';
import * as Sentry from '@sentry/react';
import { SENTRY_DSN, SENTRY_ENV, SENTRY_ENV_TYPES } from './app/constants/env';
import { stockplusSDK } from '@dunamu-stock/stockplus-web-sdk';

import '../lib/modernizr-transform';
import '../lib/zepto';

async function main() {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    debug: SENTRY_ENV !== SENTRY_ENV_TYPES.PROD,
  });

  try {
    if (typeof window !== 'undefined') {
      stockplusSDK.init();
      const { isMaintenance, redirectUrl } = await stockplusSDK.maintenance.checkMaintenanceStatus({
        phase: process.env.NODE_ENV === 'development' ? 'dev' : 'prod',
        searchParams: window.location.search,
      });
  
      if (isMaintenance && redirectUrl) {
        window.location.replace(redirectUrl);
      }
    }
  } catch (err) {
    Sentry.captureException(err);
  } finally {
    ReactDOM.render(<App />, document.getElementById('root'));
  }
}

main();
