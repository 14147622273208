/* global Kakao */
import { Routes } from '../routes';
import { CALL_API } from '../middlewares/api';
import { openAgreeTermsModal, openReleaseDormantModal } from './modals';

export const REQUEST_LOGIN = 'REQUEST_LOGIN';
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN';
export const FAILURE_LOGIN = 'FAILURE_LOGIN';

export const LOGIN_INVALIDATE = 'LOGIN_INVALIDATE';
export function loginInvalidate() {
  return {
    type: LOGIN_INVALIDATE
  };
}

export function fetchLogin(authObj) {
  return {
    [CALL_API]: {
      types: [REQUEST_LOGIN, SUCCESS_LOGIN, FAILURE_LOGIN],
      config: {
        method: 'post',
        url: Routes.api_session_path({ format: 'json' }),
        data: {
          kakao_access_token: authObj.access_token
        }
      }
    },
    authObj
  };
}


export const REQUEST_ACTIVATE = 'REQUEST_ACTIVATE';
export const SUCCESS_ACTIVATE = 'SUCCESS_ACTIVATE';
export const FAILURE_ACTIVATE = 'FAILURE_ACTIVATE';

export function activateLogin(payload) {
  return {
    [CALL_API]: {
      types: [REQUEST_ACTIVATE, SUCCESS_ACTIVATE, FAILURE_ACTIVATE],
      config: {
        method: 'post',
        url: '/api/session/activate.json',
        data: {
          payload: payload
        }
      }
    },
    payload
  };
}

export function tryLogin() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      Kakao.Auth.loginForm({
        success: resolve,
        fail: reject
      });
    })
      .then(authObj => dispatch(fetchLogin(authObj)))
      .then((action) => {
        if (action.type === FAILURE_LOGIN && action.error.status === 404) {
          return new Promise((resolve, reject) => (
            dispatch(openAgreeTermsModal({
              kakaoAccessToken: action.authObj.access_token,
              onCreateUser: () => resolve(action),
              onError: reject
            }))
          ));
        } else if (action.type === FAILURE_LOGIN && action.error.status === 403) {
          return new Promise((resolve, reject) => {
            alert('회원가입은 증권플러스 앱에서 진행해주세요.');
            reject();
          });
        } else if (action.type === FAILURE_LOGIN && action.error.status === 401 && action.error.response.body.error.code === 1000) {
        // 휴면계정 에러
          return new Promise((resolve, reject) => (
            dispatch(openReleaseDormantModal({
              token: action.error.response.body.error.data,
              onSuccess: () => resolve(action),
              onError: reject
            }))
          ));
        }
        return Promise.resolve(action);
      });
  };
}

// 액션 호출 시 로그인을 시킴
export function requireLogin(actionCreator) {
  return (...params) => (dispatch, getState) => {
    const { login } = getState();
    if (!login.isAuthenticated) {
      return dispatch(tryLogin()).then(() => dispatch(actionCreator(...params)));
    }
    return dispatch(actionCreator(...params));
  };
}

// 액션 호출 시 로그인이 되어 있지 않으면 액션을 날리지 않음
export function ifLoggedIn(actionCreator) {
  return (...params) => (dispatch, getState) => {
    const { login } = getState();
    if (!login.isAuthenticated) {
      return Promise.resolve();
    }
    return dispatch(actionCreator(...params));
  };
}


export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const SUCCESS_LOGOUT = 'SUCCESS_LOGOUT';
export const FAILURE_LOGOUT = 'FAILURE_LOGOUT';

function fetchLogout() {
  return {
    [CALL_API]: {
      types: [REQUEST_LOGOUT, SUCCESS_LOGOUT, FAILURE_LOGOUT],
      config: {
        method: 'delete',
        url: Routes.destroy_api_session_path({ format: 'json' }),
        data: {}
      }
    }
  };
}

export function logout() {
  return (dispatch, getState) => {
    const { login } = getState();
    if (login.isAuthenticated) {
      return dispatch(fetchLogout())
        .then(() => new Promise(resolve => Kakao.Auth.logout(resolve)))
        .then(() => location.reload());
    }
    return Promise.resolve();
  };
}
