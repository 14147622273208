export const AMPLITUDE_EVENT = {
  CLICK_SHARE: '공유하기_클릭',
  CLICK_SHARE_SERVICE : '공유서비스_클릭',
  CLICK_CLOSE: '닫기_클릭',
  // 투자정보
  INVESTING_STRATEGY_CONTENT: '화면진입_컨텐츠상세',
  SCREEN_ENTRY_INVESTING_STRATEGY: '화면진입_투자정보',
  CLICK_INVESTING_STRATEGY_CONTENT: '컨텐츠_클릭'
};

export const AMPLITUDE_PAGE = {
  ARTICLE_DETAIL: '모웹_컨텐츠상세',
  STOCK_DETAIL: '모웹_종목상세',
  SHARE: '공유하기',
  // 투자정보
  INVESTING_STRATEGY_CONTENT: '모웹_컨텐츠상세',
  INVESTING_STRATEGY: '모웹_투자정보'
};

export const AMPLITUDE_PAGE_DETAIL = {
  GENERAL_TAB: '종합탭',
  DEBATES_TAB: '토론탭',
  ARTICLES_TAB: '뉴스/공시탭',
  QUOTATIONS_TAB: '시세탭',
  ANALYSIS_TAB: '분석탭',
  SHARE_POPUP: '공유하기팝업'

};

export const AMPLITUDE_SHARE_SERVICE = {
  KAKAO: '카카오톡',
  FACEBOOK: '페이스북',
  TWITTER: '트위터',
  MAIL: '메일',
  BAND: '밴드',
  COPY: '복사'
};
