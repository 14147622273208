import { TOP_BUTTON_FLOATING } from '../../constants/floating_type.jsx';
import { OPEN_FLOATING, CLOSE_FLOATING, RESET_FLOATING } from '../../actions/floating.jsx';

const initialState = {
  floatingType: TOP_BUTTON_FLOATING,
  floatingProps: null
};

export function floatingsReducer(state = initialState, action = {}) {
  switch (action.type) {
  case OPEN_FLOATING:
    return Object.assign({}, state, {
      floatingType: action.floatingType,
      floatingProps: action.floatingProps || null
    });
  case RESET_FLOATING:
    return initialState;
  case CLOSE_FLOATING:
    return initialState;

  default:
    return state;
  }
}
